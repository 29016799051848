import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import {FormActions, FormAlert, FormBackdrop} from "../FormDialog/Form";
import TextField from "@material-ui/core/TextField";
import LoadingButton from "../LoadingButton";

class SearchInvoiceForm extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            invoiceId: null,
            message: null,
            errors: {},
        }
    }

    handleInputChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value,
        });
    }

    hideMessages = () => {
        this.setState({
            message: null,
            errors: {},
        });
    };

    handleSearch = () => {
        const { invoiceId } = this.state;

        if (!invoiceId) {
            this.setState({
                errors: {
                    invoiceId: 'Поле не дожно быть пустым',
                }
            });
            return;
        }

        this.setState({
            loading: true,
            errors: {}
        });

        // ищем этот invoiceId, еть ли для него уже RRN
        this.props.pushkinCardManager.requestInvoice(invoiceId)
            .then(invoice => {
                this.setState({
                    loading: false,
                });

                if (invoice === null) {
                    this.setState({
                        errors: {
                            invoiceId: 'Счет с таким ИД не найден.',
                        }
                    });
                    return;
                }

                this.props.onLoadInvoice(invoice);
            })
            .catch(error => {
                const details = error.data ? (error.data.details || {}) : {};
                this.setState({
                    loading: false,
                    message: {
                        text: error.message,
                        type: 'danger'
                    },
                    errors: details,
                });
            })
    };

    render() {
        const { message, invoiceId, errors, loading } = this.state;

        return (
            <div>
                {
                    message !== null &&
                    <FormAlert type={ message.type } onClose={ this.hideMessages }>
                        { message.text }
                    </FormAlert>
                }
                <FormBackdrop loading={ loading }>
                    <TextField
                        name="invoiceId"
                        label="ID счета (invoice ID)"
                        fullWidth
                        margin="dense"
                        value={ invoiceId || '' }
                        helperText={ errors['invoiceId'] || '' }
                        error={ errors.hasOwnProperty('invoiceId') }
                        InputProps={ {disableUnderline: false} }
                        onChange={ this.handleInputChange }
                    />
                </FormBackdrop>
                <FormActions>
                    <LoadingButton
                        color="primary"
                        onClick={ this.handleSearch }
                        loading={ loading }
                        disabled={ !invoiceId }
                    >
                        Найти
                    </LoadingButton>
                </FormActions>
            </div>

        )
    }
}

const styles = {

};

export default withStyles(styles)(SearchInvoiceForm);
