import BackendService from "./BackendService";
import apiRoutes, {reverse} from "../api/routes";

class PointsOfSaleManager extends BackendService
{
    constructor(){
        super();
        this.points = null;
        this.cities = null;
    }

    getPoints(){
        return this.points;
    }

    getCities(){
        return this.cities;
    }

    requestPointsOfSale() {
        return this.requestApi(reverse(apiRoutes.pointsOfSale), 'GET')
            .then(response => {
                this.points = response.data.points;
                this.cities = response.data.cities;

                return response;
            })
    }
}

export default new PointsOfSaleManager();
