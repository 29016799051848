import React from 'react';
import Schema from "../Schema";
import Legend from "./Legend";
import ordersManagerFactory, { EVENTS as ORDERS_MANAGER_EVENTS } from "../../service/OrdersManager";
import boardManagerFactory, { EVENTS as BOARD_MANAGER_EVENTS } from "../../service/BoardManager";
import DateTime from "../DateTime";
import { AppBar, Toolbar } from "@material-ui/core";
import BoardSeats from "../Seats/BoardSeats";
import SeatsLeft from "../SeatsLeft";

class Board extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            sessionId: null,
            scale: 1,
        };
        this.boardManager = boardManagerFactory.getManager(this.props.boardId);
        this.interval = null;

        this.schemaContainer = React.createRef();
    }

    componentDidMount(){
        this.boardManager.subscribe(BOARD_MANAGER_EVENTS.BOARD_IS_LOADED, this, ({state}) => {
            // если изменился sessionId
            const sessionId = state && state.session ? state.session.id : null;
            if (sessionId !== this.state.sessionId) {
                if (this.state.sessionId) {
                    ordersManagerFactory.getManager(this.state.sessionId).unsubscribe(ORDERS_MANAGER_EVENTS.SESSION_STATE_REFRESH, this);
                }
                if (sessionId) {
                    ordersManagerFactory.getManager(sessionId).subscribe(ORDERS_MANAGER_EVENTS.SESSION_STATE_REFRESH, this, () => {
                        this.forceUpdate();
                    });
                }

                this.setState({
                    sessionId
                });
            }
        });

        this.interval = setInterval(() => this.boardManager.requestBoard(), 2000);
        this.boardManager.requestBoard();
    }

    handleRenderSeats = () => {
        const currentWidth = this.schemaContainer.current.offsetWidth;
        const currentHeight = this.schemaContainer.current.offsetHeight;

        const top = this.schemaContainer.current.offsetTop;

        const availableHeight = window.innerHeight - top;
        const availableWidth = window.innerWidth;

        const scaleX = availableWidth / currentWidth;
        const scaleY = availableHeight / currentHeight;

        const scale = Math.min(scaleX, scaleY, 1);

        if (scale !== this.state.scale) {
            this.setState({
                scale,
            });
        }
    };

    componentWillUnmount() {
        clearInterval(this.interval);
        if (this.state.sessionId){
            ordersManagerFactory.getManager(this.state.sessionId).unsubscribeFromAllEvents(this);
        }
    }

    render() {
        const { sessionId } = this.state;

        if (!sessionId) {
            // return black window
            return <div style={{ width: '100%', height: '100%', backgroundColor: '#000'}} />;
        }

        const res = this.boardManager.getLastResponse();
        const session = res.state.session;

        const transform = this.state.scale === 1
            ? {}
            : {transform: "scale(" + this.state.scale + ")", transformOrigin: "50% 0"}

        return (
            <div>
                <AppBar position="static">
                    <Toolbar>
                        <span style={{ flexGrow: 1 }}>
                            { session.event.name } ({ session.event.age }+)
                        </span>
                        <span style={{ flexGrow: 1 }}>
                            Дата: <b><DateTime format="DD.MM.YYYY">{ session.startDate }</DateTime></b>&nbsp;
                            Время: <b><DateTime format="HH:mm">{ session.startDate }</DateTime></b>
                        </span>
                        <span style={{ flexGrow: 1 }}>
                            Ожидается
                        </span>
                        <span style={{ flexGrow: 1 }}>
                            <SeatsLeft sessionId={ session.id } />
                        </span>
                    </Toolbar>
                </AppBar>

                <div style={{ marginTop: 8 }}>
                    <div style={{ width: 'fit-content', maxWidth: '100%', margin: '0 auto' }}>
                        <div style={{ marginBottom: 40 }}>
                            <Legend sessionId={ session.id } />
                        </div>
                        <div style={{ margin: '0 -32px', ...transform }} ref={ this.schemaContainer }>
                            <Schema schemaId={ session.event.area.id }>
                                <BoardSeats
                                    key={ session.id }
                                    sessionId={ session.id }
                                    boardId={ this.props.boardId }
                                    onRenderSeats={ this.handleRenderSeats }
                                />
                            </Schema>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Board;
