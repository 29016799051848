import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Table,
    TableBody,
    TableCell as BaseTableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TableSortLabel
} from "@material-ui/core";
import DateTime from "../DateTime";
import DottedLink from "../DottedLink";
import { EVENTS as PUSHKIN_CARD_EVENTS } from "../../service/PushkinCardManager"

const TableCell = withStyles({
    root: {
        padding: 4,
        border: '1px solid rgba(224, 224, 224, 1)'
    }
})(BaseTableCell);

class InvoicesTable extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 0,
            pageSize: 25,
            sort: {
                by: 'processedAt',
                direction: 'desc',
            },
            filter: [],
            invoices: null,
            invoicesCount: 0,
            sessions: new Map(),
        };
    }

    componentDidMount(){
        this.loadInvoices();

        this.props.pushkinCardManager.subscribe(PUSHKIN_CARD_EVENTS.INVOICE_SAVED, this, () => {
            this.loadInvoices();
        })
    }

    loadInvoices = () => {
        this.props.pushkinCardManager.requestInvoices(this.state.sort, this.state.pageNum, this.state.pageSize)
            .then(({invoices, sessions, invoicesCount }) => {
                const s = new Map();
                for (let session of sessions) {
                    s.set(session.id, session);
                }
                this.setState({
                    invoices,
                    sessions: s,
                    invoicesCount,
                });
            })
    };

    handleChangePage = (event, pageNum) => {
        this.setState({
            pageNum,
            invoices: null,
        }, () => {
            this.loadInvoices()
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            pageSize: parseInt(event.target.value),
            pageNum: 0,
            invoices: null,
        }, () => {
            this.loadInvoices()
        });
    };

    handleChangeSortingByProcessedAt = () => {
        let { sort } = this.state;
        if (sort === null) {
            sort = {
                by: 'processedAt',
                direction: 'asc',
            };
        } else {
            if (sort.direction === 'desc') {
                sort = null;
            } else {
                sort = {
                    by: 'processedAt',
                    direction: 'desc',
                };
            }
        }

        this.setState({
            sort,
            invoices: null,
        }, () => {
            this.loadInvoices()
        });
    };

    handleEditInvoice = (invoice) => {
        this.props.onEditInvoice(invoice);
    }

    render() {
        const { classes } = this.props;
        const { pageNum, pageSize, sort, invoices, sessions, invoicesCount } = this.state;

        if (invoices === null) {
            return null;
        }

        return (
            <div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell rowSpan={ 2 } align="center" style={{ width: 36 }}>№</TableCell>
                                <TableCell rowSpan={ 2 } align="center" style={{ width: 120 }}>ИД платежа</TableCell>
                                <TableCell rowSpan={ 2 } align="center" style={{ width: 100 }}>
                                    <TableSortLabel
                                        active={ sort !== null && sort.by === 'processedAt' }
                                        direction={ sort !== null ? sort.direction : 'asc' }
                                        onClick={ this.handleChangeSortingByProcessedAt }
                                    >
                                        Дата платежа
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell colSpan={ 2 } align="center">Операция покупки</TableCell>
                                <TableCell colSpan={ 2 } align="center">Операция возврата</TableCell>
                                <TableCell rowSpan={ 2 } align="center" style={{ width: 200 }}>Событие</TableCell>
                                <TableCell rowSpan={ 2 } align="center" style={{ width: 150 }}>Место</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell align="center" style={{ width: 120 }}>RRN</TableCell>
                                <TableCell align="center" style={{ width: 100 }}>Дата отправки</TableCell>

                                <TableCell align="center" style={{ width: 120 }}>RRN</TableCell>
                                <TableCell align="center" style={{ width: 100 }}>Дата отправки</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                invoices.map((invoice, i) => {
                                    const sessionId = invoice.order.session.id;
                                    const session = sessions.get(sessionId);
                                    let paymentClassName = '';
                                    switch (invoice.paymentSendingStatus) {
                                        case 'ok':
                                            paymentClassName = classes.transferSuccess;
                                            break;
                                        case 'fail':
                                            paymentClassName = classes.transferFailure;
                                            break;
                                    }

                                    let refundClassName = '';
                                    switch (invoice.refundSendingStatus) {
                                        case 'ok':
                                            refundClassName = classes.transferSuccess;
                                            break;
                                        case 'fail':
                                            refundClassName = classes.transferFailure;
                                            break;
                                    }

                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={ invoice.id } data-invoice-id={ invoice.id } onClick={ this.handleClickRow }>
                                            <TableCell align="center">
                                                { pageNum * pageSize + i + 1 }
                                            </TableCell>
                                            <TableCell align="center">
                                                <DottedLink onClick={ () => this.handleEditInvoice(invoice) }>{ invoice.id }</DottedLink>
                                            </TableCell>
                                            <TableCell align="center">
                                                <DateTime>{ invoice.processedAt }</DateTime>
                                            </TableCell>

                                            <TableCell align="center">
                                                { invoice.paymentRRN }
                                            </TableCell>
                                            <TableCell align="center" className={ paymentClassName }>
                                                <DateTime>{ invoice.paymentSentAt }</DateTime>
                                            </TableCell>

                                            <TableCell align="center">
                                                { invoice.refundRRN }
                                            </TableCell>
                                            <TableCell align="center" className={ refundClassName }>
                                                <DateTime>{ invoice.refundSentAt }</DateTime>
                                            </TableCell>

                                            <TableCell align="center">
                                                <div>{ session.event.name }</div>
                                                <div><DateTime>{ session.startDate }</DateTime></div>
                                                <div>{ session.event.area.name }</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    invoice.order.items.map(item => <div>{ item.seat.fullName }</div>)
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={ invoicesCount }
                    rowsPerPage={ pageSize }
                    page={ pageNum }
                    onChangePage={ this.handleChangePage }
                    onChangeRowsPerPage={ this.handleChangeRowsPerPage }
                />
            </div>
        )
    }
}

const styles = theme => ({
    transferSuccess: {
        backgroundColor: theme.palette.success.light,
    },
    transferFailure: {
        backgroundColor: theme.palette.error.light,
    }
});

export default withStyles(styles)(InvoicesTable);
