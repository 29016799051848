import React from 'react';
import qs from "qs";
import { withSnackbar } from 'notistack';

class UrlMessage extends React.PureComponent
{
    constructor(props){
        super(props);

        const queryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});

        if (queryParams['info_message']) {
            this.props.enqueueSnackbar(queryParams['info_message'], {variant: 'info'});
        } else if (queryParams['error_message']) {
            this.props.enqueueSnackbar(queryParams['error_message'], {variant: 'error'});
        } else if (queryParams['success_message']) {
            this.props.enqueueSnackbar(queryParams['success_message'], {variant: 'success'});
        }

        delete queryParams['info_message'];
        delete queryParams['error_message'];
        delete queryParams['success_message'];

        let query = '';
        if (Object.keys(queryParams).length > 0) {
            query = '?' + qs.stringify(queryParams);
        }

        window.history.replaceState({}, '', window.location.pathname + query);
    }

    render() {
        return null;
    }
}

export default withSnackbar(UrlMessage);
