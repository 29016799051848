import React from 'react';
import PropTypes from "prop-types";
import {SchemaContext} from "../Schema/SchemaContext";

class SessionStandingSpace extends React.PureComponent
{
    constructor(props) {
        super(props);

        this.standingSpaceElement = null;
    }

    handleClick = () => {
        const { onClick } = this.props;
        onClick && onClick(this.props.space);
    };

    initStandingSpaceDOM = () => {
        const { space, schemaElementId } = this.props;

        const schemaElement = document.getElementById(schemaElementId);
        if (!schemaElement) {
            return;
        }
        const standingSpaceElement = schemaElement.getElementsByClassName(space.cssClass)[0];
        if (!standingSpaceElement) {
            return;
        }
        standingSpaceElement.onclick = this.handleClick;

        this.standingSpaceElement = standingSpaceElement;
    };

    updateLabel = () => {
        const { orderedCount, totalCount } = this.props;

        if (orderedCount === undefined || totalCount === undefined) {
            return;
        }

        const labelElement = this.standingSpaceElement.getElementsByClassName('standing_space_state')[0];
        if (!labelElement) {
            return;
        }

        const availableCount = totalCount - orderedCount;
        labelElement.innerText = '(осталось билетов: ' + availableCount + ' из ' + totalCount + ')';
    };


    componentDidMount() {
        this.initStandingSpaceDOM();
        this.updateLabel();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderedCount !== this.props.orderedCount || prevProps.totalCount !== this.props.totalCount) {
            this.updateLabel();
        }
    }

    render() {
        return null;
    }
}

SessionStandingSpace.propTypes = {

};

SessionStandingSpace.defaultProps = {

};

export default (props) => {
    return <SchemaContext.Consumer>
        {
            ({ schemaElementId }) => <SessionStandingSpace { ...props } schemaElementId={ schemaElementId } />
        }
    </SchemaContext.Consumer>
}
