import BackendService from './BackendService';
import apiRoutes, { reverse } from "../api/routes";

class EventsManager extends BackendService
{
    getUpcomingEvents() {
        return this.requestApi(reverse(apiRoutes.upcomingEvents), 'GET');
    }
}

export default new EventsManager();
