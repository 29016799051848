import BackendService from './BackendService';
import apiRoutes, {reverse} from "../api/routes";

export const EVENTS = {
    PRICE_MAP_REFRESHED: 'PRICE_MAP_REFRESHED',
};

class PriceMapManager extends BackendService
{
    constructor(sessionId) {
        super();
        this.sessionId = sessionId;
        this.discountMaps = new Map(); // mapId => map
        this.ranges = new Map(); // rangeId => range

        this.seatPriceRangeMap = new Map(); // seatId -> range
        this.seatDiscountRangeMap = new Map(); // seatId -> [range]

        this.rangeParentMap = new Map(); // rangeId => priceMap or discountMap which owns the range
        this.isLoadingComplete = false;

        this.priceMap = null;
    }

    isReady() {
        return this.isLoadingComplete;
    }

    refresh = () => {
        return this.requestPriceMaps();
    };

    requestPriceMaps() {
        return this.requestApi(reverse(apiRoutes.session.prices, {sessionId: this.sessionId}), 'GET')
            .then(response => {
                this.discountMaps = new Map();
                this.ranges = new Map();
                this.seatPriceRangeMap = new Map();
                this.seatDiscountRangeMap = new Map();
                this.rangeParentMap = new Map();

                this.priceMap = response['priceMap'];

                for (let range of response['priceMap']['ranges']) {
                    this.ranges.set(range.id, range);
                    this.rangeParentMap.set(range.id, response['priceMap']);
                    for (let seatId of range['seats']) {
                        this.seatPriceRangeMap.set(seatId, range);
                    }
                }

                for (let discountMap of response['discountMaps']) {
                    this.discountMaps.set(discountMap.id, discountMap);
                    for (let range of discountMap['ranges']) {
                        this.ranges.set(range.id, range);
                        this.rangeParentMap.set(range.id, discountMap);
                        for (let seatId of range['seats']) {
                            const f = this.seatDiscountRangeMap.get(seatId) || [];
                            f.push(range);
                            this.seatDiscountRangeMap.set(seatId, f);
                        }
                    }
                }

                this.isLoadingComplete = true;

                this.dispatch(
                    EVENTS.PRICE_MAP_REFRESHED,
                    {
                        prices: response['priceMap']['ranges'],
                    }
                );
            })
    }

    getRange(rangeId) {
        return this.ranges.get(rangeId) || null;
    }

    getSeatPriceRange(seatId) {
        return this.seatPriceRangeMap.get(seatId) || null;
    }

    getSeatDiscountRanges(seatId) {
        return this.seatDiscountRangeMap.get(seatId) || [];
    }

    getMapOfRange(rangeId) {
        return this.rangeParentMap.get(rangeId) || null;
    }

    getPriceMap() {
        return this.priceMap;
    }
}

class Factory
{
    managers = new Map();

    getManager(sessionId){
        sessionId = parseInt(sessionId);
        if (this.managers.has(sessionId)) {
            return this.managers.get(sessionId);
        }
        const manager = new PriceMapManager(sessionId);
        this.managers.set(sessionId, manager);

        return manager;
    }
}

export default new Factory();