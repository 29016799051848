import { include, reverse } from 'named-urls';

const compile = (pattern, params) => {
    return reverse(pattern.replace(/\([^()]*\)/g, ''), params);
};

export { compile as reverse };

export const routes = {
    login:  '/login',
    home: '/',
    myOrders: '/my_orders',
    upcomingEvents: '/upcoming_events',
    pointsOfSale: '/points_of_sale',
    session: include('/session/:sessionId(\\d+)', {
        index: '',
        booking: 'booking',
        board: 'board',
    }),
    board: '/board/:boardId(\\d+)',
    pushkinCard: '/pushkin_card/:shopId',
    entryControl: '/entry_control'
    // client: '/',
    // resetPassword: '/reset_password/:token',
    // admin:  include('/admin', {
    //     accounts: 'accounts',
    //     account:  include('account/:accountId(\\d+)?', {
    //         index:      '',
    //         designer:   'designer',
    //         dataSource: 'datasource/:dataSourceId(\\d+)',
    //         search:     'search',
    //         eventLog: 'eventlog',
    //     }),
    // }),
};
