import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import userManager, { EVENTS as USER_EVENTS } from '../../service/UserManager';
import LoadingButton from "../LoadingButton";
import Backdrop from "../Backdrop";
import Alert from "../Alert";
import cartManagerFactory, {EVENTS as CART_EVENTS} from "../../service/CartManager";
import sessionManager from "../../service/SessionManager";
import RobokassaPaymentForm from "../RobokassaPaymentForm";
import { withRouter } from "react-router-dom";
import { routes, reverse } from "../../routes";
import withStyles from "@material-ui/core/styles/withStyles";
import {Checkbox, FormControlLabel, Grid} from "@material-ui/core";
import IMask from 'imask';

const PHONE_REGEXP = /^\+7\d{10}$/;

class OrderingForm extends React.PureComponent
{
    constructor(props){
        super(props);

        const currentUser = userManager.getUser();

        this.state = {
            order: {
                clientComment: null,
                action: 'booking',
                notifyMe: false,
            },
            user: {
                surname: currentUser ? currentUser.surname : null,
                name:    currentUser ? currentUser.name : null,
                midname: currentUser ? currentUser.midname : null,
                email:   null,
                phone:   null,
            },
            payment: null,
            loading: false,
            orderErrors: {},
            userErrors: {},
            message: null,
        };
    }

    componentDidMount(){
        cartManagerFactory.getManager(this.props.sessionId).subscribe([
            CART_EVENTS.CART_IS_RELEASED,
            CART_EVENTS.CART_ITEMS_DELETED,
            CART_EVENTS.CART_ITEMS_ADDED
        ], this, () => {
            this.forceUpdate();
        });

        userManager.subscribe(USER_EVENTS.CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate();
        })
    }

    componentWillUnmount(){
        cartManagerFactory.getManager(this.props.sessionId).unsubscribeFromAllEvents(this);
        userManager.unsubscribeFromAllEvents(this);
    }

    clearErrors(){
        this.setState({
            message: null,
            userErrors: {},
            orderErrors: {},
        });
    }

    validate(){
        const userErrors = {};

        let { phone } = this.state.user;
        if (phone) {
            phone = this.unmaskPhone(phone);
            if (!phone.match(PHONE_REGEXP)) {
                userErrors['phone'] = 'Неверно введен номер телефона.';
            }
        } else {
            if (this.state.order.notifyMe) {
                userErrors['phone'] = 'Требуется указать номер телефона для уведомлений.';
            }
        }

        const { order, user } = this.state;
        if (order.action === 'purchasePC') {
            // ФИО обязательны
            if (!user.surname) {
                userErrors['surname'] = 'Необходимо указать фамилию.';
            }
            if (!user.name) {
                userErrors['name'] = 'Необходимо указать имя.';
            }
            if (!user.midname) {
                userErrors['midname'] = 'Необходимо указать отчество.';
            }
        }

        if (Object.keys(userErrors).length > 0) {
            this.setState({
                message: {
                    text: 'Пожалуйста, исправьте ошибки ввода',
                    type: 'danger'
                },
                userErrors,
            });
            return false;
        }

        this.setState({
            userErrors,
        });

        return true;
    }

    unmaskPhone(phone) {
        if (!phone) {
            return null;
        }
        return phone.replaceAll(/[\s()-]/ig, '');
    }

    handleSubmit = () => {
        this.clearErrors();

        if (!this.validate()) {
            return;
        }

        this.setState({
            loading: true,
            message: null,
        });

        const user = { ...this.state.user };
        for (const property in user) {
            if (user.hasOwnProperty(property) && user[property] === null) {
                delete user[property];
                continue;
            }
            if (property === 'phone') {
                user['phone'] = this.unmaskPhone(user['phone']);
            }
        }

        cartManagerFactory.getManager(this.props.sessionId)
            .order(this.state.order, user)
            .then(response => {
                this.setState({
                    loading: false,
                });
                if (response.status === 'ERROR') {
                    this.setState({
                        message: {
                            text: response.message,
                            type: 'danger'
                        },
                        orderErrors: new Map(Object.entries(response.data.order || {})),
                    });
                    return;
                }

                if (response.data.payment) {
                    this.setState({
                        payment: response.data.payment,
                    });
                    return;
                }

                userManager.setUser(response.data.user);

                const url = reverse(routes.myOrders);
                this.props.history.push(url);
            })
            .catch(error => {
                const details = error.data ? (error.data.details || {}) : {};
                this.setState({
                    loading: false,
                    message: {
                        text: error.message,
                        type: 'danger'
                    },
                    userErrors: details.user || {},
                    orderErrors: details.order || {},
                });
            })
    };

    handleOrderInputChange = event => {
        const value = event.target.value || null;
        const name = event.target.name;

        this.setState(state => {
            const orderErrors = {...state.orderErrors};
            delete orderErrors[name];
            const order = {...this.state.order};
            order[name] = value;

            return {
                orderErrors,
                message: Object.keys(orderErrors).length + Object.keys(state.userErrors).length > 0 ? state.message : null,
                order,
            }
        });
    };

    handleUserInputChange = event => {
        let value = event.target.value || null;
        if (value) {
            value = value.trim();
        }
        const name = event.target.name;

        if (name === 'phone' && value) {
            const masked = IMask.createMask({
                mask: '+7 (000) 000-00-00',
            });
            value = masked.resolve(value);
        }

        this.setState(state => {
            const userErrors = {...state.userErrors};
            delete userErrors[name];
            const user = {...this.state.user};
            user[name] = value;

            return {
                userErrors,
                message: Object.keys(userErrors).length + Object.keys(state.orderErrors).length > 0 ? state.message : null,
                user,
            }
        });
    };

    handleMessageClose = () => {
        this.clearErrors();
    };

    handleBooking = () => {
        this.setState(state => ({
            order: {
                ...state.order,
                action: 'booking',
            },
        }), () => {
            this.handleSubmit();
        });
    };

    handlePushkinCardPurchasing = () => {
        this.setState(state => ({
            order: {
                ...state.order,
                action: 'purchasePC',
            },
        }), () => {
            this.handleSubmit();
        });
    };

    handlePurchasing = () => {
        this.setState(state => ({
            order: {
                ...state.order,
                action: 'purchase',
            },
        }), () => {
            this.handleSubmit();
        });
    };

    handleNotifyMeChange = event => {
        const checked = event.target.checked;
        this.setState(state => ({
            order: { ...state.order, notifyMe: checked },
        }));
    };

    doesUserHasValidPhone() {
        const { user } = this.state;
        const currentUser = userManager.getUser();

        if (currentUser && currentUser.phone) {
            return true;
        }
        if (!user.phone) {
            return false;
        }

        return this.unmaskPhone(user.phone).match(PHONE_REGEXP) !== null;
    }

    render(){
        const session = sessionManager.getSession(this.props.sessionId);
        if (!session) {
            return null;
        }

        const { order, orderErrors, user, userErrors } = this.state;
        const { classes } = this.props;

        const cartManager = cartManagerFactory.getManager(this.props.sessionId);

        const currentUser = userManager.getUser();

        const textFields = {
            surname: 'Фамилия',
            name:    'Имя',
            midname: 'Отчество',
            email:   'Электронная почта',
            phone:   'Номер телефона',
        };

        const hasValidPhone = this.doesUserHasValidPhone();

        return (
            <div className={ classes.root }>
                {
                    !currentUser &&
                    <div className={classes.alert}>
                        <Alert type="info">
                            Просим Вас оставить информацию для связи с Вами в случае необходимости{' '}
                            верификации платежа или билета, а также в случае отмены или переноса мероприятия.
                        </Alert>
                    </div>
                }
                {
                    this.state.message !== null &&
                    <div className={ classes.alert }>
                        <Alert
                            type={ this.state.message.type }
                            onClose={ this.handleMessageClose }
                        >
                            { this.state.message.text }
                        </Alert>
                    </div>
                }

                <div className={ classes.form }>
                    <Backdrop loading={ this.state.loading }>
                        <Grid
                            container
                            spacing={1}
                            justify="space-between"
                            alignItems="flex-start"
                        >
                            {
                                ['surname', 'name', 'midname'].map(name => (
                                    <Grid item xs={ 4 } key={ name }>
                                        <TextField
                                            key={ name }
                                            label={ textFields[name] }
                                            placeholder={ textFields[name] }
                                            fullWidth
                                            margin="dense"
                                            name={ name }
                                            value={ user[name] || '' }
                                            helperText={ userErrors[name] || '' }
                                            error={ userErrors.hasOwnProperty(name) }
                                            InputProps={ {disableUnderline: false} }
                                            onChange={ this.handleUserInputChange }
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            justify="space-between"
                            alignItems="flex-start"
                        >
                            {
                                ['email', 'phone'].map(name => {
                                    if (currentUser && currentUser[name]) {
                                        return null;
                                    }
                                    const xs = currentUser && (!!currentUser['email'] || !!currentUser['phone']) ? 12 : 6;
                                    const label = textFields[name];
                                    return <Grid item xs={ xs } key={ name }>
                                        <TextField
                                            key={ name }
                                            label={ label }
                                            placeholder={ textFields[name] }
                                            fullWidth
                                            margin="dense"
                                            name={ name }
                                            value={ user[name] || '' }
                                            helperText={ userErrors[name] || '' }
                                            error={ userErrors.hasOwnProperty(name) }
                                            InputProps={ {disableUnderline: false} }
                                            onChange={ this.handleUserInputChange }
                                        />
                                    </Grid>
                                })
                            }
                        </Grid>

                        <TextField
                            label="Комментарий к заказу"
                            fullWidth
                            margin="dense"
                            name="clientComment"
                            value={ order['clientComment'] || '' }
                            helperText={ orderErrors['clientComment'] || '' }
                            error={ orderErrors.hasOwnProperty('clientComment') }
                            InputProps={ {disableUnderline: false} }
                            onChange={ this.handleOrderInputChange }
                            multiline
                            rowsMax={ 2 }
                        />
                        {
                            (!currentUser || !currentUser['phone']) &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={ this.state.order.notifyMe || hasValidPhone }
                                        onChange={ this.handleNotifyMeChange }
                                        color="primary"
                                        disabled={ hasValidPhone }
                                    />
                                }
                                label="Уведомлять об изменениях в мероприятии"
                            />
                        }
                    </Backdrop>
                </div>

                {
                    session.settings.isOnlineBookingEnabled ?
                    <React.Fragment>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={ this.handleBooking }
                            loading={ this.state.loading }
                            disabled={ !cartManager.getItems() || !cartManager.getItems().length }
                        >
                            Забронировать
                        </LoadingButton>
                        <p>
                            {
                                currentUser && (currentUser.isEmailConfirmed || currentUser.isPhoneConfirmed) ?
                                <React.Fragment>
                                    Срок бронирования - <b>{ this.getBookingTimeLabel() }</b><br />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    Срок бронирования - <b>30 мин.</b>{' '}
                                    Бронирование будет автоматически продлено на срок <b>{ this.getBookingTimeLabel() }</b>{' '}
                                    после подтверждения адреса эл. почты.<br />
                                </React.Fragment>

                            }
                            За <b>{ this.getBookingTerminationTimeLabel() }</b> до начала мероприятия бронь аннулируется.
                        </p>
                    </React.Fragment>
                    :
                    <p>
                        Онлайн-бронирование билетов на данное мероприятие недоступно.
                    </p>
                }
                {
                    session.settings.isOnlinePaymentEnabled ?
                        <React.Fragment>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={ this.handlePurchasing }
                                loading={ this.state.loading }
                                disabled={ !cartManager.getItems() || !cartManager.getItems().length }
                            >
                                Купить
                            </LoadingButton>
                            <p>
                                Оплата билетов осуществляется на страницах платежного шлюза{' '}
                                <img alt="robokassa" src="https://auth.robokassa.ru/Merchant/PaymentForm/Images/logo-s.png" />.{' '}
                                {
                                    session.settings.onlineSurcharge > 0 &&
                                    <span>К сумме заказа будет добавлена комиссия { this.getOnlineSurchargeLabel() }.</span>
                                }
                            </p>

                            {
                                session.settings.isOnlinePaymentByPushkinCardEnabled &&
                                <div>
                                    {
                                        cartManager.getItems() && cartManager.getItems().length > 1 &&
                                        <div className={ classes.alert }>
                                            <Alert type="warning">
                                                По условиям программы "Пушкинская карта", держатель карты может оплатить ею билет только для себя.
                                                Для оплаты Пушкинской картой оставьте в корзине единственное место.
                                            </Alert>
                                        </div>
                                    }
                                    <LoadingButton
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={ this.handlePushkinCardPurchasing }
                                        loading={ this.state.loading }
                                        disabled={ !cartManager.getItems() || cartManager.getItems().length !== 1 }
                                    >
                                        Купить с оплатой Пушкинской картой
                                    </LoadingButton>
                                </div>
                            }

                            <p>
                                После покупки или бронирования билетов Вы можете управлять своими заказами, нажав на ссылку{' '}
                                "Мои заказы" верхнего меню.
                            </p>
                        </React.Fragment>
                        :
                        <p>
                            Онлайн-покупка билетов на данное мероприятие недоступна.
                        </p>
                }

                {
                    this.state.payment &&
                    <RobokassaPaymentForm payment={ this.state.payment } />
                }
            </div>
        )
    }

    getOnlineSurchargeLabel() {
        const session = sessionManager.getSession(this.props.sessionId);
        if (session.settings.onlineSurchargeUnit === '%') {
            return `${session.settings.onlineSurcharge}%`;
        }

        return `${session.settings.onlineSurcharge} руб.`
    }

    getBookingTimeLabel() {
        const session = sessionManager.getSession(this.props.sessionId);
        return this.getTimeInterval(session.settings.bookingTime);
    }

    getBookingTerminationTimeLabel() {
        const session = sessionManager.getSession(this.props.sessionId);
        return this.getTimeInterval(session.settings.abortTime);
    }

    getTimeInterval = seconds => {
        let minutes = Math.floor(seconds / 60);
        let days = Math.floor(minutes / (24 * 60));
        let hours = Math.floor((minutes - days * 24 * 60) / 60);
        minutes = minutes - days * 24 * 60 - hours * 60;

        const a = [];
        if (days) {
            a.push(`${days} дн.`);
        }
        if (hours) {
            a.push(`${hours} ч.`);
        }
        if (minutes) {
            a.push(`${minutes} мин.`);
        }

        return a.join(', ');
    }
}

OrderingForm.propTypes = {
    sessionId: PropTypes.number.isRequired,
};

const s = {
    root: {
        '& p': {
            fontSize: '0.8rem',
        }
    },
    alert: {
        margin: '0 -16px',
    },
    form: {
        margin: '8px 0',
    }
};

export default withRouter(withStyles(s)(OrderingForm));
