import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import {FormActions, FormAlert, FormBackdrop} from "../FormDialog/Form";
import TextField from "@material-ui/core/TextField";
import LoadingButton from "../LoadingButton";
import {Grid, Typography} from "@material-ui/core";

class RRNForm extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            paymentRRN: props.invoice.paymentRRN,
            refundRRN: props.invoice.refundRRN,
            message: null,
            errors: {},
        }
    }

    handleSave = (name) => {
        const [ action, subject ] = name.split('_');

        const propertyName = subject === 'refund'
            ? 'refundRRN'
            : 'paymentRRN';

        const propertyValue = this.state[propertyName];

        if (!propertyValue) {
            this.setState({
                errors: {
                    [propertyName]: 'Поле не дожно быть пустым',
                }
            });
            return;
        }

        this.setState({
            loading: true,
            message: null,
            errors: {}
        });

        const invoice = {...this.props.invoice};
        invoice[propertyName] = propertyValue;

        // ищем этот invoiceId, еть ли для него уже RRN
        this.props.pushkinCardManager.saveInvoice(invoice.id, invoice, action === 'send' ? subject : null)
            .then(invoice => {
                this.setState({
                    loading: false,
                });

                this.props.onSave(invoice);
            })
            .catch(error => {
                const details = error.data ? (error.data.details || {}) : {};
                this.setState({
                    loading: false,
                    message: {
                        text: error.message,
                        type: 'danger'
                    },
                    errors: details,
                });
            })
    };

    handleInputChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value,
        });
    }

    hideMessages = () => {
        this.setState({
            message: null,
            errors: {},
        });
    };

    render() {
        const { invoice } = this.props;
        const { message, paymentRRN, refundRRN, errors, loading } = this.state;

        return (
            <div>
                {
                    message !== null &&
                    <FormAlert type={ message.type } onClose={ this.hideMessages }>
                        { message.text }
                    </FormAlert>
                }
                <FormBackdrop loading={ loading }>
                    <Typography variant="h6" style={{ marginBottom: 8 }}>Платеж</Typography>
                    {
                        invoice.paymentSendingStatus === 'ok' &&
                        <FormAlert type="info">
                            Платеж отправлен
                        </FormAlert>
                    }
                    <div>
                        <TextField
                            name="paymentRRN"
                            label={ 'RRN оплаты к счету №' + invoice.id }
                            fullWidth
                            margin="dense"
                            value={ paymentRRN || '' }
                            helperText={ errors['paymentRRN'] || '' }
                            error={ errors.hasOwnProperty('paymentRRN') }
                            InputProps={ {disableUnderline: false} }
                            onChange={ this.handleInputChange }
                            disabled={ invoice.paymentSendingStatus === 'ok' }
                        />

                        <Grid container justify="space-between">
                            <LoadingButton
                                name="save_payment"
                                color="primary"
                                onClick={ this.handleSave }
                                loading={ loading }
                                disabled={ !paymentRRN || invoice.paymentSendingStatus === 'ok'  }
                                style={{ width: '40%'}}
                                variant="contained"
                            >
                                Сохранить
                            </LoadingButton>

                            <LoadingButton
                                name="send_payment"
                                color="primary"
                                onClick={ this.handleSave }
                                loading={ loading }
                                disabled={ !paymentRRN || invoice.paymentSendingStatus === 'ok'  }
                                style={{ width: '59%'}}
                                variant="contained"
                            >
                                Сохранить и отправить
                            </LoadingButton>
                        </Grid>
                    </div>

                    <hr style={{ margin: '16px -24px', borderBottom: 'none' }} />

                    <Typography variant="h6" style={{ marginBottom: 8 }}>Возврат</Typography>
                    {
                        invoice.refundSendingStatus === 'ok' &&
                        <FormAlert type="info">
                            Возврат отправлен
                        </FormAlert>
                    }
                    <div>
                        <TextField
                            name="refundRRN"
                            label={ 'RRN возврата к счету №' + invoice.id }
                            fullWidth
                            margin="dense"
                            value={ refundRRN || '' }
                            helperText={ errors['refundRRN'] || '' }
                            error={ errors.hasOwnProperty('refundRRN') }
                            InputProps={ {disableUnderline: false} }
                            onChange={ this.handleInputChange }
                            disabled={ invoice.refundSendingStatus === 'ok' }
                        />

                        <Grid container justify="space-between">
                            <LoadingButton
                                name="save_refund"
                                color="primary"
                                onClick={ this.handleSave }
                                loading={ loading }
                                disabled={ !refundRRN || invoice.refundSendingStatus === 'ok'  }
                                style={{ width: '40%'}}
                                variant="contained"
                            >
                                Сохранить
                            </LoadingButton>

                            <LoadingButton
                                name="send_refund"
                                color="primary"
                                onClick={ this.handleSave }
                                loading={ loading }
                                disabled={ !refundRRN || invoice.refundSendingStatus === 'ok'  }
                                style={{ width: '59%'}}
                                variant="contained"
                            >
                                Сохранить и отправить
                            </LoadingButton>
                        </Grid>
                    </div>
                </FormBackdrop>
                <FormActions />
            </div>

        )
    }
}

const styles = {

};

export default withStyles(styles)(RRNForm);
