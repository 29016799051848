import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Tooltip,
    Icon,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableFooter,
    Popover,
    Radio, FormControlLabel, RadioGroup, FormLabel
} from "@material-ui/core";
import { TableCell as BaseTableCell, TableRow as BaseTableRow, IconButton as BaseIconButton } from '@material-ui/core';
import { EVENTS as CART_EVENTS } from "../../service/CartManager";
import { EVENTS as SCHEMA_EVENTS } from "../../service/SchemaManager";
import FormControl from "@material-ui/core/FormControl";
import TableRow from "@material-ui/core/TableRow";
import Currency from "../Currency";
import Alert from "../Alert";

const TableCell = withStyles({
    root: {
        padding: '8px',
        lineHeight: '1.3em',
    },
    // head: {
    //     height: 40,
    // },
    // footer: {
    //     height: 40,
    //     color: 'inherit',
    //     lineHeight: 'inherit',
    //     fontSize: 'inherit',
    // }
})(BaseTableCell);

class SeatDiscountForm extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.state = {
            discountPopupAnchorEl: null,
        };
    }

    handleChange = event => {
        this.props.onChange(this.props.seatId, parseInt(event.target.value));
    };

    render() {
        const { priceMapManager, value, seatId, classes } = this.props;

        const priceRange = priceMapManager.getSeatPriceRange(seatId);
        const price = priceRange.nominal;

        return (
            <div>
                <h3 style={{ marginTop: 0, marginLeft: 8 }}>Выбор скидки</h3>

                <Alert type="warning" style={{ margin: '0 -16px'}}>
                    <b>Внимание!</b> Условие предоставления скидки должны быть соблюдены. В противном случае билет, приобретенный со скидкой, будет считаться недействительным.
                </Alert>

                <TableContainer style={{ marginBottom: 8 }}>
                    <Table>
                        <TableBody>
                            <TableRow className={ classes.headerRow }>
                                <TableCell align="left">Место:</TableCell>
                                <TableCell align="right">{ this.props.seatName }</TableCell>
                            </TableRow>
                            <TableRow className={ classes.headerRow }>
                                <TableCell align="left">Цена:</TableCell>
                                <TableCell align="right"><Currency>{ price }</Currency></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow className={ classes.headerRow }>
                                <TableCell align="center" style={{ width: 50 }} />
                                <TableCell align="left">Название скидки /<br /> условия предоставления</TableCell>
                                <TableCell align="center" style={{ width: 80 }}>Размер<br />скидки</TableCell>
                                <TableCell align="right" style={{ width: 100 }}>Цена<br />со скидкой</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover tabIndex={-1}>
                                <TableCell align="center" className={ classes.radioCell }>
                                    <Radio
                                        checked={ value === 0 }
                                        onChange={ this.handleChange }
                                        value={ 0 }
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    Без скидки
                                </TableCell>
                                <TableCell align="center">
                                    0
                                </TableCell>
                                <TableCell align="right" className={ classes.discountCell }>
                                    <Currency>{ price }</Currency>
                                </TableCell>
                            </TableRow>
                            {
                                priceMapManager.getSeatDiscountRanges(seatId).map(range => {
                                    const discountMap = priceMapManager.getMapOfRange(range.id);

                                    const priceWithDiscount = range.unit === '%'
                                        ? price * (100 - range.nominal)/100
                                        : price - range.nominal;

                                    return (
                                        <TableRow hover tabIndex={-1} key={ discountMap.id }>
                                            <TableCell align="center" className={ classes.radioCell }>
                                                <Radio
                                                    checked={ value === discountMap.id }
                                                    onChange={ this.handleChange }
                                                    value={ discountMap.id }
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <span>{ discountMap.name }</span>
                                                <div style={{ fontSize: 'small' }}>{ discountMap.condition }</div>
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    range.unit === '%'
                                                        ? `-${range.nominal}%`
                                                        : <Currency>{ -range.nominal }</Currency>
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                <span className={ priceWithDiscount < price ? classes.priceWithDiscount : '' }>
                                                    <Currency>{ priceWithDiscount }</Currency>
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}


const sss = theme => ({
    radioCell: {
        padding: 4,
    },
    priceWithDiscount: {
        color: theme.palette.success.main,
    },
    headerRow: {
        '& > th': {
            //borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd',
        }
    }
});

export default withStyles(sss)(SeatDiscountForm);
