import React from 'react';
import PropTypes from "prop-types";
import sessionManager from "../../service/SessionManager";
import Schema from "../Schema";
import RefreshCountdown from "../Seats/RefreshCountdown";
import BookingSeats from "../Seats/BookingSeats";
import Legend from "./Legend";
import ordersManagerFactory, { EVENTS as ORDERS_MANAGER_EVENTS } from "../../service/OrdersManager";
import cartManagerFactory, { EVENTS as CART_EVENTS } from "../../service/CartManager"
import priceMapManagerFactory from "../../service/PriceMapManager"
import userManager from "../../service/UserManager";
import Cart from "../Cart";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Ordering from "../Ordering";
import DateTime from "../DateTime";
import Badge from "@material-ui/core/Badge";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import schemaManagerFactory from "../../service/SchemaManager";
import { withSnackbar } from 'notistack';
import qs from "qs";
import config from '../../params';
import FormDialog from "../FormDialog/FormDialog";
import {FormActions} from "../FormDialog/Form";
import classNames from 'classnames';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import StandingSpaceForm from "./StandingSpaceForm";
import BookingStandingSpaces from "../StandingSpaces/BookingStandingSpaces";
import SeatsLeft from "../SeatsLeft";

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

class CartButton extends React.PureComponent
{
    componentDidMount() {
        const allCartEvents = [
            CART_EVENTS.CART_ITEMS_DELETED,
            CART_EVENTS.CART_ITEMS_ADDED,
            CART_EVENTS.CART_IS_RELEASED,
        ];

        this.props.cartManager.subscribe(allCartEvents, this, () => { this.forceUpdate() });
    }

    componentWillUnmount() {
        this.props.cartManager.unsubscribeFromAllEvents(this);
    }

    render() {
        const count = this.props.cartManager.getItems().length;
        return (
            <Tooltip title="Ваш заказ">
                <IconButton>
                    <StyledBadge badgeContent={ count } color="primary">
                        <Icon>shopping_cart</Icon>
                    </StyledBadge>
                </IconButton>
            </Tooltip>
        )
    }
}

class Booking extends React.PureComponent
{
    constructor(props){
        super(props);

        this.sideBarWidth = 400;

        this.state = {
            rightBarOpen: false,
            sticky: false,
            cartMode: this.getCartMode(),
            isCartDialogOpen: false,
            footerHeight: this.getFooterHeight(),
            selectedStandingSpaceSeat: null,
        };

        this.cartManager = cartManagerFactory.getManager(this.props.sessionId);
        this.ordersManager = ordersManagerFactory.getManager(this.props.sessionId);
        this.priceMapManager = priceMapManagerFactory.getManager(this.props.sessionId);

        this.cartContainer = React.createRef();
    }

    getFooterHeight() {
        const f = document.getElementsByTagName('footer');
        if (f.length > 0) {
            return f[0].offsetHeight;
        }
        return 0;
    }

    getCartMode() {
        return this.sideBarWidth * window.devicePixelRatio <= window.innerWidth / 2 ? 'slider' : 'dialog';
    }

    componentDidMount(){
        const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (queryParams.hasOwnProperty('force_employee') && userManager.isEmployee()) {
            let apiEndpoint = config.apiEndpoint;
            if (apiEndpoint.slice(-1) !== '/') {
                apiEndpoint += '/';
            }
            window.location.href = apiEndpoint + 'admin/session/' + this.props.sessionId + '/plenum';
            return;
        }

        sessionManager.requestSession(this.props.sessionId)
            .then(() => {
                this.forceUpdate();
                //return schemaManager.load(session.area.id);
            });

        // load schema
        this.ordersManager.subscribe(ORDERS_MANAGER_EVENTS.SESSION_STATE_REFRESH, this, () => {
            this.forceUpdate();
        });

        this.cartManager.subscribe(CART_EVENTS.CART_IS_RELEASED, this, () => {
            this.setState({
                rightBarOpen: false,
            });
            this.props.enqueueSnackbar('Заказ успешно оформлен.', {variant: 'success'});
        });

        window.addEventListener('resize', this.handleResize);
        window.addEventListener('scroll', this.handleScroll);
    }

    handleResize = () => {
        const cartMode = this.getCartMode();
        if (cartMode !== this.state.cartMode) {
            this.setState({
                cartMode
            });
        }

        const footerHeight = this.getFooterHeight();
        if (footerHeight !== this.state.footerHeight) {
            this.setState({
                footerHeight
            });
        }
    };

    handleScroll = () => {
        if (!this.cartContainer.current) {
            return;
        }
        const sticky = this.cartContainer.current.getBoundingClientRect().top < 0;
        if (this.state.sticky !== sticky) {
            this.setState({
                sticky,
            });
        }
    };

    componentWillUnmount() {
        this.ordersManager.unsubscribeFromAllEvents(this);
        this.cartManager.unsubscribeFromAllEvents(this);

        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    handleSeatClick = seat => {
        if (this.cartManager.hasSeat(seat.id)) {
            this.cartManager.deleteSeats([seat.id]);
        } else {
            const session = sessionManager.getSession(this.props.sessionId);
            if (!session.settings.isOnlinePaymentEnabled && !session.settings.isOnlineBookingEnabled) {
                this.props.enqueueSnackbar('Нельзя добавить место в корзину, т.к. заказ мест на данное мероприятие недоступен.', {variant: 'error', preventDuplicate: true});
                return;
            }

            this.cartManager.addSeats([seat.id]);
        }
    };

    handleClickStandingSpace = seat => {
        const session = sessionManager.getSession(this.props.sessionId);
        if (!session.settings.isOnlinePaymentEnabled && !session.settings.isOnlineBookingEnabled) {
            this.props.enqueueSnackbar('Нельзя добавить билет в корзину, т.к. заказ мест на данное мероприятие недоступен.', {variant: 'error', preventDuplicate: true});
            return;
        }

        this.setState({
            selectedStandingSpaceSeat: seat,
        });
    };

    handleCloseStandingSpace = () => {
        this.setState({
            selectedStandingSpaceSeat: null,
        });
    };

    handleSaveStandingSpaceItems = (seatId, count) => {
        this.setState({
            selectedStandingSpaceSeat: null,
        });
        this.cartManager.addStandingSpaceItems(seatId, count);
    };


    handleRightBarClickAway = e => {

    };

    handleRightBarToggle = () => {
        this.setState({
            rightBarOpen: !this.state.rightBarOpen,
        });
    };

    handleCartDialogOpen = () => {
        this.setState({
            isCartDialogOpen: true,
        });
    };

    handleCartDialogClose = () => {
        this.setState({
            isCartDialogOpen: false,
        });
    };

    render() {
        const session = sessionManager.getSession(this.props.sessionId);
        if (!session) {
            return null;
        }

        const { classes } = this.props;

        const { selectedStandingSpaceSeat } = this.state;

        return (
            <div style={{overflowX: 'hidden', position: 'relative', height: '100%' }} ref={ this.cartContainer }>
                {
                    this.state.cartMode === 'slider' ?
                    <ClickAwayListener onClickAway={this.handleRightBarClickAway}>
                        <div style={{
                            right: this.state.rightBarOpen ? 0 : -this.sideBarWidth,
                            width: this.sideBarWidth,
                            height: `calc(100% - ${ 6 + this.state.footerHeight}px)`,
                        }} className={ classes.sideBar }>
                            <div
                                className={ classNames({
                                    [classes.toggleBar]: true,
                                    [classes.sticky]: this.state.sticky,
                                    [classes.open]: this.state.rightBarOpen,
                                }) }
                                onClick={this.handleRightBarToggle}
                            >
                                <CartButton cartManager={ this.cartManager } />
                            </div>
                            <div style={{ height: '100%', overflowY: 'scroll' }}>
                                <h2 style={{margin: 12}}>Ваш заказ</h2>
                                <Cart
                                    cartManager={ this.cartManager }
                                    ordersManager={ this.ordersManager }
                                    priceMapManager={ this.priceMapManager }
                                    schemaManager={ schemaManagerFactory.getManager(session.event.area.id) }
                                />
                                <Ordering sessionId={ this.props.sessionId } />
                            </div>
                        </div>
                    </ClickAwayListener>
                    :
                    <React.Fragment>
                        <div className={ classes.cartButtonBar + ' ' + (this.state.sticky ? classes.sticky : '') }>
                            <div className={ classes.cartButtonContainer } onClick={ this.handleCartDialogOpen }>
                                <CartButton cartManager={ this.cartManager } />
                            </div>
                        </div>

                        <FormDialog
                            open={ this.state.isCartDialogOpen }
                            title="Ваш заказ"
                            onClose={ this.handleCartDialogClose }
                        >
                            <div style={{ margin: '0 -24px' }}>
                                <Cart
                                    cartManager={ this.cartManager }
                                    ordersManager={ this.ordersManager }
                                    priceMapManager={ this.priceMapManager }
                                    schemaManager={ schemaManagerFactory.getManager(session.event.area.id) }
                                />
                                <Ordering sessionId={ this.props.sessionId } />
                            </div>
                            <FormActions />
                        </FormDialog>

                    </React.Fragment>
                }

                {
                    selectedStandingSpaceSeat !== null &&
                    <FormDialog
                        open
                        title={ 'Заказ билетов в зоне "' + selectedStandingSpaceSeat.name + '"' }
                        onClose={ this.handleCloseStandingSpace }
                        maxWidth="xs"
                    >
                        <StandingSpaceForm
                            seat={ selectedStandingSpaceSeat }
                            onSave={ this.handleSaveStandingSpaceItems }
                            sessionId={ this.props.sessionId }
                        />
                    </FormDialog>
                }

                <div style={{ margin: 32 }}>
                    <div style={{textAlign: 'center', marginBottom: 40}}>
                        <h1>{ session.event.name } ({ session.event.age }+)</h1>
                        <div className="event-description">
                            <div>{ session.event.area.name }</div>
                            <div><DateTime>{ session.startDate }</DateTime></div>
                            <div><SeatsLeft sessionId={ this.props.sessionId } /></div>
                            <div>
                                <RefreshCountdown sessionId={ this.props.sessionId } />
                            </div>
                        </div>
                    </div>

                    <div style={{ width: 'fit-content', maxWidth: '100%', margin: '0 auto' }}>
                        <div style={{ marginBottom: 40 }}>
                            <Legend sessionId={ this.props.sessionId } />
                        </div>
                        <div style={{ margin: '0 -32px'}}>
                            <Schema schemaId={ session.event.area.id }>
                                <BookingSeats
                                    sessionId={ this.props.sessionId }
                                    onClick={ this.handleSeatClick }
                                />
                                <BookingStandingSpaces
                                    sessionId={ this.props.sessionId }
                                    onClick={ this.handleClickStandingSpace }
                                />
                            </Schema>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const s = {
    cartButtonBar: {
        position: 'absolute',
        top: 6,
        right: 6,
        zIndex: 600,
    },
    cartButtonContainer: {
        height: 54,
        background: 'white',
        width: 54,
        color: '#aaa',
        cursor: 'pointer',
        padding: 3,
        borderRadius: '28px',
        boxShadow: '-1px 0 10px 0 rgba(0, 0, 0, 0.3)',
    },
    toggleBar: {
        position: 'absolute',
        top: 0,
        height: 54,
        background: 'white',
        width: 54,
        color: '#aaa',
        cursor: 'pointer',
        padding: 3,
        left: -54,
        borderRadius: '28px 0 0 28px',
        boxShadow: '-4px 0 4px 0 rgba(0, 0, 0, 0.3)',
        zIndex: 1,
        '&$sticky': {
            right: 0,
            left: 'auto',
            transition: 'right 0.4s',
            '&$open': {
                right: 400
            }
        }
    },
    sticky: {
        position: 'fixed !important',
    },
    open: {

    },
    sideBar: {
        position: 'absolute',
        background: 'white',
        minHeight: 'calc(100% - 6px)',
        zIndex: 600,
        top: 6,
        right: 0,
        boxShadow: '-3px 0 3px 0 rgba(0, 0, 0, 0.2)',
        transition: 'right 0.4s',
        overflowY: 'visible',
    }
};

export default withSnackbar(withStyles(s)(Booking));
