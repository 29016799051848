import boardManagerFactory, { EVENTS as BOARD_EVENTS } from "../../service/BoardManager";
import SessionSeats from "./SessionSeats";

class BoardSeats extends SessionSeats
{
    constructor(props) {
        super(props);
        this.boardManager = boardManagerFactory.getManager(this.props.boardId);
    }

    componentDidMount() {
        this.boardManager.subscribe(BOARD_EVENTS.BOARD_IS_LOADED, this, () => {
            this.forceUpdate();
        });

        super.componentDidMount();
    }

    componentWillUnmount() {
        this.boardManager.unsubscribeFromAllEvents(this);
        super.componentWillUnmount();
    }

    getSelectedSeats() {
        const lastResponse = this.boardManager.getLastResponse();
        return lastResponse.hasOwnProperty('state')
            ? lastResponse['state']['seats']
            : [];
    }
}

export default BoardSeats;

