import React from 'react';
import PropTypes from "prop-types";
import {FormActions, FormAlert, FormBackdrop} from "../FormDialog/Form";
import {Button, Icon, IconButton, TextField} from "@material-ui/core";
import ordersManagerFactory from "../../service/OrdersManager";
import priceMapManagerFactory from "../../service/PriceMapManager";

class StandingSpaceForm extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            message: null,
            errors: {},
            loading: false,
            count: 1,
        }

        this.ordersManager = ordersManagerFactory.getManager(this.props.sessionId);
        this.priceMapManager = priceMapManagerFactory.getManager(this.props.sessionId);
    }

    handleChangeCount = event => {
        if (!event.target.value.match(/^\d*$/)) {
            return;
        }
        let count = parseInt(event.target.value);

        const { orderedCount, totalCount } = this.props;
        const availableCount = totalCount - orderedCount;

        if (count > availableCount) {
            count = availableCount;
        }

        this.setState(state => {
            const errors = {...state.errors};
            delete errors['count'];
            return {
                errors,
                message: errors.size > 0 ? state.message : null,
                count,
            }
        });
    };

    handleMessageClose = () => {
        this.setState({
            message: null,
            errors: {},
        });
    };

    handleSave = () => {
        this.setState({
            message: null,
            errors: {},
        });
        const errors = {};
        if (this.state.count > 10) {
            errors.count = 'Не более 10 билетов в одном заказе.';
        }
        if (this.state.count < 1) {
            errors.count = 'Чтобы сохранить добавьте по крайней мере 1 билет.';
        }

        if (Object.keys(errors).length > 0) {
            this.setState({
                errors,
                message: {
                    type: 'danger',
                    text: 'Некорректные данные для сохранения.',
                }
            });

            return;
        }

        this.props.onSave && this.props.onSave(this.props.seat.id, this.state.count);
    };

    handleIncrementCount = () => {
        this.setState(state => {
            const { orderedCount, totalCount } = this.props;
            const availableCount = totalCount - orderedCount;
            if (state.count >= availableCount) {
                return {};
            }
            return {
                count: state.count + 1
            }
        });
    };

    handleDecrementCount = () => {
        this.setState(state => {
            if (state.count <= 1) {
                return {};
            }
            return {
                count: state.count - 1
            }
        });
    };

    render() {
        const seatState = this.ordersManager.getSeatState(this.props.seat.id);

        let orderedCount = 0;
        if (seatState){
            Object.keys(seatState).map(status => orderedCount += seatState[status]);
        }

        const totalCount = this.priceMapManager.getSeatPriceRange(this.props.seat.id).count;

        const { errors, count, message } = this.state;

        const availableCount = totalCount - orderedCount;

        return (
            <div>
                <FormBackdrop loading={ false }>
                    {
                        message &&
                        <FormAlert
                            type={ message.type }
                            onClose={ this.handleMessageClose }
                        >
                            { message.text }
                        </FormAlert>
                    }
                    <div style={{ position: 'relative' }}>
                        <div style={{ marginRight: 88 }}>
                            <TextField
                                label={ <span style={{ whiteSpace: 'nowrap'}}>{ "Количество билетов (доступно: " + availableCount + " из " + totalCount + ")" }</span> }
                                fullWidth
                                margin="dense"
                                value={ count }
                                helperText={ errors['count'] || '' }
                                error={ errors.hasOwnProperty('count') }
                                InputProps={ {disableUnderline: false} }
                                onChange={ this.handleChangeCount }
                            />
                        </div>
                        <div style={{ position: 'absolute', right: 0, top: 0 }}>
                            <IconButton onClick={ this.handleIncrementCount } disabled={ count >= availableCount }>
                                <Icon fontSize="small">add</Icon>
                            </IconButton>
                            <IconButton onClick={ this.handleDecrementCount } disabled={ count <= 1 }>
                                <Icon fontSize="small">remove</Icon>
                            </IconButton>
                        </div>
                    </div>
                    <FormActions>
                        <Button
                            color="primary"
                            onClick={ this.handleSave }
                        >
                            Сохранить
                        </Button>
                    </FormActions>
                </FormBackdrop>
            </div>
        );
    }
}

StandingSpaceForm.propTypes = {
    seat: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired,
    onSave: PropTypes.func.isRequired,
    sessionId: PropTypes.number.isRequired,
};

export default StandingSpaceForm;
