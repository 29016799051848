import BackendService from './BackendService';
import apiRoutes, {reverse} from "../api/routes";

export const EVENTS = {
    BOARD_IS_LOADED: 'BOARD_IS_LOADED',
};

class BoardManager extends BackendService
{
    constructor(boardId) {
        super();
        this.boardId = boardId;
        this.lastResponse = null;
    }

    requestBoard() {
        return this.requestApi(reverse(apiRoutes.board), 'GET', {action: 'status', board_id: this.boardId})
            .then(response => {
                this.lastResponse = response;
                this.dispatch(EVENTS.BOARD_IS_LOADED, response);
                return response;
            });
    }

    getLastResponse() {
        return this.lastResponse;
    }
}

class Factory
{
    managers = new Map();

    getManager(boardId){
        boardId = parseInt(boardId);
        if (this.managers.has(boardId)) {
            return this.managers.get(boardId);
        }
        const manager = new BoardManager(boardId);
        this.managers.set(boardId, manager);

        return manager;
    }
}

export default new Factory();
