import React from 'react';
import Button from "@material-ui/core/Button/Button";

export default class LoadingButton extends React.Component
{
    handleClick = () => {
        this.props.onClick && this.props.onClick(this.props.name);
    };

    render(){
        let { children, disabled, loading, ...rest } = this.props;
        if (this.props.loading){
            disabled = true;
        }
        return <Button { ...rest } disabled={ disabled } onClick={ this.handleClick }>
            { loading ? 'Loading...' : children }
        </Button>
    }
}
