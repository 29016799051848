import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import userManager from '../../service/UserManager';
import Button from "@material-ui/core/Button/Button";
import LoadingButton from "../LoadingButton";
import { FormActions, FormAlert, FormBackdrop } from "../FormDialog/Form";

const CODE_SENDING_STATUS = {
    NOT_SENT: 1,
    SENT: 2,
};

export default class RestoreAccessForm extends React.PureComponent
{
    constructor(props){
        super(props);

        this.state = {
            errors: {},
            message: null,
            contact: null,
            code: null,
            codeSendingStatus: CODE_SENDING_STATUS.NOT_SENT,
            loading: false,
        };
    }

    hideMessages = () => {
        this.setState({
            errors: {},
            message: null,
        });
    };

    handleInputChange = event => {
        const value = event.target.value || null;
        const name = event.target.name;

        this.setState(state => {
            const errors = {...state.errors};
            delete errors[name];
            return {
                errors,
                message: Object.keys(errors).length > 0 ? state.message : null,
                [name]: value,
            }
        });
    };

    handleCodeRequest = () => {
        const errorMessage = 'Пожалуйста, исправьте ошибки ввода.';
        const errors = {};
        if (!this.state.contact){
            errors['contact'] = 'Требуется заполнить это поле.';
            this.setState({
                errors,
                message: {
                    type: 'danger',
                    text: errorMessage,
                }
            });

            return;
        }

        this.setState({
            errors,
            message: null,
            loading: true,
        });
        userManager
            .restoreAccessRequest(this.state.contact)
            .then(() => {
                this.setState({
                    codeSendingStatus: CODE_SENDING_STATUS.SENT,
                    loading: false,
                });
            })
            .catch(error => {
                const details = error.data ? (error.data.details || {}) : {};
                this.setState({
                    loading: false,
                    errors: details,
                    message: {
                        type: 'danger',
                        text: error.message,
                    }
                });
            });
    };

    handleLogin = () => {
        const errorMessage = 'Пожалуйста, исправьте ошибки ввода.';
        const errors = {};
        if (!this.state.code){
            errors['code'] = 'Требуется заполнить это поле.';
            this.setState({
                errors,
                message: {
                    type: 'danger',
                    text: errorMessage,
                }
            });

            return;
        }

        this.setState({
            errors,
            message: null,
            loading: true,
        });
        userManager
            .restoreAccessLogin(this.state.contact, this.state.code)
            .then(() => {
                this.setState({
                    codeSendingStatus: CODE_SENDING_STATUS.NOT_SENT,
                    loading: false,
                });
                this.props.onRestoreAccess();
            })
            .catch(error => {
                const details = error.data ? (error.data.details || {}) : {};
                this.setState({
                    loading: false,
                    errors: details,
                    message: {
                        type: 'danger',
                        text: error.message,
                    }
                });
            });
    };

    handleChangeContact = () => {
        this.setState({
            message: null,
            code: null,
            codeSendingStatus: CODE_SENDING_STATUS.NOT_SENT,
        });
    };

    render(){
        const { errors, message, loading, codeSendingStatus } = this.state;

        return (
            <div>
                {
                    message &&
                    <FormAlert type={ message.type } onClose={ this.hideMessages }>
                        { message.text }
                    </FormAlert>
                }
                <FormBackdrop loading={ loading }>
                    <div>
                        <p>Введите адрес электронной почты или телефон, указанные и подтвержденные ранее.</p>
                        <TextField
                            label="Email или номер телефона"
                            fullWidth
                            margin="dense"
                            name="contact"
                            value={ this.state.contact || '' }
                            helperText={ errors['contact'] || '' }
                            error={ errors.hasOwnProperty('contact') }
                            InputProps={ {disableUnderline: false} }
                            onChange={ this.handleInputChange }
                            disabled={ codeSendingStatus === CODE_SENDING_STATUS.SENT }
                        />
                    </div>
                    {
                        codeSendingStatus === CODE_SENDING_STATUS.SENT &&
                        <div>
                            <p>На указанный email/телефон был выслан код. Введите его в поле ниже, чтобы войти на сайт под своей учетной записью.</p>
                            <TextField
                                label="Код для входа"
                                fullWidth
                                margin="dense"
                                name="code"
                                value={ this.state.code || '' }
                                helperText={ errors['code'] || '' }
                                error={ errors.hasOwnProperty('code') }
                                InputProps={ {disableUnderline: false} }
                                onChange={ this.handleInputChange }
                            />
                        </div>
                    }
                </FormBackdrop>
                <FormActions>
                    {
                        codeSendingStatus === CODE_SENDING_STATUS.NOT_SENT &&
                        <LoadingButton
                            color="primary"
                            onClick={ this.handleCodeRequest }
                            loading={ loading }
                        >
                            Выслать код доступа
                        </LoadingButton>
                    }
                    {
                        codeSendingStatus === CODE_SENDING_STATUS.SENT &&
                        <LoadingButton
                            color="primary"
                            onClick={ this.handleLogin }
                            loading={ loading }
                        >
                            Войти
                        </LoadingButton>
                    }
                    {
                        codeSendingStatus === CODE_SENDING_STATUS.SENT &&
                        <Button color="default" onClick={ this.handleChangeContact }>
                            Изменить email/телефон
                        </Button>
                    }
                </FormActions>
            </div>
        )
    }
}

RestoreAccessForm.propTypes = {
    onRestoreAccess: PropTypes.func.isRequired,
};
