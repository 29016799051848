import React from 'react';
import FormDialog from "../../components/FormDialog/FormDialog";
import ProfileForm from "../../components/ProfileForm";
import userManager, { EVENTS as USER_EVENTS } from "../../service/UserManager";
import { withSnackbar } from 'notistack';
import {FormAlert} from "../FormDialog/Form";

class ProfileDialog extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            user: null,
            invitation: false,
        };
    }

    componentDidMount(){
        userManager.subscribe(USER_EVENTS.CREATE_PROFILE_REQUEST, this, () => {
            this.setState({
                user: userManager.constructor.createUser(),
            });
        });
        userManager.subscribe(USER_EVENTS.EDIT_PROFILE_REQUEST, this, ({forced}) => {
            this.setState({
                user: userManager.getUser(),
                invitation: forced
            });
        });
    }

    componentWillUnmount(){
        userManager.unsubscribeFromAllEvents(this);
    }

    handleSave = user => {
        const { email, phone } = this.state.user;
        let contactsToConfirm = [];
        if (user.email && user.email !== email) {
            contactsToConfirm.push('email');
        }
        if (user.phone && user.phone !== phone) {
            contactsToConfirm.push('phone');
        }
        this.setState({
            user: null,
        });
        this.props.enqueueSnackbar('Профиль успешно сохранен', {variant: 'success'});

        if (contactsToConfirm.length > 0){
            userManager.confirmContacts(contactsToConfirm);
        }
    };

    handleClose = () => {
        this.setState({
            user: null,
        });
    };

    handleCloseInvitation = () => {
        this.setState({
            invitation: false,
        });
    };

    render(){
        if (!this.state.user) {
            return null;
        }
        return (
            <FormDialog
                open
                title="Профиль пользователя"
                onClose={ this.handleClose }
                maxWidth="xs"
            >
                {
                    this.state.invitation === true &&
                    <FormAlert onClose={ this.handleCloseInvitation } type="info">
                        Просим Вас оставить информацию для связи с Вами в случае необходимости верификации платежа или билета, а также в случае отмены или переноса мероприятия.
                    </FormAlert>
                }
                <ProfileForm user={ this.state.user } onSave={ this.handleSave } />
            </FormDialog>
        )
    }
}

export default withSnackbar(ProfileDialog);
