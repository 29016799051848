import Api from './Api';
import config from '../params';
import { routes } from "../routes";

class BackendApi extends Api
{
    constructor(){
        super();
        this.apiEndpoint = config.apiEndpoint;
        this.userToken = null;
    }

    static createError(error){
        const emptyError = {
            code: null,
            message: null,
            data: null,
        };
        return { ...emptyError, ...error }
    }

    static getError(xhr){
        try {
            const result = JSON.parse(xhr.response);
            return this.createError({
                code: xhr.status,
                message: result.message,
                data: result.data || null,
            });
        } catch (e) {
            return this.createError({
                code: xhr.status,
                message: 'Malformed response',
            });
        }
    }

    getPromiseKey(method, url){
        return method + '_' + url + (this.userToken ? '_' + this.userToken : '');
    }

    createPromise(url, method, postData = null){
        return new Promise((resolve, reject) =>{
            const xhr = new XMLHttpRequest();
            xhr.timeout = 120000;

            xhr.onload = () =>{
                this.userToken = xhr.getResponseHeader('x-set-user-token');

                if (xhr.status === 401){
                    window.location.replace(routes.login + '?session_is_over');
                    return;
                }

                if (xhr.status === 200){
                    try {
                        const result = JSON.parse(xhr.response);
                        if (result.status === 'ERROR') {
                            reject(this.constructor.createError({
                                code: xhr.status,
                                message: result.message,
                                data: result.data,
                            }));
                        } else {
                            resolve(result);
                        }
                    } catch (e){
                        reject(this.constructor.createError({
                            code: xhr.status,
                            message: 'Malformed response',
                        }));
                    }
                } else {
                    reject(this.constructor.getError(xhr));
                }

            };
            xhr.onerror = () =>{
                reject(this.constructor.createError({
                    message: 'Connection error',
                }))
            };
            xhr.ontimeout = () =>{
                reject(this.constructor.createError({
                    message: 'Connection timeout',
                }))
            };

            xhr.withCredentials = true;

            let u = this.apiEndpoint + '/api' + url;

            if (url.match(/^\/[a-z0-9_]+\.php/)) {
                const m = this.apiEndpoint.match(/(.+)(\/app_dev.php|\/app.php)(.*)/);
                if (m !== null) {
                    u = m[1] + m[3] + url;
                } else {
                    u = this.apiEndpoint + url;
                }
                xhr.withCredentials = false;
            }
            
            if (u.indexOf('?') === -1){
                u += '?';
            } else {
                u += '&';
            }
            u += 'ajax'

            xhr.open(method, u);

            if (this.userToken){
                xhr.setRequestHeader("x-user-token", this.userToken);
            }

            if (method === 'GET'){
                xhr.send(null);
            } else {
                xhr.send(postData ? JSON.stringify(postData) : null);
            }
        });
    }

    getApiUrl(url, params){
        if (params){
            if (url.indexOf('?') === -1){
                url += '?';
            } else {
                url += '&';
            }
            url += this.constructor.serialize(params)
        }

        return this.apiEndpoint + url;
    }
}


export default new BackendApi();
export { BackendApi };
