import React from 'react';
import { withSnackbar } from 'notistack';
import RestoreAccessForm from "./RestoreAccessForm";
import userManager, { EVENTS as USER_EVENTS } from "../../service/UserManager";
import FormDialog from "../FormDialog/FormDialog";

class RestoreAccessDialog extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            open: false,
        };
    }

    componentDidMount(){
        userManager.subscribe(USER_EVENTS.RESTORE_ACCESS_REQUEST, this, () => {
            this.setState({
                open: true,
            });
        });
    }

    componentWillUnmount(){
        userManager.unsubscribeFromAllEvents(this);
    }

    handleRestoreAccess = () => {
        this.setState({
            open: false,
        });
        this.props.enqueueSnackbar('Вы успешно авторизованы в системе.', {variant: 'success'});
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    render(){
        if (!this.state.open){
            return null;
        }
        return (
            <FormDialog
                open
                title="Восстановление доступа"
                //onSave={ this.handleSave }
                onClose={ this.handleClose }
                maxWidth="xs"
            >
                <RestoreAccessForm onRestoreAccess={ this.handleRestoreAccess } />
            </FormDialog>
        )
    }
}

export default withSnackbar(RestoreAccessDialog);
