import React from 'react';
import PropTypes from "prop-types";
import './style.css';

export default class Preloader extends React.PureComponent
{
    render(){
        return (
            <div className="c-preloader loaded">
                <div className="animation-preloader">
                    <div className="spinner" />
                    <div className="txt-loading">
                        {
                            this.props.text.split('').map((letter, i) => {
                                const style = {
                                    animationDelay: (i + 1) * 0.2 + 's',
                                };
                                return (
                                    <span key={ letter + i } style={{ position: 'relative' }}>
                                        <span className="letters-animated" style={ style }>{ letter }</span>
                                        <span className="letters-static">{ letter }</span>
                                    </span>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

Preloader.propTypes = {
    text: PropTypes.string.isRequired,
};

Preloader.defaultProps = {
    text: 'SEAT BOOKING',
};
