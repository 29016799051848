import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';

class DottedLink extends React.PureComponent
{
    render() {
        //if (typeof this.props.children === 'string') {
            let { classes, className, ...rest } = this.props;
            if (className) {
                className = classNames(className, classes.root);
            } else {
                className = classes.root;
            }
            return <span className={ className } {...rest}>{ this.props.children }</span>
        //}
        //return null;
    }
}

const style = theme => ({
    root: {
        color: theme.palette.primary.main,
        borderBottom: '1px dotted',
        cursor: 'pointer',
    }
});

export default withStyles(style)(DottedLink);
