import React from 'react';
import {render} from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from "react-router-dom";
import userManager, { EVENTS as USER_EVENTS } from './service/UserManager';
import partnerManager from './service/PartnerManager';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {SnackbarProvider} from "notistack";
import { withSnackbar, useSnackbar } from 'notistack';
import {routes, reverse} from './routes';
import { matchPath } from "react-router-dom";
import Preloader from "./components/Preloader";
import Home from "./components/Home";
import UpcomingEvents from "./components/UpcomingEvents";
import Booking from "./components/Booking";
import Board from "./components/Booking/Board";
import MainMenu from "./components/MainMenu";
import ProfileDialog from "./components/ProfileDialog";
import RestoreAccessDialog from "./components/RestoreAccess/RestoreAccessDialog";
import ContactConfirmationDialog from "./components/ContactConfirmation/ContactConfirmationDialog";
import MyOrders from "./components/MyOrders";
import OfferToVerifyContactDetails from "./components/Booking/OfferToVerifyContactDetails";
import NotificationOfAccountCreation from "./components/Booking/NotificationOfAccountCreation";
import ReservationCountdown from "./components/ReservationCountdown/ReservationCountdown";
import withStyles from "@material-ui/core/styles/withStyles";
import { ruRU } from '@material-ui/core/locale';
import PointsOfSale from "./components/PointsOfSale";
import RobokassaPaymentForm from "./components/RobokassaPaymentForm";
import UrlMessage from "./components/UrlMessage";
import {Grid} from "@material-ui/core";
import DottedLink from "./components/DottedLink";
import FormDialog from "./components/FormDialog/FormDialog";
import PushkinCardDashboard from "./components/PushkinCardDashboard";
import EntryControl from "./components/EntryControl";

const themeProps = {
    palette: {
        background: {
            default: '#fff'
        },
        primary: {
            main: '#426eca',
        },
        secondary: {
            main: '#e01414',
        }
    },
    typography: {
        caption: {
            letterSpacing: 0
        },
        // button: {
        //     letterSpacing: 0,
        //     fontWeight: 'normal',
        // }
    },
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: '0.875rem',
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '0.875rem',
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: '0.875rem',
            }
        },
        MuiButton: {
            root: {
                lineHeight: '1.5em',
            }
        },
    }
};

const themeProps2 = {
    palette: {
        background: {
            default: '#eee'
        }
    }
};
const theme = createMuiTheme(themeProps, ruRU);
const theme2 = createMuiTheme(themeProps2, ruRU);

class App extends React.Component
{
    constructor(props){
        super(props);
        this.location = null;
        this.state = {
            isOfferOpen: false,
        };
    }

    componentDidMount(){
        userManager.subscribe(USER_EVENTS.CURRENT_USER_CHANGED, this, () => this.forceUpdate());

        userManager.requestUser()
            .catch(error => {
                this.props.enqueueSnackbar(error.message, {variant: 'error'});
            });

        partnerManager.requestPartner()
            .then(() => {
                this.forceUpdate();
            })
            .catch(error => {
                this.props.enqueueSnackbar(error.message, {variant: 'error'});
            });
    }

    componentWillUnmount(){
        userManager.unsubscribe(USER_EVENTS.CURRENT_USER_CHANGED, this);
    }

    componentDidUpdate() {
        if (this.location === null || this.location.pathname !== this.props.location.pathname){
            this.location = this.props.location;
            this.props.onLocation(this.location);
        }
    }

    handleError = (message) => {
        this.props.enqueueSnackbar(message, {variant: 'error'});
    };

    handleOpenOffer = () => {
        this.setState({
            isOfferOpen: true,
        });
    };

    handleCloseOffer = () => {
        this.setState({
            isOfferOpen: false,
        });
    };

    isNeedShowMainMenu() {
        const { location } = this.props;

        return location.pathname !== reverse(routes.entryControl);
    }

    isNeedShowFooter() {
        const { location } = this.props;

        return location.pathname !== reverse(routes.entryControl);
    }

    getComponent() {
        return (
            <Switch>
                <Route path={ routes.home } exact render={ () => (
                    <div style={{ padding: 32 }}>
                        <Home />
                    </div>
                )}/>
                <Route path={ routes.myOrders } exact render={() => (
                    <div style={{ padding: 32 }}>
                        <h1>Мои заказы</h1>
                        <MyOrders />
                    </div>
                )}/>
                <Route path={ routes.upcomingEvents } exact render={() => (
                    <div style={{ padding: 32 }}>
                        <h1>Предстоящие события</h1>
                        <UpcomingEvents />
                    </div>
                )}/>
                <Route path={ routes.pointsOfSale } exact render={() => (
                    <div style={{ padding: 32 }}>
                        <h1>Точки продаж билетов</h1>
                        <PointsOfSale />
                    </div>
                )}/>
                <Route path={ routes.session.index } exact render={ ({match}) =>{
                    return <Booking sessionId={ parseInt(match.params.sessionId) }/>
                } }/>
                <Route path={ routes.session.booking } exact render={ ({match}) =>{
                    return <Booking sessionId={ parseInt(match.params.sessionId) }/>
                } }/>
                <Route path={ routes.board } exact render={ ({match}) =>{
                    return <Board
                        boardId={ parseInt(match.params.boardId) }
                        //sessionId={ parseInt(match.params.sessionId) }
                    />
                } }/>

                <Route path={ reverse(routes.entryControl) } exact render={() => (
                    <div style={{ padding: 32, maxWidth: 800, width: '100%', margin: '0 auto' }}>
                        <h1>Контроль входа</h1>
                        <EntryControl />
                    </div>
                )}/>

                <Route path={ routes.pushkinCard } exact render={ ({match}) => {
                    return <div style={{ padding: 32 }}>
                        <h1>Исполненные платежи</h1>
                        <PushkinCardDashboard
                            shopId={ match.params.shopId }
                        />
                    </div>
                } }/>

                <Redirect to={ routes.home } />
            </Switch>
        );
    }

    render() {
        if (userManager.isUnknownUser() || partnerManager.isUnknownPartner()) {
            return <React.Fragment>
                <RobokassaPaymentForm />
                <Preloader />
            </React.Fragment>
        }

        const partner = partnerManager.getPartner();
        const requisites = [];
        if (partner.ogrn) {
            requisites.push('ОГРН: ' + partner.ogrn);
        }
        if (partner.ogrnip) {
            requisites.push('ОГРНИП: ' + partner.ogrnip);
        }
        if (partner.inn){
            requisites.push('ИНН: ' + partner.inn);
        }

        return <Switch>
            <Route path={ routes.board } exact render={ ({match}) =>{
                return <Board
                    boardId={ parseInt(match.params.boardId) }
                    //sessionId={ parseInt(match.params.sessionId) }
                />
            } }/>
            <Route>
                <React.Fragment>
                    <UrlMessage />
                    {
                        this.isNeedShowMainMenu() &&
                        <MainMenu />
                    }
                    <main className={ this.props.classes.main }>
                        <div className={ this.props.classes.x }>
                            <OfferToVerifyContactDetails />
                            <NotificationOfAccountCreation />
                            <ReservationCountdown onError={ this.handleError } />
                        </div>
                        { this.getComponent() }
                        <ProfileDialog />
                        <RestoreAccessDialog />
                        <ContactConfirmationDialog />
                    </main>
                    {
                        this.isNeedShowFooter() &&
                        <footer className={ this.props.classes.footer }>
                            <div>
                                <Grid container>
                                    <Grid item sm={ 6 } style={{ textAlign: 'left' }}>
                                        <div>Билетный оператор: { partner.title }</div>
                                        {
                                            partner.site &&
                                            <div>Официальный сайт: <a href={ partner.site } target="_blank">{ partner.site }</a></div>
                                        }
                                        <div>{ requisites.join(', ') }</div>
                                        {
                                            partner.description &&
                                            <div>{ partner.description }</div>
                                        }
                                        <div>
                                            <DottedLink onClick={ this.handleOpenOffer } style={{ color: '#fff' }}>Условия обслуживания</DottedLink>
                                        </div>
                                    </Grid>
                                    <Grid item sm={ 6 } style={{ textAlign: 'left' }}>
                                        {
                                            partner.phones &&
                                            partner.phones.map(phone => (
                                                <div className="agent-contact">
                                                    <div className="phone">
                                                        <span className="phone-number">{ phone.number }</span>
                                                    </div>
                                                    {
                                                        phone.comment &&
                                                        <span className="comment">
                                                        { phone.comment }
                                                    </span>
                                                    }
                                                </div>
                                            ))
                                        }
                                        {
                                            partner.emails &&
                                            partner.emails.map(email => (
                                                <div className="agent-contact">
                                                    <div className="email">
                                                        <a href="mailto:{{ email }}">{ email }</a>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Grid>
                                </Grid>

                                <FormDialog
                                    open={ this.state.isOfferOpen }
                                    title="Условия обслуживания"
                                    onClose={ this.handleCloseOffer }
                                    fullWidth
                                    maxWidth="md"
                                >
                                    <div dangerouslySetInnerHTML={{ __html: partner.offer }} className={ this.props.classes.offer } />
                                </FormDialog>

                                {/*Система онлайн-бронирования и продажи билетов на культурные мероприятия Seat-booking.com<br />*/}
                                {/*<Link to={ routes.pointsOfSale }>Точки продаж билетов</Link>*/}
                            </div>
                        </footer>
                    }
                </React.Fragment>
            </Route>
        </Switch>

        //const mainCssContainer = this.props.location.pathname === routes.client ? 'container__map-page' : '';

    }
}

const s = {
    x: {
        '& > div': {
            marginBottom: 1
        },
        '& > div:last-child': {
            marginBottom: 0
        }
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    footer: {
        padding: 20,
        background: '#005285',
        color: '#fff',
        textAlign: 'center',
        '& a': {
            color: '#fff',
        }
    },
    offer: {
        '& h1': {
            fontSize: '1.5em',
        }
    }
};

const Apps = withStyles(s)(withSnackbar(withRouter(App)));

function CloseSnackbar({ notiKey }) {
    const { closeSnackbar } = useSnackbar();

    return <i className='fas fa-times' style={{cursor: 'pointer'}} onClick={() => closeSnackbar(notiKey)}/>;
}

const injectClose = notiKey => <CloseSnackbar notiKey={notiKey} />;

class Wrapper extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            theme: theme
        }
    }

    chooseTheme = (loc) => {
        const match = matchPath(loc['pathname'], {
            path: [routes.login],
            exact: true,
            strict: true
        });

        if (match) {
            this.setState({
               theme: theme2
            });
        } else {
            this.setState({
                theme: theme
            });
        }
    };

    render() {
        return (
            <MuiThemeProvider theme={this.state.theme}>
                <CssBaseline/>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    autoHideDuration={5000}
                    action={injectClose}
                >
                <Router>
                    <Route path="/" render={(props) => <Apps {...props} onLocation={ this.chooseTheme } />} />
                </Router>
                </SnackbarProvider>
            </MuiThemeProvider>
        )
    }
}

render(<Wrapper />, document.getElementById('app'));