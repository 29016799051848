import React from 'react';
import userManager, { EVENTS as USER_EVENTS } from "../../service/UserManager";
import { EVENTS as CART_EVENTS } from "../../service/CartManager";
import myOrdersManager from "../../service/MyOrdersManager";
import ItemReservationCountdown from "./ItemReservationCountdown";
import moment from "moment";
import dispatcher from "../../service/dispatcher";

class ReservationCountdown extends React.PureComponent
{
    constructor(props){
        super(props);

        this.state = {
            orders: null,
        };

        this.interval = null;

        if (userManager.getUser()) {
            this.updateOrders();
        }
    }

    componentDidMount() {
        userManager.subscribe(USER_EVENTS.CURRENT_USER_CHANGED, this, user => {
            this.setState({
                orders: null,
            });
            if (user && !this.interval) {
                this.updateOrders();
            } else if (!user && this.interval) {
                clearInterval(this.interval);
            }
        });

        dispatcher.subscribe(CART_EVENTS.CART_IS_RELEASED, this, () => {
            this.updateOrders();
        })
    }

    componentWillUnmount() {
        userManager.unsubscribeFromAllEvents(this);
    }

    updateOrders = () => {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.requestOrders();
        }, 60000);
        
        return this.requestOrders();
    };
    
    requestOrders = () => {
        myOrdersManager.getReservations()
            .then(response => {
                const orders = [];
                for (let i = 0; i < response.data.orders.length; i++) {
                    const order = {...response.data.orders[i]};
                    order.session = response.data.sessions.find(session => session.id === order.sessionId);
                    order.expiresIn = moment(order.expiresAt).unix() - moment(response.data.now).unix();
                    orders.push(order);
                }
                orders.sort((a, b) => a.expiresIn - b.expiresIn);

                this.setState({
                    orders,
                });
            })
    };

    handleExpired = order => {
        const orders = [...this.state.orders];
        for (let i = 0; i < orders.length; i++) {
            if (orders[i].id === order.id) {
                orders.splice(i, 1);
                this.setState({
                    orders,
                });
                return;
            }
        }
    };

    render() {
        const { orders } = this.state;
        if (!orders) {
            return null;
        }
        return (
            <div>
                {
                    orders.map(order => <ItemReservationCountdown
                        key={ order.id }
                        order={ order }
                        onExpired={ () => this.handleExpired(order) }
                        onError={ this.props.onError }
                    />)
                }
            </div>
        )
    }
}

export default ReservationCountdown;
