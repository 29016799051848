import backend, { BackendApi } from "../api/BackendApi";
import dispatcher from "./dispatcher";

export default class BackendService
{
    constructor(){
        this._backend = backend;
        this._dispatcher = dispatcher;
    }

    subscribe(eventType, tag, handler){
        this._dispatcher.subscribe(eventType, tag, handler);
    }

    unsubscribe(eventType, tag){
        this._dispatcher.unsubscribe(eventType, tag);
    }

    unsubscribeFromAllEvents(tag){
        this._dispatcher.unsubscribeFromAllEvents(tag);
    }

    dispatch(eventType, ...data){
        this._dispatcher.dispatch(eventType, ...data);
    }

    requestApi(url, method, queryData = null, postData = null){
        return this._backend.requestApi(url, method, queryData, postData);
    }

    getApiUrl(url, params){
        return this._backend.getApiUrl(url, params);
    }

    static getError(xhr){
        return BackendApi.getError(xhr);
    }

    static getResolvedPromise(value){
        const promise = Promise.resolve(value);
        promise.instant = true;
        return promise;
    }

    static getRejectedPromise(reason){
        const promise = Promise.reject(reason);
        promise.instant = true;
        return promise;
    }

    static isInstantPromise(promise){
        return promise.instant === true;
    }

    static getNetworkErrorResponseMock = (message, timeout) => {
        if (!timeout) {
            return Promise.reject({
                message,
            });
        }

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject({
                    message,
                });
            }, timeout);
        });
    };

    static getRequestErrorResponseMock = (message, timeout) => {
        if (!timeout) {
            return Promise.resolve({
                status: 'ERROR',
                message,
            });
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    status: 'ERROR',
                    message,
                });
            }, timeout);
        });
    };

    static getSuccessResponseMock = (message, data, timeout) => {
        if (!timeout) {
            return Promise.resolve({
                status: 'OK',
                message,
            });
        }

        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    status: 'OK',
                    message,
                    data,
                });
            }, timeout);
        });
    };
}
