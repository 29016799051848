import BackendService from './BackendService';
import apiRoutes, {reverse} from "../api/routes";
import cartManagerFactory, { EVENTS as CART_EVENTS } from "./CartManager";

export const EVENTS = {
    ORDER: 'order',
    SESSION_STATE_REFRESH: 'SESSION_STATE_REFRESH',
};

export const SEAT_STATUS = {
    booked: 'b',
    bookedByMe: 'B',
    purchased: 's',
    purchasedByMe: 'S',
    reserved: 'r',
    free: '',
    cart: 'c',
    unavailable: 'u'
};

class OrdersManager extends BackendService
{
    constructor(sessionId) {
        super();
        this.sessionId = sessionId;

        this.seats = null;

        this.seatsByStatuses = new Map();
        this.resetCounters();

        this.cartManager = cartManagerFactory.getManager(sessionId);
        this.cartManager.subscribe(CART_EVENTS.CART_IS_RELEASED, this, () => {
            this.refresh();
        });
    }

    getSeats(){
        return this.seats;
    }

    refresh = () => {
        return this.requestSeatsStatuses();
    };

    resetCounters(){
        Object.keys(SEAT_STATUS).forEach(status => this.seatsByStatuses.set(SEAT_STATUS[status], 0));
    }

    requestSeatsStatuses(){
        this.requestApi(reverse(apiRoutes.session.seats, {sessionId: this.sessionId}), 'GET')
            .then(response => {
                this.seats = new Map();

                this.resetCounters();

                for (let seat of response.seats) {
                    this.seats.set(parseInt(seat.id), seat);

                    if (seat.status){
                        Object.keys(seat.status).map(status => {
                            let c = this.seatsByStatuses.get(status);
                            c = c + seat.status[status];
                            this.seatsByStatuses.set(status, c);
                        });
                    }
                }

                const cartItems = this.cartManager.getItems();
                const itemIdsToDelete = [];
                for (let cartItem of cartItems) {
                    if (!this.seats.has(cartItem.seatId)) {
                        // удалить из корзины
                        itemIdsToDelete.push(cartItem.id);
                    }
                }
                if (itemIdsToDelete.length > 0) {
                    this.cartManager.deleteItems(itemIdsToDelete); /// ? нужно ли это?
                }

                this.dispatch(EVENTS.SESSION_STATE_REFRESH);
            })
    }

    getSeat(seatId) {
        if (this.seats === null) {
            return null;
        }
        return this.seats.get(seatId) || null;
    }

    getSeatState(seatId){
        if (this.seats === null) {
            return null;
        }
        seatId = parseInt(seatId);
        if (!this.seats.has(seatId)) {
            return null;
        }
        const seat = this.seats.get(seatId);

        return seat.status;
    }

    getOccupiedSeatsCount(){
        if (this.seats === null) {
            return 0;
        }
        return this.seatsByStatuses.get(SEAT_STATUS.reserved)
            + this.seatsByStatuses.get(SEAT_STATUS.purchasedByMe)
            + this.seatsByStatuses.get(SEAT_STATUS.purchased)
            + this.seatsByStatuses.get(SEAT_STATUS.bookedByMe)
            + this.seatsByStatuses.get(SEAT_STATUS.booked)
        ;
    }
}

class Factory
{
    managers = new Map();

    getManager(sessionId){
        sessionId = parseInt(sessionId);
        if (this.managers.has(sessionId)) {
            return this.managers.get(sessionId);
        }
        const manager = new OrdersManager(sessionId);
        this.managers.set(sessionId, manager);

        return manager;
    }
}

export default new Factory();