import React from 'react';
import { withSnackbar } from 'notistack';
import userManager, { EVENTS as USER_EVENTS, CONTACTS_TO_CONFIRM } from "../../service/UserManager";
import FormDialog from "../FormDialog/FormDialog";
import ContactConfirmationForm from "./ContactConfirmationForm";

const CONTACT_TYPE = {
    EMAIL: CONTACTS_TO_CONFIRM.EMAIL ? 1 : 0,
    PHONE: CONTACTS_TO_CONFIRM.PHONE ? 2 : 0,
};

class ContactConfirmationDialog extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            contactToConfirm: 0,
        };
    }

    componentDidMount(){
        userManager.subscribe(USER_EVENTS.CONTACT_CONFIRMATION_REQUESTED, this, contactTypes => {
            let contactToConfirm = 0;
            for (let contactType of contactTypes) {
                switch (contactType) {
                    case 'email':
                        contactToConfirm += CONTACT_TYPE.EMAIL;
                        break;
                    case 'phone':
                        contactToConfirm += CONTACT_TYPE.PHONE;
                        break;
                }
            }
            this.setState({
                contactToConfirm,
            });
        });
    }

    componentWillUnmount(){
        userManager.unsubscribe(USER_EVENTS.CONTACT_CONFIRMATION_REQUESTED, this);
    }

    handleContactConfirmationCancel = () => {
        this.setState({
            contactToConfirm: 0,
        });
    };

    handleContactConfirmed = (contactType, isMerged) => {
        let contactToConfirm = 0;
        switch (contactType) {
            case 'email':
                contactToConfirm = CONTACT_TYPE.EMAIL;
                break;
            case 'phone':
                contactToConfirm = CONTACT_TYPE.PHONE;
                break;
            default:
                return;
        }
        this.setState(state => ({
            contactToConfirm: state.contactToConfirm - contactToConfirm,
        }));

        const message = isMerged
           ? `Контактные данные успешно подтверждены. В системе имелась другая учетная запись, 
              в которой данный ${contactType === 'email' ? 'адрес электронной почты' : 'номер телефона'} был подтвержден ранее. 
              Обе учетные записи были объединены в одну.`
           : 'Контактные данные успешно подтверждены.';

        this.props.enqueueSnackbar(message, {variant: 'success'});
    };

    render(){
        if (!this.state.contactToConfirm){
            return null;
        }

        return (
            <FormDialog
                open
                title="Подтверждение контактных данных"
                onClose={ this.handleContactConfirmationCancel }
                maxWidth="xs"
            >
                {
                    ((this.state.contactToConfirm & CONTACT_TYPE.EMAIL) > 0) &&
                    <ContactConfirmationForm
                        contactType="email"
                        onConfirmed={ this.handleContactConfirmed }
                    />
                }
                {
                    ((this.state.contactToConfirm & CONTACT_TYPE.PHONE) > 0) &&
                    <ContactConfirmationForm
                        contactType="phone"
                        onConfirmed={ this.handleContactConfirmed }
                    />
                }
            </FormDialog>
        )
    }
}

export default withSnackbar(ContactConfirmationDialog);
