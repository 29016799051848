import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Alert from "../Alert";
import userManager, { EVENTS as USER_EVENTS } from "../../service/UserManager";
import LoadingButton from "../LoadingButton";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid/Grid";
import Backdrop from "../Backdrop";
import DottedLink from "../DottedLink";
import params from "../../params";
import {FormActions, FormAlert, FormBackdrop, FormButton} from "../FormDialog/Form";

class Login extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username || '',
            password: '',
            errors: {},
            message: null,
            loading: false,
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.username !== this.props.username) {
            this.setState({
                username: this.props.username || '',
            })
        }
    }

    handleSubmit = () => {
        const errors = {};
        for (let prop of ['username', 'password']){
            try {
                this.validateProperty(prop);
            } catch (e){
                errors[prop] = e.message;
            }
        }

        if (Object.keys(errors).length > 0){
            this.setState({
                message: {
                    type: 'danger',
                    text: 'Пожалуйста, исправьте ошибки ввода.',
                },
                errors
            });
            return;
        }

        this.setState({
            message: null,
            errors,
            loading: true,
        });

        userManager.login(this.state.username, this.state.password)
            .then(() => {
                this.setState({
                    loading: false,
                });
                if (userManager.isSuperAdmin() || userManager.isAdmin() || userManager.isSeller()) {
                    //window.location.href = params.backendUrl;
                }
            })
            .catch(error => {
                const details = error.data ? (error.data.details || {}) : {};
                this.setState({
                    message: {
                        type: 'danger',
                        text: error.message,
                    },
                    errors: details,
                    loading: false,
                });
            });
    };

    validateProperty(name){
        const value = this.state[name];
        switch (name){
            case 'username':
            case 'password':
                if (value === ''){
                    throw new Error('Это поле не может быть пустым.');
                }
                break;
            default:
                break;
        }
    }

    handleInputChange = event => {
        const name = event.target.name;
        this.hideMessages();
        this.setState({
            [name]: event.target.value
        });
    };

    hideMessages = () => {
        this.setState({
            errors: {},
            message: null
        });
    };

    handleForgotPassword = () => {
        userManager.dispatch(USER_EVENTS.RESTORE_ACCESS_REQUEST);
    };

    handleCreateProfile = () => {
        userManager.createProfile();
    };

    render() {
        const { errors, message } = this.state;
        return (
            <div>
                {
                    !this.props.hideHeader &&
                    <h2 style={{margin: '0 0 8px 0'}}>Вход в систему</h2>
                }
                {
                    message &&
                    <FormAlert onClose={ this.hideMessages } type={ message.type }>
                        { message.text }
                    </FormAlert>
                }

                <FormBackdrop loading={ this.state.loading }>
                    <TextField
                        autoFocus
                        label="Имя пользователя"
                        placeholder="Имя пользователя, номер телефона или адрес эл. почты"
                        name="username"
                        fullWidth
                        margin="dense"
                        value={ this.state.username }
                        helperText={ errors['username'] || '' }
                        error={ errors.hasOwnProperty('username') }
                        onChange={ this.handleInputChange }
                    />
                    <TextField
                        label="Пароль"
                        name="password"
                        fullWidth
                        margin="dense"
                        value={ this.state.password }
                        helperText={ errors['password'] || '' }
                        error={ errors.hasOwnProperty('password') }
                        onChange={ this.handleInputChange }
                        type="password"
                    />
                </FormBackdrop>
                <br />
                <Grid container spacing={ 1 } style={{fontSize: 'smaller', marginBottom: 8}}>
                    {
                        !this.props.hideRegister &&
                        <Grid item>
                            <DottedLink onClick={ this.handleCreateProfile }>Регистрация</DottedLink>
                        </Grid>
                    }

                    {
                        !this.props.hideRemember &&
                        <Grid item>
                            <DottedLink onClick={ this.handleForgotPassword }>Забыли пароль?</DottedLink>
                        </Grid>
                    }
                </Grid>

                <FormActions>
                    <FormButton
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={ this.handleSubmit }
                        disabled={ this.state.loading }
                    >
                        Войти
                    </FormButton>
                </FormActions>
            </div>
        )
    }
}

Login.propTypes = {
    username: PropTypes.string,
    hideHeader: PropTypes.bool,
    hideRegister: PropTypes.bool,
    hideRemember: PropTypes.bool,
};

Login.defaultProps = {
    hideHeader: false,
    hideRegister: false,
    hideRemember: false,
};

export default withRouter(Login);
