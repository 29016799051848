import React from 'react';
import DateTime from "../DateTime";
import { FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import EntryControlManager from "../../service/EntryControlManager";
import { Alert } from '@material-ui/lab';
import userManager, {EVENTS as USER_EVENTS} from "../../service/UserManager";

class ControlSettingsForm extends React.PureComponent
{
    constructor(props){
        super(props);

        this.state = {
            message: null,
            areas: null,
            events: null,
        }

        this.entryControlManager = new EntryControlManager();
    }

    componentDidMount() {
        this.loadAreas();
        userManager.subscribe(USER_EVENTS.CURRENT_USER_CHANGED, this, () => {
            this.loadAreas();
        });
    }

    componentWillUnmount(){
        userManager.unsubscribeFromAllEvents(this);
    }

    loadAreas() {
        return this.entryControlManager.requestAreas()
            .then(response => {
                this.setState({
                    areas: response.data.areas,
                });
            })
            .catch(error => {
                this.setState({
                    message: {
                        type: 'danger',
                        text: error.message
                    },
                });
            })
    }

    loadEvents(areaId) {
        return this.entryControlManager.requestEvents(areaId)
            .then(response => {
                this.setState({
                    events: response.data.events,
                });
            })
            .catch(error => {
                this.setState({
                    message: {
                        type: 'danger',
                        text: error.message
                    },
                });
            })
    }

    handleChangeArea = e => {
        const areaId = e.target.value ? parseInt(e.target.value) : null;

        this.props.onChangeSettings(areaId, null, null);

        this.setState({
            events: null,
        });
        if (areaId){
            this.loadEvents(areaId);
        }
    };

    handleChangeEvent = e => {
        const eventId = e.target.value ? parseInt(e.target.value) : null;

        this.props.onChangeSettings(this.props.areaId, eventId, null);
    };

    handleChangeSession = e => {
        const sessionId = e.target.value ? parseInt(e.target.value) : null;

        this.props.onChangeSettings(this.props.areaId, this.props.eventId, sessionId);
    };

    render() {
        const { areas, events, message } = this.state;

        const { areaId, eventId, sessionId } = this.props;

        let sessions = [];
        if (events && eventId) {
            const event = events.find(event => event.id = eventId);
            sessions = event.sessions;
        }

        return (
            <div>
                {
                    message &&
                    <Alert severity={ message.type === 'danger' ? 'error' : 'success' }>
                        { message.text }
                    </Alert>
                }
                <FormControl
                    fullWidth
                    margin="dense"
                >
                    <InputLabel shrink>Концертная площадка:</InputLabel>
                    <Select
                        value={ areaId || '' }
                        onChange={ this.handleChangeArea }
                        displayEmpty
                    >
                        <MenuItem value="">Не выбрана</MenuItem>
                        {
                            areas !== null && areas.map(area => (
                                <MenuItem key={ area.id } value={ area.id }>{ area.name }</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    margin="dense"
                >
                    <InputLabel shrink>Мероприятие:</InputLabel>
                    <Select
                        value={ eventId || '' }
                        onChange={ this.handleChangeEvent }
                        displayEmpty
                    >
                        <MenuItem value="">Не выбрано</MenuItem>
                        {
                            events !== null && events.map(event => (
                                <MenuItem key={ event.id } value={ event.id }>{ event.name }</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    margin="dense"
                >
                    <InputLabel shrink>Сеанс:</InputLabel>
                    <Select
                        value={ sessionId || '' }
                        onChange={ this.handleChangeSession }
                        displayEmpty
                    >
                        <MenuItem value="">Не выбран</MenuItem>
                        {
                            sessions.map(session => (
                                <MenuItem key={ session.id } value={ session.id }><DateTime>{ session.startDate }</DateTime></MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        )
    }
}

export default ControlSettingsForm;
