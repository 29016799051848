import React from 'react';
import eventsManager from "../../service/EventsManager";
import DottedLink from "../../components/DottedLink";
import "./styles.css";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Icon from "@material-ui/core/Icon/Icon";
import {Link} from "react-router-dom";
import {routes, reverse} from "../../routes";

export default class UpcomingEvents extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.state = {
            areas: null,
            sessionMenuAnchor: null,
        };
    }

    componentDidMount() {
        eventsManager.getUpcomingEvents()
            .then(response => {
                this.setState({
                    areas: response.areas,
                });
            });
    }

    handleSessionMenuOpen = event => {
        this.setState({
            sessionMenuAnchor: event.currentTarget,
        });
    };

    handleSessionMenuClose = () => {
        this.setState({
            sessionMenuAnchor: null,
        });
    };

    render() {
        if (this.state.areas === null) {
            return 'loading...';
        }
        if (this.state.areas.length === 0) {
            return <p>На данный момент в системе не зарегистрировано предстоящих событий. Попробуйте позже.</p>
        }

        const eventId = this.state.sessionMenuAnchor
            ? parseInt(this.state.sessionMenuAnchor.getAttribute('data-event'))
            : null;

        return <div className="cUpcomingEvents">
            <ul>
            {
                this.state.areas.map(area => (
                    <li key={ area.id }>
                        <h4>{ area.name }:</h4>
                        <ol className="events">
                            {
                                area.events.map(event => (
                                    <li key={ event.id }>
                                        <span className="cUpcomingEvents-event" data-event={ event.id } onClick={ this.handleSessionMenuOpen }>
                                            <DottedLink>{ event.name + ' (' + event.age + '+)'}</DottedLink>
                                            <Icon>arrow_drop_down</Icon>
                                        </span>
                                        <Menu
                                            anchorEl={ eventId === event.id ? this.state.sessionMenuAnchor : null }
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            getContentAnchorEl={null}
                                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                            open={ eventId === event.id }
                                            onClose={ this.handleSessionMenuClose }
                                        >
                                            {
                                                event.sessions.map(session => (
                                                    <MenuItem key={ session.id }>
                                                        <Link to={ reverse(routes.session.index, {sessionId: session.id}) }>{ session.date }</Link>
                                                    </MenuItem>
                                                ))
                                            }
                                        </Menu>
                                    </li>
                                ))
                            }
                        </ol>
                    </li>
                ))
            }
            </ul>
        </div>
    }
}
