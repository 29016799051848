import React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import './style.css';
import PropTypes from 'prop-types';

export default class Alert extends React.Component
{
    constructor(props){
        super(props);
        this.state = {
            shown: true
        };
    }

    handleClick = () => {
        if (this.props.onClose){
            this.props.onClose();
        }
        this.setState({
            shown: false
        })
    };

    render(){
        const { type, children, onClose, ...restProps } = this.props;

        if (!this.state.shown){
            return null;
        }

        const className = 'c-alert ' + type; // + ' ' + this.props.placement;

        return (
            <div className={ className } { ...restProps }>
                {
                    onClose &&
                    <IconButton aria-label="Delete" onClick={ this.handleClick }>
                        <Icon>visibility_off</Icon>
                    </IconButton>
                }
                <span>{ children }</span>
            </div>
        )
    }
}

Alert.propTypes = {
    type: PropTypes.oneOf(['success', 'warning', 'danger', 'info']),
    onClose: PropTypes.func,
    //canClose: PropTypes.bool,
    //placement: PropTypes.oneOf(['modal', 'popover', 'context']),
};

Alert.defaultProps = {
    type: 'danger',
    //canClose: false,
    //placement: 'modal',
};