import React from 'react';
import PropTypes from "prop-types";
import schemaManagerFactory from "../../service/SchemaManager";
import ordersManagerFactory, {EVENTS as ORDERS_MANAGER_EVENTS} from "../../service/OrdersManager";
import priceMapManagerFactory, {EVENTS as PRICE_MAP_MANAGER_EVENTS} from "../../service/PriceMapManager";
import SessionStandingSpace from "./SessionStandingSpace";
import {withStyles} from "@material-ui/core";
import {SchemaContext} from "../Schema/SchemaContext";

const REFRESH_INTERVAL = 60;

class SessionStandingSpaces extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.ordersManager = ordersManagerFactory.getManager(this.props.sessionId);
        this.priceMapManager = priceMapManagerFactory.getManager(this.props.sessionId);
        this.schemaManager = schemaManagerFactory.getManager(this.props.schemaId);

        this.refreshTimeout = null;
    }

    componentDidMount() {
        if (this.renderedSeatsCount) {
            this.props.onRenderSeats && this.props.onRenderSeats();
        }
        this.ordersManager.subscribe(ORDERS_MANAGER_EVENTS.SESSION_STATE_REFRESH, this, () => {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = setTimeout(() => {
                this.ordersManager.refresh();
            }, REFRESH_INTERVAL * 1000);
            this.forceUpdate();
        });

        this.priceMapManager.subscribe(PRICE_MAP_MANAGER_EVENTS.PRICE_MAP_REFRESHED, this, () => {
            this.forceUpdate();
        });

        this.ordersManager.refresh();
        this.priceMapManager.refresh();
    }

    componentWillUnmount() {
        this.ordersManager.unsubscribeFromAllEvents(this);
        this.priceMapManager.unsubscribeFromAllEvents(this);

        clearTimeout(this.refreshTimeout);
    }

    getSelectedSeats() {
        return [];
    }

    handleClick = standingSpace => {
        if (!this.props.onClick) {
            return;
        }

        const seat = standingSpace.seats[0];

        this.props.onClick(seat);
    };

    render(){
        const { classes } = this.props;
        const standingSpaces = this.schemaManager.getStandingSpaces();

        return (
            <div className={ classes.root }>
                {
                    standingSpaces.map(standingSpace => {
                        const seat = standingSpace.seats[0];
                        const priceRange = this.priceMapManager.getSeatPriceRange(seat.id);

                        let orderedCount = 0;
                        const seatState = this.ordersManager.getSeatState(seat.id);
                        if (seatState){
                            Object.keys(seatState).map(status => orderedCount += seatState[status]);
                        }

                        return <SessionStandingSpace
                            key={standingSpace.id}
                            space={standingSpace}
                            onClick={ this.handleClick }
                            color={ priceRange ? priceRange.color : null }
                            price={ priceRange ? priceRange.nominal : null }
                            orderedCount={ orderedCount }
                            totalCount={ priceRange ? priceRange.count : undefined }
                        />
                    })
                }
            </div>
        );
    }
}

SessionStandingSpaces.propTypes = {
    schemaId: PropTypes.number.isRequired,
    sessionId: PropTypes.number.isRequired,
    onClick: PropTypes.func,
};

export const styles = {
    root: {
        '& > .chair': {
            position: 'absolute',
            cursor: 'pointer',
        }
    }
};

export default withStyles(styles)((props) => {
    return <SchemaContext.Consumer>
        {
            ({ schemaId }) => <SessionStandingSpaces {...props} schemaId={ schemaId } />
        }
    </SchemaContext.Consumer>
});
