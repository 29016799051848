import React from 'react';
import PropTypes from "prop-types";
import schemaManagerFactory from "../../service/SchemaManager";
import { SchemaContext } from "./SchemaContext";
import {v4 as uuidv4} from "uuid";

class Schema extends React.PureComponent
{
    constructor(props) {
        super(props);
        // На случай если на странице одновременно есть несколько копий одной схемы.
        this.schemaElementId = 'schema-' + uuidv4();
    }

    componentDidMount() {
        schemaManagerFactory.getManager(this.props.schemaId).requestSchema()
            .then(() => {
                this.forceUpdate();
            });
    }

    render() {
        const schema = schemaManagerFactory.getManager(this.props.schemaId).getSchema(this.props.schemaId);
        if (!schema) {
            return null;
        }

        return (
            <div style={{ overflowX: 'scroll' }}>
                <div style={{position: 'relative', width: schema.width, height: schema.height, margin: '0 auto'}} id={ this.schemaElementId }>
                    <link rel="stylesheet" type="text/css" href={ schema.stylePath } />
                    <div dangerouslySetInnerHTML={{ __html:  schema.layoutHtml }} />

                    <SchemaContext.Provider value={ {
                        schemaId: this.props.schemaId,
                        schemaElementId: this.schemaElementId,
                    } }>
                        { this.props.children }
                    </SchemaContext.Provider>
                </div>
            </div>
        )
    }
}

Schema.propTypes = {
    schemaId: PropTypes.number.isRequired,
};

export default Schema;
