export default class Api
{
    constructor(){
        this.pendingPromises = new Map();
    }

    static serialize(obj, prefix) {
        const str = [];
        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                const k = prefix ? prefix + "[" + p + "]" : p;
                const v = obj[p];
                if (v === null){
                    continue;
                }
                const a = (typeof v === "object") ?
                    this.serialize(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v);
                if (a !== ''){
                    str.push(a);
                }
            }
        }
        return str.join("&");
    };

    getPromiseKey(method, url){
        return method + '_' + url;
    }

    createPromise(url, method, postData = null){
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.timeout = 20000;

            xhr.onload = () => {
                if (xhr.status === 200){
                    try {
                        const result = JSON.parse(xhr.response);
                        resolve(result);
                    } catch (e) {
                        reject('Malformed response');
                    }
                } else {
                    reject('Server error');
                }
            };
            xhr.onerror = () => {
                reject('Connection error');
            };
            xhr.ontimeout = () => {
                reject('Connection timeout');
            };

            xhr.open(method, url);

            if (method === 'GET') {
                xhr.send(null);
            } else {
                xhr.send(postData ? JSON.stringify(postData) : null);
            }
        });
    }

    requestApi(url, method, queryData = null, postData = null){
        if (queryData){
            const params = this.constructor.serialize(queryData);
            if (params !== ''){
                if (url.indexOf('?') === -1){
                    url += '?' + params;
                } else {
                    url += '&' + params;
                }
            }
        }

        const promiseKey = this.getPromiseKey(method, url);
        if (this.pendingPromises.has(promiseKey)){
            return this.pendingPromises.get(promiseKey);
        }

        const promise = this.createPromise(url, method, postData);

        this.pendingPromises.set(promiseKey, promise);

        return promise
            .then(response => {
                this.pendingPromises.delete(promiseKey);
                return response;
            })
            .catch(error => {
                this.pendingPromises.delete(promiseKey);
                throw error;
            });
    }
}
