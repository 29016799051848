import React from 'react';
import { withStyles } from "@material-ui/core";
import DateTime from "../DateTime";
import { Alert as BaseAlert } from '@material-ui/lab';

const Alert = withStyles({
    root: {
        marginBottom: 1
    }
})(BaseAlert);

class ScanResult extends React.PureComponent
{
    getClientName() {
        const { result } = this.props;
        const { orderItem } = result;
        if (orderItem.customer) {
            return orderItem.customer.displayedName;
        }
        if (orderItem.order.customer) {
            return orderItem.order.customer.displayedName;
        }

        return null;
    };

    getSeverity = () => {
        const { result } = this.props;
        if (result.dangers.length > 0) {
            return 'error';
        }
        if (result.warnings.length > 0) {
            return 'warning';
        }

        return 'success';
    };

    getSeverityClassName = (severity) => {
        const { classes } = this.props;
        switch (severity) {
            case 'error':
                return classes.error;
            case 'warning':
                return classes.warning;
            case 'success':
                return classes.success;
            default:
                return '';
        }
    };

    render() {
        const { result, classes } = this.props;

        const { orderItem, dangers, warnings } = result;

        const severity = this.getSeverity();
        return (
            <div className={ classes.root + ' ' + this.getSeverityClassName(severity) }>
                {
                    dangers.map(danger => <Alert severity="error">
                        { danger }
                    </Alert>)
                }
                {
                    warnings.map(warning => <Alert severity="warning">
                        { warning }
                    </Alert>)
                }
                <table cellSpacing="1" cellPadding="0">
                    <tbody>
                    <tr>
                        <td>Мероприятие:</td>
                        <td>{ orderItem.order.eventSession.event.name }</td>
                    </tr>
                    <tr>
                        <td>Время проведения:</td>
                        <td><DateTime>{ orderItem.order.eventSession.startDate }</DateTime></td>
                    </tr>
                    <tr>
                        <td>Место проведения:</td>
                        <td>{ orderItem.order.eventSession.event.area.name }</td>
                    </tr>
                    <tr>
                        <td>Покупатель:</td>
                        <td>{ this.getClientName() }</td>
                    </tr>
                    <tr>
                        <td>Место:</td>
                        <td>{ orderItem.seat.name }</td>
                    </tr>
                    <tr>
                        <td>Комментарий администратора:</td>
                        <td><i>{ orderItem.order.comment }</i></td>
                    </tr>
                    <tr>
                        <td>Комментарий клиента:</td>
                        <td><i>{ orderItem.order.clientComment }</i></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

const styles = () => ({
    root: {
        '& table': {
            width: '100%',
            '& > tbody > tr > td': {
                padding: 8,
                '&:first-child': {
                    maxWidth: 250,
                },
                '&:last-child': {
                    backgroundColor: '#eee',
                }
            }
        },
        '&$error table': {
            '& > tbody > tr > td': {
                '&:first-child': {
                    color: '#611a15',
                },
                '&:last-child': {
                    color: '#611a15',
                    backgroundColor: '#fdecea',
                }
            }
        },
        '&$warning table': {
            '& > tbody > tr > td': {
                '&:first-child': {
                    color: '#663c00',
                },
                '&:last-child': {
                    color: '#663c00',
                    backgroundColor: '#fff4e5',
                }
            }
        },
        '&$success table': {
            '& > tbody > tr > td': {
                '&:first-child': {
                    color: '#1e4620',
                },
                '&:last-child': {
                    color: '#1e4620',
                    backgroundColor: '#edf7ed',
                }
            }
        }
    },
    error: {

    },
    warning: {

    },
    success: {

    }
});

export default withStyles(styles)(ScanResult);
