import React from 'react';
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import {SEAT_STATUS} from "../../service/OrdersManager";
import withStyles from "@material-ui/core/styles/withStyles";

class SessionSeat extends React.PureComponent
{
    handleClick = () => {
        const { status, onClick } = this.props;
        if (status === SEAT_STATUS.free || status === SEAT_STATUS.cart) {
            onClick && onClick(this.props.seat);
        }
    };

    render() {
        const { seat, color, price, status, classes } = this.props;

        const style = {left: seat.x, top: seat.y};

        if (seat.angle) {
            style.transform = 'rotate(' + seat.angle + 'deg)';
        }

        if (color) {
            style.backgroundColor = color;
        }

        let className = 'chair';

        switch (status) {
            case SEAT_STATUS.unavailable:
                className += ' ' + classes.invisible;
                break;
            case SEAT_STATUS.free:
                break;
            case SEAT_STATUS.reserved:
                className += ' ' + classes.reserved;
                break;
            case SEAT_STATUS.cart:
                className += ' ' + classes.selection;
                break;
            default:
                className += ' ' + classes.occupied;
                break;
        }
        if (!this.props.onClick){
            className += ' ' + classes.noActions;
        }

        let title = seat.name;
        if (price) {
            title = <span>{ seat.name }<br />{ price } руб.</span>;
        }

        return (
            <Tooltip title={ title }>
                <div className={ className } style={ style } onClick={ this.handleClick } seat_id={ seat.id }>
                    { seat.label }
                </div>
            </Tooltip>
        )
    }
}

SessionSeat.propTypes = {
    seat: PropTypes.shape({
        id: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
        angle: PropTypes.number,
        name: PropTypes.string,
        label: PropTypes.string,
    }),
    color: PropTypes.string,
    price: PropTypes.number,
    status: PropTypes.string,
    onClick: PropTypes.func,
};

SessionSeat.defaultProps = {
    price: 0,
    status: SEAT_STATUS.free,
};

const styles = {
    noActions: {
        cursor: 'not-allowed !important',
    },
    reserved: {
        backgroundColor: '#ddd !important',
        cursor: 'not-allowed !important',
    },
    occupied: {
        backgroundColor: '#ddd !important',
        cursor: 'not-allowed !important',
    },
    selection: {
        backgroundColor: 'yellowgreen !important',
    },
    invisible: {
        color: 'transparent',
        background: '#ddd',
        cursor: 'not-allowed !important',
    },
};

export default withStyles(styles)(SessionSeat);
