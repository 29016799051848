import React from 'react';
import cartManagerFactory, { EVENTS as CART_EVENTS } from "../../service/CartManager";
import SessionSeats from "./SessionSeats";

class BookingSeats extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.cartManager = cartManagerFactory.getManager(this.props.sessionId);

        this.state = {
            selectedSeats: this.getSelectedSeats()
        }
    }

    componentDidMount() {
        this.cartManager.subscribe(
            [
                CART_EVENTS.CART_ITEMS_DELETED,
                CART_EVENTS.CART_ITEMS_ADDED,
                CART_EVENTS.CART_IS_RELEASED,
            ],
            this,
            () => {
                this.setState({
                    selectedSeats: this.getSelectedSeats()
                });
            }
        );
    }

    componentWillUnmount() {
        this.cartManager.unsubscribeFromAllEvents(this);
    }

    getSelectedSeats() {
        const result = [];
        for (let item of this.cartManager.getItems()) {
            result.push(item.seatId);
        }
        return result;
    }

    render() {
        return <SessionSeats
            {...this.props}
            selectedSeats={ this.state.selectedSeats }
        />
    }
}

export default BookingSeats;
