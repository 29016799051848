import BackendService from './BackendService';
import apiRoutes, {reverse} from "../api/routes";

class EntryControlManager extends BackendService
{
    check(code) {
        return this.requestApi(reverse(apiRoutes.control.check), 'GET', {code});
    }

    pass(code) {
        return this.requestApi(reverse(apiRoutes.control.pass), 'POST', {code});
    }

    requestAreas() {
        return this.requestApi(reverse(apiRoutes.control.areas), 'GET');
    }

    requestEvents(areaId) {
        return this.requestApi(reverse(apiRoutes.control.events, {areaId}), 'GET');
    }
}

export default EntryControlManager;
