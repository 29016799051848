import BackendService from './BackendService';
//import ordersManagerFactory, { EVENTS as ORDER_MANAGER_EVENTS } from "./OrdersManager";
import userManager, {EVENTS as USER_EVENTS} from "./UserManager";
import apiRoutes, {reverse} from "../api/routes";
import {v4 as uuidv4} from "uuid";

export const EVENTS = {
    CART_IS_LOADED: 'CART_IS_LOADED',
    CART_ITEMS_DELETED: 'CART_ITEMS_DELETED',
    CART_ITEMS_ADDED: 'CART_ITEMS_ADDED',
    CART_IS_RELEASED: 'CART_IS_RELEASED',
    CART_ITEM_DISCOUNT_CHANGED: 'CART_ITEM_DISCOUNT_CHANGED',
};

const ITEM_TYPE = {
    SITTING: 'sitting',
    STANDING: 'standing',
};

class CartManager extends BackendService
{
    constructor(sessionId) {
        super();
        this.sessionId = sessionId;

        this.items = [];

        this.loadItems();

        // const ordersManager = ordersManagerFactory.getManager(sessionId);
        // ordersManager.subscribe(ORDER_MANAGER_EVENTS.ORDER, this, () => {
        //     this.items = null;
        //     this.dispatch(EVENTS.CART_IS_RELEASED);
        // });

        userManager.subscribe(USER_EVENTS.CURRENT_USER_CHANGED, this, () => {
            this.loadItems();
            this.dispatch(EVENTS.CART_IS_LOADED);
        });
    }

    setDiscountForItem(itemId, discountId) {
        const item = this.items.find(item => item.id === itemId);
        if (!item) {
            return;
        }

        const oldDiscount = item.discountId;
        if (item.discountId === discountId) {
            return;
        }
        item.discountId = discountId;

        this.saveItems();

        this.dispatch(EVENTS.CART_ITEM_DISCOUNT_CHANGED, discountId, oldDiscount);
    }

    order(order, user = null) {
        return this.requestApi(
            reverse(apiRoutes.session.order, {sessionId: this.sessionId}),
            'POST',
            null,
            {
                order,
                user,
                items: this.items,
            }
        ).then(response => {
            this.clearItems();
            this.dispatch(EVENTS.CART_IS_RELEASED);

            return response;
        })
    }

    saveItems() {
        if (this.items.length === 0) {
            localStorage.removeItem(this.getStorageKey());
            return;
        }
        localStorage.setItem(this.getStorageKey(), JSON.stringify(this.items));
    }

    loadItems() {
        const storedItems = localStorage.getItem(this.getStorageKey()) || '[]';
        const items = JSON.parse(storedItems);

        this.items = [];
        for (let item of items) {
            if (typeof item === 'number') {
                this.items.push({
                    id: uuidv4(),
                    type: ITEM_TYPE.SITTING,
                    seatId: item,
                    discountId: 0,
                });
            } else {
                if (!item.id) {
                    item.id = uuidv4();
                }
                if (!item.type) {
                    item.type = ITEM_TYPE.SITTING;
                }
                this.items.push(item);
            }
        }
    }

    getItems() {
        return this.items;
    }

    clearItems(){
        this.items = [];
        this.saveItems();
    }

    getStorageKey() {
        const user = userManager.getUser();
        const userId = user ? user.id : 0;
        return 'cart_' + this.sessionId + '_' + userId + '_';
    }

    hasSeat(seatId) {
        return this.items.find(item => item.seatId === seatId) !== undefined;
    }

    addSeats(seatIds) {
        const addedItems = [];
        for (let seatId of seatIds) {
            if (this.hasSeat(seatId)) {
                continue;
            }
            const item = {
                id: uuidv4(),
                type: ITEM_TYPE.SITTING,
                seatId: seatId,
                discountId: 0,
            };
            this.items.push(item);
            addedItems.push(item);
        }

        this.saveItems();
        this.dispatch(EVENTS.CART_ITEMS_ADDED, this.items, addedItems);
    }

    deleteSeats(seatIds) {
        const deletedItems = [];
        const aliveItems = [];
        for (let item of this.items) {
            if (!item.hasOwnProperty('seatId')) {
                aliveItems.push(item);
                continue;
            }
            if (seatIds.includes(item.seatId)) {
                deletedItems.push(item);
            } else {
                aliveItems.push(item);
            }
        }
        this.items = aliveItems;

        this.saveItems();
        this.dispatch(EVENTS.CART_ITEMS_DELETED, this.items, deletedItems);
    }

    // addStandingSpaceItems(standingSpaceId, count) {
    //     const addedItems = [];
    //     for (let i = 0; i < count; i++) {
    //         const item = {
    //             id: uuidv4(),
    //             standingSpaceId,
    //             discountId: 0,
    //         };
    //         addedItems.push(item);
    //         this.items.push(item);
    //     }
    //     if (addedItems.length > 0){
    //         this.saveItems();
    //         this.dispatch(EVENTS.CART_ITEMS_ADDED, this.items, addedItems);
    //     }
    // }
    //
    // deleteStandingSpaceItems(standingSpaceId, count) {
    //     const deletedItems = [];
    //     for (let i = this.items.length - 1; i >= 0; i--) {
    //         if (count === 0) {
    //             break;
    //         }
    //         const item = this.items[i];
    //         if (item.standingSpaceId === standingSpaceId) {
    //             deletedItems.push(item);
    //             this.items.splice(i, 1);
    //             count--;
    //         }
    //     }
    //     if (deletedItems.length > 0){
    //         this.saveItems();
    //         this.dispatch(EVENTS.CART_ITEMS_DELETED, this.items, deletedItems);
    //     }
    // }

    addStandingSpaceItems(seatId, count) {
        const addedItems = [];
        for (let i = 0; i < count; i++) {
            const item = {
                id: uuidv4(),
                type: ITEM_TYPE.STANDING,
                seatId,
                discountId: 0,
            };
            addedItems.push(item);
            this.items.push(item);
        }
        if (addedItems.length > 0){
            this.saveItems();
            this.dispatch(EVENTS.CART_ITEMS_ADDED, this.items, addedItems);
        }
    }

    deleteStandingSpaceItems(seatId, count) {
        const deletedItems = [];
        for (let i = this.items.length - 1; i >= 0; i--) {
            if (count === 0) {
                break;
            }
            const item = this.items[i];
            if (item.seatId === seatId) {
                deletedItems.push(item);
                this.items.splice(i, 1);
                count--;
            }
        }
        if (deletedItems.length > 0){
            this.saveItems();
            this.dispatch(EVENTS.CART_ITEMS_DELETED, this.items, deletedItems);
        }
    }

    deleteItems(itemIds) {
        const deletedItems = [];
        const aliveItems = [];
        for (let item of this.items) {
            if (itemIds.includes(item.id)) {
                deletedItems.push(item);
            } else {
                aliveItems.push(item);
            }
        }
        this.items = aliveItems;

        this.saveItems();
        this.dispatch(EVENTS.CART_ITEMS_DELETED, this.items, deletedItems);
    }
}

class Factory
{
    managers = new Map();

    getManager(sessionId){
        sessionId = parseInt(sessionId);
        if (this.managers.has(sessionId)) {
            return this.managers.get(sessionId);
        }
        const manager = new CartManager(sessionId);
        this.managers.set(sessionId, manager);

        return manager;
    }
}

export default new Factory();
