import React from 'react';
import { Button } from "@material-ui/core";
import Alert from "../Alert";
import {DialogContext} from './DialogContext';
import Backdrop from "../Backdrop";

class FormActions extends React.PureComponent
{
    render() {
        return (
            <DialogContext.Consumer>
                { ({ onClose, styles }) => (
                    <div className={ styles.actions }>
                        { this.props.children }
                        {
                            onClose &&
                            <FormButton
                                color="default"
                                onClick={ onClose }
                            >
                                Отмена
                            </FormButton>
                        }
                    </div>
                ) }
            </DialogContext.Consumer>
        )
    }
}

class FormAlert extends React.PureComponent
{
    render() {
        const { children, ...rest } = this.props;
        return (
            <DialogContext.Consumer>
                { ({styles}) => (
                    <div className={ styles.alert }>
                        <Alert {...rest}>
                            { children }
                        </Alert>
                    </div>
                ) }
            </DialogContext.Consumer>
        )
    }
}

class FormBackdrop extends React.PureComponent
{
    render() {
        const { children, ...rest } = this.props;
        return (
            <DialogContext.Consumer>
                { ({styles}) => (
                    <div className={ styles.backdrop }>
                        <Backdrop {...rest}>
                            { children }
                        </Backdrop>
                    </div>
                ) }
            </DialogContext.Consumer>
        )
    }
}

class FormButton extends React.PureComponent
{
    render() {
        const { children, ...restProps } = this.props;
        return (
            <DialogContext.Consumer>
                { ({buttonProps}) => {
                    const p = buttonProps || {};
                    return (
                        <Button
                            { ...restProps} { ...p }
                        >
                            { children }
                        </Button>
                    )
                } }
            </DialogContext.Consumer>
        )
    }
}

export { FormActions, FormAlert, FormBackdrop, FormButton };
