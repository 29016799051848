import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { withStyles } from '@material-ui/core/styles';
import {DialogContext} from './DialogContext';

class FormDialog extends React.PureComponent
{
    render(){
        const { classes, title, ...rest } = this.props;
        if (rest.disableEnforceFocus === undefined){
            rest.disableEnforceFocus = true;
        }
        if (rest.disableBackdropClick === undefined){
            rest.disableBackdropClick = true;
        }
        return (
            <Dialog
                { ...rest }
                scroll="body"
            >
                <DialogTitle className={ classes.title }>
                    { title }
                    <IconButton color="default" onClick={ this.props.onClose } className={ classes.closeButton }>
                        <Icon>close_icon</Icon>
                    </IconButton>
                </DialogTitle>
                <DialogContent className={ classes.content }>
                    <DialogContext.Provider value={ {
                        onClose: this.props.onClose,
                        styles: {
                            actions: this.props.classes.actions,
                            alert: this.props.classes.alert,
                            backdrop: this.props.classes.backdrop,
                        },
                        buttonProps: this.props.buttonProps
                    } }>
                        { this.props.children }
                    {/*{*/}
                        {/*React.cloneElement(*/}
                            {/*React.Children.only(this.props.children),*/}
                            {/*this.getPropsToInject()*/}
                        {/*)*/}
                    {/*}*/}
                    </DialogContext.Provider>
                </DialogContent>
            </Dialog>
        )
    }
    //
    // getPropsToInject() {
    //     return {
    //         //onClose: this.props.onClose,
    //         onGetStyle: name => {
    //             if (this.props.classes.hasOwnProperty(name)) {
    //                 return this.props.classes[name];
    //             }
    //             return '';
    //         },
    //         // onGetActionsStyle: () => {
    //         //     return this.props.classes.actions;
    //         // },
    //         // onGetAlertStyle: () => {
    //         //     return this.props.classes.alert;
    //         // }
    //     };
    //
    // }
}

const styles = () => ({
    title: {
        paddingRight: 56,
    },
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
    actions: {
        padding: '16px 0',
        textAlign: 'right'
    },
    alert: {
        margin: '0 -24px',
    },
    content: {
        padding: '0 24px',
    },
    backdrop: {
        margin: '0 -24px',
        padding: '0 24px',
        '& .cBackdrop-backdrop': {
            margin: '0 -24px',
            width: 'calc(100% + 48px)',
        }
    }
});

FormDialog.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(FormDialog);
