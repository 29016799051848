import React from 'react';
import userManager, { EVENTS as USER_EVENTS} from "../../service/UserManager";
import Link from "react-router-dom/Link";
import PropTypes from 'prop-types';
import { Menu, MenuItem, Button, AppBar, Toolbar, Popover, Tooltip } from "@material-ui/core";
import Login from "../../components/Login";
import {routes} from "../../routes";
import {withRouter} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import config from '../../params';

class MainMenu extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            userMenuAnchorElement: null,
            loginMenuAnchorElement: null,
        };
        this.unlisten = null;
    }

    componentDidMount(){
        userManager.subscribe([USER_EVENTS.CURRENT_USER_CHANGED, USER_EVENTS.CURRENT_USER_UPDATED], this, () => this.forceUpdate());
        this.unlisten = this.props.history.listen(() => {
            this.forceUpdate();
        });
    }

    componentWillUnmount(){
        userManager.unsubscribe(USER_EVENTS.CURRENT_USER_CHANGED, USER_EVENTS.CURRENT_USER_UPDATED, this);
        this.unlisten();
    }

    handleProfileMenuOpen = event => {
        this.setState({
            userMenuAnchorElement: event.currentTarget,
        });
    };

    handleProfileMenuClose = () => {
        this.setState({
            userMenuAnchorElement: null
        });
    };

    handleProfileDialogOpen = () => {
        this.setState({
            userMenuAnchorElement: null,
        });
        userManager.editProfile();
    };

    handleLoginMenuOpen = event => {
        this.setState({
            loginMenuAnchorElement: event.currentTarget,
        });
    };

    handleLoginMenuClose = () => {
        this.setState({
            loginMenuAnchorElement: null,
        });
    };

    handleLogout = () => {
        this.setState({
            userMenuAnchorElement: null
        });
        userManager.logout();
    };

    getUserMenu() {
        if (userManager.isUnknownUser()) {
            return null;
        }

        if (userManager.isGuest()) {
            return <React.Fragment>
                <Button color="inherit" onClick={ this.handleLoginMenuOpen }>Войти</Button>
                <Popover
                    open={ this.state.loginMenuAnchorElement !== null }
                    anchorEl={ this.state.loginMenuAnchorElement }
                    onClose={ this.handleLoginMenuClose }
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableEnforceFocus
                >
                    <div className="popover-form" style={{width: 250}}>
                        <Login />
                    </div>
                </Popover>
            </React.Fragment>
        }

        const user = userManager.getUser();
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Button onClick={ this.handleProfileMenuOpen }>
                    <Tooltip title={ user.isEmailConfirmed ? 'Адрес электронной почты подтвержден' : 'Нет подтвержденного адреса электронной почты'}>
                        <span className={ classes.emailSign + ' ' + (user.isEmailConfirmed ? classes.confirmedContact : classes.unconfirmedContact) }>
                            <i className="fas fa-at" />
                        </span>
                    </Tooltip> { user.displayedName }
                </Button>
                <Menu
                    anchorEl={ this.state.userMenuAnchorElement }
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={ this.state.userMenuAnchorElement !== null}
                    onClose={ this.handleProfileMenuClose }
                >
                    <MenuItem onClick={ this.handleProfileDialogOpen }>Профиль</MenuItem>
                    {/*<MenuItem>Настройки</MenuItem>*/}
                    <MenuItem onClick={ this.handleLogout }>Выйти</MenuItem>
                </Menu>
            </React.Fragment>
        )
    }

    render(){
        if (userManager.isUnknownUser()) {
            return null;
        }

        return (
            <React.Fragment>
                <AppBar position="static" color="default">
                    <Toolbar style={{justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                            <div style={{ textTransform: 'uppercase', fontWeight: 500, paddingRight: 12 }}>
                                Seat booking
                            </div>
                            <MainMenuLink name="Афиша" link={ routes.upcomingEvents } />
                            <MainMenuLink name="Мои заказы" link={ routes.myOrders } />
                            {
                                userManager.isEmployee() &&
                                <MainMenuLink name="Панель управления" link={ config.apiEndpoint } />
                            }
                        </div>
                        {
                            this.getUserMenu()
                        }
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        );
    }
}

MainMenu.propTypes = {
    user: PropTypes.shape({
        role: PropTypes.string
    }),
};

const mainMenuStyles = theme => ({
    emailSign: {
        paddingRight: 4,
    },
    confirmedContact: {
        color: theme.palette.success.main,
    },
    unconfirmedContact: {
        color: theme.palette.error.main,
    }
});

export default withRouter(withStyles(mainMenuStyles)(MainMenu));


class BaseMainMenuLink extends React.Component
{
    render() {
        const { link, name, classes } = this.props;
        const enabled = link !== window.location.pathname;
        if (!enabled) {
            return <Button disabled><span className={ classes.disabled }>{ name }</span></Button>
        }
        if (link.match(/^https?:\/\//)) {
            return <Button><a href={ link }>{ name }</a></Button>
        }
        
        return (
            <Button><Link to={ link }>{ name }</Link></Button>
        );
    }
}

const menuLinkStyles = theme => ({
    disabled: {
        color: theme.palette.text.primary,
    }
});

const MainMenuLink = withStyles(menuLinkStyles)(BaseMainMenuLink);