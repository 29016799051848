import React from 'react';
import PropTypes from 'prop-types';
import DottedLink from "../DottedLink";
import Countdown from "./Countdown";
import userManager, { EVENTS as USER_EVENTS} from "../../service/UserManager";
import DateTime from "../DateTime";
import myOrdersManager from "../../service/MyOrdersManager";
import RobokassaPaymentForm from "../RobokassaPaymentForm";
import { Link } from "react-router-dom";
import { routes, reverse } from "../../routes";

class ItemReservationCountdown extends React.PureComponent
{
    constructor(props) {
        super(props);

        this.state = {
            payment: null,
        };

        this.isGettingPaymentForm = false;
    }

    componentDidMount(){
        userManager.subscribe(USER_EVENTS.CURRENT_USER_UPDATED, this, (currentUser, prevUser) => {
            if (currentUser.email !== prevUser.email || currentUser.isEmailConfirmed !== prevUser.isEmailConfirmed) {
                this.forceUpdate();
            }
        });
    }

    handleEmailConfirmationRequest = () => {
        userManager.confirmContacts(['email']);
    };

    handleProfileEditionRequest = () => {
        userManager.editProfile();
    };

    handlePay = () => {
        if (this.isGettingPaymentForm) {
            return;
        }
        this.isGettingPaymentForm = true;
        myOrdersManager.payOrder(this.props.order.id)
            .then(response => {
                this.isGettingPaymentForm = false;
                this.setState({
                    payment: response.data.payment,
                });
            })
            .catch(error => {
                this.isGettingPaymentForm = false;
                this.props.onError && this.props.onError(error.message);
            });
    };

    render() {
        const { order, onExpired } = this.props;
        const session = order.session;
        const user = userManager.getUser();
        const hasConfirmedContact = user.email && user.isEmailConfirmed;

        return (
            <div style={{padding: '8px 16px', backgroundColor: 'rgb(245, 245, 220)', marginBottom: 1}}>
                {
                    this.state.payment &&
                    <RobokassaPaymentForm payment={ this.state.payment } />
                }
                <div style={{position: 'relative', minHeight: 43}}>
                    <div style={{width: 200, position: 'absolute', top: 4}}>
                        <Countdown
                            time={ order.expiresIn }
                            onExpired={ onExpired }
                        />
                    </div>
                    <div style={{marginLeft: 200 }}>
                        <span>
                            до истечения брони мест ({order.items.length} шт.) на мероприятие {' '}
                            <Link to={ reverse(routes.session.index, {sessionId: session.id}) }>&laquo;{ session.event.name }&raquo;</Link><br />
                            <b>{ session.event.area.name }</b>, время начала мероприятия: <b><DateTime>{ session.startDate }</DateTime></b>.
                        </span>
                    </div>
                </div>
                <ul style={{ marginBottom: 0 }}>
                    <li>Чтобы приобрести электронные билеты, <DottedLink onClick={ this.handlePay }>оплатите заказ</DottedLink></li>
                    {
                        !hasConfirmedContact &&
                        <li>
                            Чтобы продлить бронирование на 3 дня,{' '}
                            <React.Fragment>
                                {
                                    !user.email &&
                                    <span><DottedLink onClick={ this.handleProfileEditionRequest }>укажите</DottedLink> и подтвердите адрес электронной почты</span>
                                }
                                {
                                    user.email && !user.isEmailConfirmed &&
                                    <DottedLink onClick={ this.handleEmailConfirmationRequest }>подтвердите адрес электронной почты</DottedLink>
                                }
                            </React.Fragment>

                        </li>
                    }
                </ul>
            </div>
        )
    }
}

ItemReservationCountdown.propTypes = {
    order: PropTypes.shape({
        session:   PropTypes.object,
        items:     PropTypes.array,
        expiresIn: PropTypes.number,
    }),
    onExpired: PropTypes.func,
    onError: PropTypes.func,
};

export default ItemReservationCountdown;
