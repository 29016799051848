import React from 'react';
import PropTypes from "prop-types";
//import './style.css';
import Grid from "@material-ui/core/Grid/Grid";
import Link from "react-router-dom/es/Link";
import {routes} from "../../routes";
import userManager, { EVENTS as USER_EVENTS } from "../../service/UserManager";
import Login from "../Login";

export default class Home extends React.PureComponent
{
    componentDidMount() {
        userManager.subscribe(USER_EVENTS.CURRENT_USER_CHANGED, this, () => this.forceUpdate());
    }

    componentWillUnmount() {
        userManager.unsubscribe(USER_EVENTS.CURRENT_USER_CHANGED, this);
    }

    render() {
        if (userManager.isUnknownUser()) {
            return null;
        }

        const user = userManager.getUser();

        return <div>
            <h1>Добро пожаловать!</h1>

            <p>Проект SeatBooking создан для того, чтобы посетители театров, кинотеатров, клубов и прочих
                культурно-развлекательных учреждений
                имели возможность бронировать билеты в зрительный зал, отслеживать количество свободных мест при
                бронировании, выбирать желаемые места на подробной
                схеме зрительного зала.</p>

            {
                userManager.isGuest() &&
                <div>
                    <Grid container>
                        <Grid sm={6} item>
                            <p>Если Вы владелец или администратор культурно-развлекательного учреждения, то у Вас
                                появилась прекрасная возможность порадовать своих гостей дополнительным сервисом,
                                повысить уровень их комфорта, сделать процесс бронирования мест удобным, наглядным и
                                притягательным. Мы предлагаем Вашим клиентам легкое бронирование понравившися мест в два
                                клика
                                вместо долгого и не всегда продуктивного общения с оператором по телефону.</p>
                            <p>Мы избавим ваших сотрудников от необходимости отвечать на одни и те же вопросы, а ваших
                                клиентов - от необходимости их задавать.</p>
                            <p>Объявляйте новые мероприятия, устанавливайте цены на билеты и сроки бронирования,
                                отслеживайте статистику - мы предлагаем Вам автоматизированное рабочее место для
                                реализации билетов на ваши мероприятия.</p>
                            <p>Выполните вход, если у Вас уже есть аккаунт, или свяжитесь с нами для подключения вашей
                                организации к нашему сервису.</p>
                        </Grid>
                        <Grid sm={6} item>
                            <p>Если Вы - поклонник культурного досуга, часто или не очень посещаете театры, ходите в
                                кино или на клубные вечеринки, то теперь Вам будет значительно проще
                                стать гостем ваших любимых мест отдыха.
                                <Link to={ routes.upcomingEvents }>Выберите</Link> то, которое планируете посетить в
                                ближайшее время, укажите событие, на которое Вы планируете попасть, и забронируйте
                                наиболее понравившиеся места.</p>
                            <p>Не осталось свободных мест на интересный фильм, концерт, выступление? - не
                                расстраивайтесь: оставьте заявку и Вы получите уведомление (на телефон или email - как
                                вам удобнее),
                                если места появятся.</p>
                            <p>Если Вы уже имеете гостевой аккаунт - выполните вход: так бронирование мест будет еще
                                более быстрым.</p>
                        </Grid>
                    </Grid>
                    <Login />
                </div>
            }
            {
                userManager.isAdmin() &&
                <div>
                    <p>{ user.displayedName }, рады приветствовать Вас на сайте нашего сервиса.</p>
                    <p>Вы управляете площадками:</p>
                    <ul>
                        {/*{% for area in app.user.areas %}*/}
                        {/*<li>{{area.name}}</li>*/}
                        {/*{% endfor %}*/}
                    </ul>
                    <p>Перейдите к <Link to={ routes.upcomingEvents }>списку мероприятий</Link>, чтобы объявить
                        новые события, посмотреть статистику посещений, пометить зрительные места как забронированные
                        или выкупленные.</p>
                </div>
            }
            {
                userManager.isSeller() &&
                <div>
                    <p>{ user.displayedName }, рады приветствовать Вас на сайте нашего сервиса.</p>
                    <p>Для бронирования и продажи билетов перейдите к <a href="">списку мероприятий</a></p>
                </div>
            }

            {
                userManager.isSpectator() &&
                <div>
                    <p>{ user.displayedName }, рады приветствовать Вас на сайте нашего сервиса. Приглашаем Вас
                        перейти к <Link to={ routes.upcomingEvents }>выбору объявленных событий</Link>, где Вы сможете
                        определиться с выбором места.</p>
                </div>
            }
        </div>
    }
}

