import BackendService from './BackendService';
import apiRoutes, { reverse } from "../api/routes";

export const EVENTS = {
    CURRENT_USER_CHANGED: 'current_user_changed',
    CURRENT_USER_UPDATED: 'current_user_updated',
    CONTACT_CONFIRMATION_REQUESTED: 'contact_confirmation_requested',
    CREATE_PROFILE_REQUEST: 'create_profile_request',
    EDIT_PROFILE_REQUEST: 'edit_profile_request',
    RESTORE_ACCESS_REQUEST: 'restore_access_request',
    MERGE_ACCOUNTS: 'merge_accounts',
};

export const CONTACTS_TO_CONFIRM = {
    EMAIL: true,
    PHONE: false,
};

class UserManager extends BackendService
{
    constructor(){
        super();
        this.currentUser = undefined;
    }

    getUser(){
        return this.currentUser;
    }

    setUser(user) {
        let isChanged = false;

        const prevUser = this.currentUser ? { ...this.currentUser } : this.currentUser;
        this.currentUser = user;

        if (prevUser && user) {
            isChanged = prevUser.id !== user.id;
        } else {
            isChanged = prevUser !== user;
        }
        if (isChanged) {
            this.dispatch(EVENTS.CURRENT_USER_CHANGED, user, prevUser);
            return;
        }

        if (user) {
            for (let property of Object.keys(user)) {
                if (prevUser[property] !== user[property]) {
                    this.dispatch(EVENTS.CURRENT_USER_UPDATED, user, prevUser);
                    return;
                }
            }
        }
    }

    requestUser(){
        if (this.currentUser !== undefined){
            return Promise.resolve(this.currentUser);
        }

        return this.hey();
    }

    hey(){
        return this
            .requestApi(reverse(apiRoutes.hey), 'GET')
            .then(response => {
                this.setUser(response.data.user);

                return response.data.user;
            });
    }

    isUnknownUser() {
        return this.currentUser === undefined;
    }

    isGuest() {
        return this.currentUser === null;
    }

    isAdmin() {
        return this.currentUser && this.currentUser.role === 'ROLE_ADMIN';
    }

    isSeller() {
        return this.currentUser && this.currentUser.role === 'ROLE_SELLER';
    }

    isSuperAdmin() {
        return this.currentUser && this.currentUser.role === 'ROLE_SUPERADMIN';
    }

    isSpectator() {
        return this.currentUser && this.currentUser.role === 'ROLE_SPECTATOR';
    }

    isAssociate() {
        return this.currentUser && this.currentUser.role === 'ROLE_ASSOCIATE';
    }

    isEmployee() {
        return this.currentUser && (this.isSeller() || this.isAdmin() || this.isAssociate());
    }

    // forgotPassword(email) {
    //     return this
    //         .requestApi(reverse(apiRoutes.forgotPassword, {email: email}), 'POST');
    // }



    logout(){
        if (!this.currentUser){
            return Promise.resolve();
        }

        return this
            .requestApi(reverse(apiRoutes.logout), 'POST')
            .then(() => {
                this.setUser(null);
            });
    }

    login(username, password){
        if (this.currentUser){
            return Promise.resolve(this.currentUser);
        }
        return this
            .requestApi(reverse(apiRoutes.login), 'POST', null, {login: username, password: password})
            .then(response => {
                this.setUser(response.data.user);

                return response.data.user;
            });
    }

    switchUser(login) {
        return this.hey({_switch_user: login});
    }

    switchBackUser() {
        return this.switchUser('_exit');
    }

    saveUser(user){
        const url = !user.id ? reverse(apiRoutes.users) : reverse(apiRoutes.user, { userId: user.id });
        const method = !user.id ? 'POST' : 'PUT';

        return this
            .requestApi(url, method, null, user)
            .then(response => {
                this.setUser(response.data.user);

                return response.data.user;
            })
    }

    restoreAccessRequest(contact) {
        const data = {
            contact
        };
        return this.requestApi(reverse(apiRoutes.backdoor), 'POST', null, data);
    }

    restoreAccessLogin(contact, code){
        const data = {
            contact,
            code,
        };
        return this.requestApi(reverse(apiRoutes.backdoor), 'POST', null, data)
            .then(response => {
                this.setUser(response.data.user);

                return response.data.user;
            })
    }

    requestConfirmationCode(type) {
        return this.requestApi(reverse(apiRoutes.contactConfirmation, {type}), 'POST');
    }

    checkConfirmationCode(type, token) {
        return this
            .requestApi(reverse(apiRoutes.contactConfirmation, {type}), 'POST', {token})
            .then(response => {
                //if (response.status === 'OK') {
                    this.setUser(response.data.user);
                //}

                return response;
            });
    }

    confirmContacts(types) {
        this.dispatch(EVENTS.CONTACT_CONFIRMATION_REQUESTED, types);
    }

    createProfile() {
        this.dispatch(EVENTS.CREATE_PROFILE_REQUEST);
    }

    editProfile(forced = false) {
        this.dispatch(EVENTS.EDIT_PROFILE_REQUEST, {forced});
    }

    static createUser(){
        return {
            id: null,
            surname: null,
            name: null,
            midname: null,
            gender: null,
            email: null,
            phone: null,
        }
    }

    requestTwins(){
        return this.requestApi(reverse(apiRoutes.twins), 'GET');
    }

    merge(userId, data){
        return this.requestApi(reverse(apiRoutes.union, {userId}), 'POST', null, data)
            .then(response => {
                this.dispatch(EVENTS.MERGE_ACCOUNTS);
                return response;
            })
    }
}

export default new UserManager();
