import React from 'react';
import {Grid, Icon, IconButton, Menu, MenuItem, withStyles} from "@material-ui/core";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {Button as BaseButton} from "@material-ui/core";
import EntryControlManager from "service/EntryControlManager";
import userManager, {EVENTS as USER_EVENTS} from "service/UserManager";
import { Alert } from '@material-ui/lab';
import ScanResult from "./ScanResult";
import { withSnackbar } from 'notistack';
import ControlSettingsForm from "./ControlSettingsForm";
import DottedLink from "../DottedLink";
import Login from "../Login";
import FormDialog from "../FormDialog/FormDialog";

const MODES = {
    READY: 1,
    SCAN: 2,
    CHECKING: 3,
    PASSING: 4,
};

const Button = withStyles({
    root: {
        margin: '8px 0',
        padding: '12px 16px',
        '& + $root': {
            marginTop: 0
        }
    }
})(BaseButton);

class EntryControl extends React.PureComponent
{
    constructor(props){
        super(props);

        this.state = {
            areaId: null,
            eventId: null,
            sessionId: null,
            mode: MODES.READY,
            message: null,
            code: null,
            result: null,
            isSettingsFormOpen: true,
            isLoginFormOpen: false,
            userMenuAnchorElement: null,
            useFlashlight: false,
            isCameraWarmedUp: false,
        }

        this.entryControlManager = new EntryControlManager();

        this.aaa = 'A';
    }

    componentDidMount(){
        userManager.subscribe(USER_EVENTS.CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount(){
        userManager.unsubscribeFromAllEvents(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.useFlashlight && !this.state.useFlashlight) {
            this.aaa = this.aaa + 'A';
            console.log('forceUpdate')
            this.forceUpdate();
        }
    }

    handleScan = () => {
        this.setState({
            mode: MODES.SCAN,
            message: null,
            result: null,
            code: null,
            isCameraWarmedUp: false,
        });
    };

    handleCancelScan = () => {
        this.setState({
            mode: MODES.READY,
        });
    };

    onUpdateScreen = (err, result) => {
        if (!result) {
            if (!this.state.isCameraWarmedUp) {
                this.setState({
                    isCameraWarmedUp: true,
                });
            }
            return;
        }

        this.setState({
            message: null,
            mode: MODES.CHECKING,
            result: null,
            code: result.text,
        });


        this.entryControlManager.check(result.text)
            .then((response) => {
                const result = response.data;
                const { areaId, eventId, sessionId } = this.state;
                this.additionalChecking(result, areaId, eventId, sessionId);
                const {orderItem, warnings, dangers} = result;
                this.setState({
                    mode: MODES.READY,
                    result: {
                        orderItem,
                        warnings,
                        dangers,
                    }
                });
            })
            .catch(error => {
                this.setState({
                    message: {
                        type: 'danger',
                        text: error.message
                    },
                    mode: MODES.READY,
                });
            })
    };

    additionalChecking(result, areaId, eventId, sessionId) {
        const { orderItem } = result;
        result.warnings = [];
        if (areaId && orderItem.order.eventSession.event.area.id !== areaId) {
            result.warnings.push('Концертная площадка не соответствует настройкам фильтра.');
        }
        if (eventId && orderItem.order.eventSession.event.id !== eventId) {
            result.warnings.push('Мероприятие не соответствует настройкам фильтра.');
        }
        if (sessionId && orderItem.order.eventSession.id !== sessionId) {
            result.warnings.push('Сеанс не соответствует настройкам фильтра.');
        }
    };

    // handleTest = () => {
    //     this.onUpdateScreen(null, {
    //         text: '142786k9f0c83942f1a5c43'
    //     });
    // };

    handlePass = () => {
        const { result, code } = this.state;
        if (!result || !result.orderItem) {
            return;
        }

        this.setState({
            message: null,
            mode: MODES.PASSING,
        });

        this.entryControlManager.pass(code)
            .then(() => {
                this.setState({
                    mode: MODES.READY,
                    message: null,
                    result: null,
                });
                this.props.enqueueSnackbar('Посетитель успешно зарегистрирован на мероприятии.', {variant: 'success', preventDuplicate: true});
            })
            .catch(error => {
                this.setState({
                    mode: MODES.READY,
                    message: {
                        type: 'danger',
                        text: error.message
                    },
                });
            })
    };

    handleChangeSettings = (areaId, eventId, sessionId) => {
        let { result } = this.state;
        if (result) {
            result = {...result};
            this.additionalChecking(result, areaId, eventId, sessionId);
        }

        this.setState({
            areaId,
            eventId,
            sessionId,
            result,
        });
    };

    toggleSettings = () => {
        this.setState(state => ({
            isSettingsFormOpen: !state.isSettingsFormOpen
        }));
    };

    handleToggleLoginForm = () => {
        this.setState(state => ({
            userMenuAnchorElement: null,
            isLoginFormOpen: !state.isLoginFormOpen
        }));
    };

    handleOpenProfileMenu = event => {
        this.setState({
            userMenuAnchorElement: event.currentTarget,
        });
    };

    handleCloseProfileMenu = () => {
        this.setState({
            userMenuAnchorElement: null,
        });
    };

    handleLogout = () => {
        this.setState({
            userMenuAnchorElement: null
        });
        userManager.logout();
    };

    toggleFlashlight = () => {
        this.setState(state => ({
            useFlashlight: !state.useFlashlight
        }));
    };

    render() {
        const user = userManager.getUser(); //{name: 'Vasya'};
        const { classes } = this.props;

        const { mode, message, result, areaId, eventId, sessionId, isSettingsFormOpen, isLoginFormOpen,
            useFlashlight, isCameraWarmedUp } = this.state;


        console.log('useFlashlight && isCameraWarmedUp', useFlashlight, isCameraWarmedUp, useFlashlight && isCameraWarmedUp);

        return (
            <>
                <Grid container>
                    <Grid item xs={ 6 }>
                        <h3 style={{ margin: 0, fontWeight: 'normal' }}>
                            <DottedLink onClick={ this.toggleSettings }>Параметры проверки</DottedLink>
                        </h3>
                    </Grid>
                    <Grid item xs={ 6 }>
                        <h3 style={{ margin: 0, fontWeight: 'normal', textAlign: 'right' }}>
                            {
                                user !== null ?
                                    <DottedLink onClick={ this.handleOpenProfileMenu }>{ user.name }</DottedLink>
                                    :
                                    <DottedLink onClick={ this.handleToggleLoginForm }>Войти</DottedLink>
                            }
                        </h3>
                    </Grid>
                </Grid>

                {
                    !user &&
                    <FormDialog
                        open={ isLoginFormOpen }
                        title="Вход в систему"
                        onClose={ this.handleToggleLoginForm }
                        maxWidth="xs"
                        buttonProps={{
                            variant: 'text'
                        }}
                    >
                        <Login hideHeader />
                    </FormDialog>
                }

                <Menu
                    open={ this.state.userMenuAnchorElement !== null }
                    anchorEl={ this.state.userMenuAnchorElement }
                    onClose={ this.handleCloseProfileMenu }
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableEnforceFocus
                >
                    <MenuItem onClick={ this.handleProfileDialogOpen }>Профиль</MenuItem>
                    <MenuItem onClick={ this.handleLogout }>Выйти</MenuItem>
                </Menu>


                <div className={ classes.switchableForm} style={ isSettingsFormOpen ? { height: 164 } : { height: 0 }}>
                    <ControlSettingsForm
                        areaId={ areaId }
                        eventId={ eventId }
                        sessionId={ sessionId }
                        onChangeSettings={ this.handleChangeSettings }
                    />
                </div>

                {/*<Button*/}
                {/*    color="secondary"*/}
                {/*    variant="contained" onClick={ this.handleTest }*/}
                {/*>*/}
                {/*    Test scan*/}
                {/*</Button>*/}
                {
                    mode === MODES.SCAN ?
                    <>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={ this.handleCancelScan }
                            fullWidth
                        >
                            Отменить сканирование
                        </Button>
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', zIndex: 1, left: 4, top: 4, borderRadius: 24, backgroundColor: '#fff' }}>
                            <IconButton onClick={ this.toggleFlashlight } color="primary" >
                                <Icon>{ useFlashlight ? 'flashlight_off' : 'flashlight_on' }</Icon>
                            </IconButton>
                            </div>
                            <BarcodeScannerComponent
                                key = { this.aaa}
                                onUpdate={ this.onUpdateScreen }
                                torch={ useFlashlight && isCameraWarmedUp ? true : undefined }
                            />
                        </div>
                    </>
                    :
                    <>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={ this.handleScan }
                            fullWidth
                            disabled={ mode !== MODES.READY }
                        >
                            Новое сканирование
                        </Button>
                        {
                            mode === MODES.CHECKING ?
                            <div>
                                Загрузка...
                            </div>
                            :
                            <>
                                {
                                    message !== null &&
                                    <Alert severity={ message.type === 'danger' ? 'error' : 'success' }>
                                        { message.text }
                                    </Alert>
                                }
                                {
                                    result &&
                                    <>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={ this.handlePass }
                                            fullWidth
                                            disabled={ result.dangers.length > 0 || mode === MODES.PASSING }
                                        >
                                            Пропустить на мероприятие
                                        </Button>
                                        <ScanResult result={ result } />
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </>
        )
    }
}

const s = () => ({
    switchableForm: {
        overflowY: 'hidden',
        transition: 'height 0.5s',
    },
    loginForm: {
        marginBottom: 16,
    }
});

export default withSnackbar(withStyles(s)(EntryControl));
