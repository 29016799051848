import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import userManager, { CONTACTS_TO_CONFIRM } from '../../service/UserManager';
import Switch from "@material-ui/core/Switch/Switch";
import Grid from "@material-ui/core/Grid/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import LoadingButton from "../LoadingButton";
import { FormActions, FormAlert, FormBackdrop } from "../FormDialog/Form";
import withStyles from "@material-ui/core/styles/withStyles";

class ProfileForm extends React.PureComponent
{
    constructor(props){
        super(props);

        const user = props.user; // || userManager.constructor.createUser();

        this.state = {
            user,
            loading: false,
            errors: {},
            password: null,
            passwordReentry: null,
            changePasswordMode: false,
            message: null,
        };

        this.confirmedContacts = {
            email: user.isEmailConfirmed ? user.email : null,
            phone: user.isPhoneConfirmed ? user.phone : null,
        };
    }

    handleSubmit = () => {
        const message = {
            text: 'Пожалуйста, исправьте ошибки ввода',
            type: 'danger'
        };
        const errors = {};
        const surname = this.state.user.surname;
        if (!surname || !surname.trim()) {
            errors['surname'] = 'Необходимо заполнить это поле';
        }
        if (!this.state.user.id || this.state.changePasswordMode) {
            if (!this.state.password) {
                errors['password'] = 'Пароль не должен быть пустым';
            } else if (this.state.password !== this.state.passwordReentry) {
                errors['passwordReentry'] = 'Пароли не совпадают';
            }
        }
        if (Object.keys(errors).length > 0) {
            this.setState({
                message,
                errors,
            });
            return;
        }

        let user;
        if (!this.state.user.id || this.state.changePasswordMode) {
            user = {...this.state.user};
            user.password = this.state.password;
        } else {
            user = this.state.user;
        }

        this.setState({
            loading: true,
            errors,
            message: null,
        });

        userManager
            .saveUser(user)
            .then(user => {
                this.setState({
                    loading: false,
                });
                this.props.onSave(user);
            })
            .catch(error => {
                const details = error.data ? (error.data.details || {}) : {};
                this.setState({
                    loading: false,
                    message: {
                        text: error.message,
                        type: 'danger'
                    },
                    errors: details,
                });
            })
    };

    handleInputChange = event => {
        const value = event.target.value || null;
        const name = event.target.name;

        this.setState(state => {
            const errors = {...state.errors};
            const user = {...this.state.user};

            user[name] = value;
            delete errors[name];

            return {
                errors,
                message: Object.keys(errors).length > 0 ? state.message : null,
                user,
            }
        });
    };

    handlePasswordChange = event => {
        const password = event.target.value || null;
        this.setState(state => {
            const errors = {...state.errors};
            delete errors['password'];
            return {
                errors,
                message: errors.size > 0 ? state.message : null,
                password,
            }
        });
    };

    handlePasswordReentryChange = event => {
        const passwordReentry = event.target.value;
        this.setState(state => {
            const errors = {...state.errors};
            delete errors['passwordReentry'];
            return {
                errors,
                message: errors.size > 0 ? state.message : null,
                passwordReentry,
            }
        });
    };

    handleTogglePassword = event => {
        this.setState({
            changePasswordMode: event.target.checked,
        });
    };

    hideMessages = () => {
        this.setState({
            message: null,
            errors: {},
        });
   };

    render(){
        const textFields = {
            surname: 'Фамилия',
            name:    'Имя',
            midname: 'Отчество',
            email:   'Адрес электронной почты',
            phone:   'Номер телефона',
        };

        const { user, errors, message } = this.state;
        const { classes } = this.props;

        return (
            <div>
                {
                    message !== null &&
                    <FormAlert type={ message.type } onClose={ this.hideMessages }>
                        { message.text }
                    </FormAlert>
                }
                <FormBackdrop loading={ this.state.loading }>
                {
                    ['surname', 'name', 'midname'].map(name => (
                        <TextField
                            key={ name }
                            label={ textFields[name] }
                            placeholder={ textFields[name] }
                            fullWidth
                            margin="dense"
                            name={ name }
                            value={ user[name] || '' }
                            helperText={ errors[name] || '' }
                            error={ errors.hasOwnProperty(name) }
                            InputProps={ {disableUnderline: false} }
                            onChange={ this.handleInputChange }
                        />
                    ))
                }

                <FormControl
                    fullWidth
                    margin="dense"
                    error={ errors.hasOwnProperty('gender') }
                >
                    <InputLabel shrink>Пол</InputLabel>
                    <Select
                        name="gender"
                        value={ user['gender'] || '' }
                        onChange={ this.handleInputChange }
                        displayEmpty
                    >
                        <MenuItem value="">Не указан</MenuItem>
                        <MenuItem value="M">Мужской</MenuItem>
                        <MenuItem value="F">Женский</MenuItem>
                    </Select>
                    {
                        errors.hasOwnProperty('gender') &&
                        <FormHelperText>{ errors['gender'] }</FormHelperText>
                    }
                </FormControl>

                {
                    ['email', 'phone'].map(name => {
                        const isConfirmed = CONTACTS_TO_CONFIRM[name.toUpperCase()] === false || this.confirmedContacts[name] === user[name];
                        const label = isConfirmed
                            ? <span>{ textFields[name] }</span>
                            : <span>{ textFields[name] } (<span className={ classes.unconfirmed }>не подтвержден</span>)</span>;
                        return <TextField
                            key={ name }
                            label={ label }
                            placeholder={ textFields[name] }
                            fullWidth
                            margin="dense"
                            name={ name }
                            value={ user[name] || '' }
                            helperText={ errors[name] || '' }
                            error={ errors.hasOwnProperty(name) }
                            InputProps={ {disableUnderline: false} }
                            onChange={ this.handleInputChange }
                        />
                    })
                }
                {
                    user.id > 0 &&
                    <div style={{marginTop: 16}}>
                        <FormControlLabel
                            margin="dense"
                            control={
                                <Switch
                                    checked={ this.state.changePasswordMode }
                                    onChange={ this.handleTogglePassword }
                                    color="primary"
                                />
                            }
                            label="Изменить пароль"
                        />
                    </div>
                }
                {
                    (!user.id || this.state.changePasswordMode) &&
                    <Grid container justify="space-between">
                        <Grid>
                            <TextField
                                label="Пароль"
                                placeholder="Пароль"
                                type="password"
                                fullWidth
                                margin="dense"
                                name="password"
                                value={ this.state.password || '' }
                                helperText={ errors['password'] || '' }
                                error={ errors.hasOwnProperty('password') }
                                InputProps={ {disableUnderline: false} }
                                onChange={ this.handlePasswordChange }
                                onBlur={ this.handleBlurPassword }
                            />
                        </Grid>
                        <Grid>
                            <TextField
                                label="Повторите пароль"
                                placeholder="Повторите пароль"
                                type="password"
                                fullWidth
                                margin="dense"
                                name="passwordReentry"
                                value={ this.state.passwordReentry || '' }
                                helperText={ errors['passwordReentry'] || '' }
                                error={ errors.hasOwnProperty('passwordReentry') }
                                InputProps={ {disableUnderline: false} }
                                onChange={ this.handlePasswordReentryChange }
                                onBlur={ this.handleBlurPasswordReentry }
                            />
                        </Grid>
                    </Grid>
                }
                </FormBackdrop>
                <FormActions>
                     <LoadingButton
                         color="primary"
                         name="submit"
                         onClick={ this.handleSubmit }
                         loading={ this.state.loading }
                     >
                         Сохранить
                     </LoadingButton>
                </FormActions>
            </div>
        )
    }
}

ProfileForm.propTypes = {
    user: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
};

const s = theme => ({
    unconfirmed: {
        color: theme.palette.error.main
    },
});

export default withStyles(s)(ProfileForm);
