import React from 'react';
import PropTypes from "prop-types";
import schemaManagerFactory from "../../service/SchemaManager";
import ordersManagerFactory, {EVENTS as ORDERS_MANAGER_EVENTS, SEAT_STATUS} from "../../service/OrdersManager";
import priceMapManagerFactory, {EVENTS as PRICE_MAP_MANAGER_EVENTS} from "../../service/PriceMapManager";
import SessionSeat from "./SessionSeat";
import {SchemaContext} from "../Schema/SchemaContext";

const REFRESH_INTERVAL = 60;

class SessionSeats extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.ordersManager = ordersManagerFactory.getManager(this.props.sessionId);
        this.priceMapManager = priceMapManagerFactory.getManager(this.props.sessionId);
        this.schemaManager = schemaManagerFactory.getManager(this.props.schemaId);

        this.refreshTimeout = null;

        this.renderedSeatsCount = 0;
    }

    componentDidMount() {
        if (this.renderedSeatsCount) {
            this.props.onRenderSeats && this.props.onRenderSeats();
        }
        this.ordersManager.subscribe(ORDERS_MANAGER_EVENTS.SESSION_STATE_REFRESH, this, () => {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = setTimeout(() => {
                this.ordersManager.refresh();
            }, REFRESH_INTERVAL * 1000);
            this.forceUpdate();
        });

        this.priceMapManager.subscribe(PRICE_MAP_MANAGER_EVENTS.PRICE_MAP_REFRESHED, this, () => {
            this.forceUpdate();
        });

        this.ordersManager.refresh();
        this.priceMapManager.refresh();
    }

    componentDidUpdate() {
        if (this.renderedSeatsCount) {
            this.props.onRenderSeats && this.props.onRenderSeats();
        }
    }

    componentWillUnmount() {
        this.ordersManager.unsubscribeFromAllEvents(this);
        this.priceMapManager.unsubscribeFromAllEvents(this);

        clearTimeout(this.refreshTimeout);
    }

    render(){
        const { selectedSeats } = this.props;
        const seats = this.schemaManager.getSchema().seats;
        if (this.renderedSeatsCount !== null){
            this.renderedSeatsCount = seats.length;
        }

        return (
            <div>
                {
                    seats.map(seat => {
                        const seatPriceRange = this.priceMapManager.getSeatPriceRange(seat.id);
                        const seatState = this.ordersManager.getSeatState(seat.id);

                        let status = SEAT_STATUS.unavailable;
                        if (seatState) {
                            status = Object.keys(seatState).find(status => seatState[status] > 0) || SEAT_STATUS.free;
                        }

                        if (selectedSeats.includes(seat.id)) {
                            status = SEAT_STATUS.cart;
                        }

                        return <SessionSeat
                            key={seat.id}
                            seat={seat}
                            onClick={ this.props.onClick }
                            color={ seatPriceRange ? seatPriceRange.color : null }
                            price={ seatPriceRange ? seatPriceRange.nominal : null }
                            status={ status }
                        />
                    })
                }
            </div>
        );
    }
}

SessionSeats.propTypes = {
    schemaId: PropTypes.number.isRequired,
    sessionId: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    onRenderSeats: PropTypes.func,
};

export default class extends React.PureComponent
{
    render() {
        return <SchemaContext.Consumer>
            {
                ({schemaId}) => <SessionSeats {...this.props} schemaId={schemaId}/>
            }
        </SchemaContext.Consumer>
    }
}
