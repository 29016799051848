import React from 'react';
import ordersManagerFactory, { EVENTS as ORDERS_MANAGER_EVENTS } from "../../service/OrdersManager";
import { Button } from "@material-ui/core";

const REFRESH_INTERVAL = 60;

class RefreshCountdown extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            x: REFRESH_INTERVAL,
        };
        this.ordersManager = ordersManagerFactory.getManager(this.props.sessionId);
        this.interval = null;
    }

    componentDidMount() {
        this.ordersManager.subscribe(ORDERS_MANAGER_EVENTS.SESSION_STATE_REFRESH, this, () => {
            clearInterval(this.interval);
            this.setState({
                loading: false,
                x: REFRESH_INTERVAL
            });
            this.interval = setInterval(() => {
                this.setState(state => ({
                    x: state.x - 1
                }));
            }, 1000);
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.ordersManager.unsubscribeFromAllEvents(this);
    }

    handleRefreshSessionSeats = () => {
        this.setState({
            loading: true
        });
        this.ordersManager.refresh();
    };

    render() {
        const { x, loading } = this.state;
        return (
            <Button onClick={ this.handleRefreshSessionSeats } disabled={ loading }>
                Автоматическое обновление мест через { x >= 0 ? x : 0 } сек.
            </Button>
        );
    }
}

export default RefreshCountdown;
