import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import TableFooter from "@material-ui/core/TableFooter";
import Button from "@material-ui/core/Button";
import BaseTableCell from '@material-ui/core/TableCell';
import withStyles from "@material-ui/core/styles/withStyles";
import backend from "../../api/BackendApi";
import apiRoutes, { reverse } from "../../api/routes";
import Badge from "@material-ui/core/Badge";
import myOrdersManager, { EVENTS as MY_ORDERS_EVENTS } from "../../service/MyOrdersManager";
import priceMapManagerFactory, { EVENTS as PRICE_MAP_MANAGER_EVENTS } from "../../service/PriceMapManager";
import { withSnackbar } from 'notistack';
import RobokassaPaymentForm from "../RobokassaPaymentForm";
import DottedLink from "../DottedLink";
import Currency from "../Currency";
import {Popover} from "@material-ui/core";
import SeatDiscountForm from "../Cart/SeatDiscountForm";

const tableCellStyles = {
    root: {
        padding: '2px 4px',
    }
};

const TableCell = withStyles(tableCellStyles)(BaseTableCell);

class OrderItems extends React.PureComponent
{
    constructor(props) {
        super(props);

        const forPayment = new Map();
        for (let item of this.getItems()) {
            if (item.status === 0) {
                forPayment.set(item.id, item.price - item.discount);
            }
        }

        this.priceMapManager = priceMapManagerFactory.getManager(this.props.session.id);

        this.state = {
            forPayment,
            loading: false,
            discountPopupAnchorEl: null,
        };
    }

    componentDidMount() {
        myOrdersManager.subscribe([MY_ORDERS_EVENTS.ORDER_ITEM_IS_CANCELLED, MY_ORDERS_EVENTS.ORDER_ITEM_DISCOUNT_IS_CHANGED], this, orderId => {
            if (orderId === this.props.orderId) {
                this.refreshForPayment();
            }
        });

        this.priceMapManager.subscribe(PRICE_MAP_MANAGER_EVENTS.PRICE_MAP_REFRESHED, this, () => {
            this.forceUpdate();
        });

        this.priceMapManager.refresh();
    }

    componentWillUnmount() {
        myOrdersManager.unsubscribeFromAllEvents(this);
    }

    refreshForPayment(){
        const forPayment = new Map();
        for (let item of this.getItems()) {
            if (item.status === 0 && this.state.forPayment.has(item.id)) {
                forPayment.set(item.id, item.price - item.discount);
            }
        }

        this.setState({
            forPayment,
        });
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.items !== this.props.items) {
    //         const prevItemsIds = new Set();
    //         for (let item of prevProps.items) {
    //             prevItemsIds.add(item.id);
    //         }
    //
    //         const forPayment = new Map();
    //         for (let item of this.props.items) {
    //             if (item.status === 0 && (!prevItemsIds.has(item.id) || this.state.forPayment.has(item.id))) {
    //                 forPayment.set(item.id, item.price);
    //             }
    //         }
    //
    //         this.setState({
    //             forPayment,
    //         });
    //     }
    // }

    handleChangeForPayment = (item, checked) => {
        const forPayment = new Map(this.state.forPayment);
        if (checked) {
            forPayment.set(item.id, item.price - item.discount);
        } else {
            forPayment.delete(item.id);
        }
        this.setState({
            forPayment,
        });
    };

    getPrintTicketUrl = (orderId, orderItemId) => {
        return backend.getApiUrl(reverse(apiRoutes.myOrders.order.items.item.ticket, {orderId, orderItemId}));
    };

    getPrintTicketsUrl = (orderId) => {
        return backend.getApiUrl(reverse(apiRoutes.myOrders.order.tickets, {orderId}));
    };

    getItems() {
        const order = myOrdersManager.getOrder(this.props.orderId);
        if (!order) {
            return [];
        }
        return order.items;
    }

    handlePay = () => {
        this.setState({
            loading: true,
        });
        myOrdersManager.payOrder(this.props.orderId, [ ...this.state.forPayment.keys() ])
            .then(response => {
                this.setState({
                    payment: response.data.payment,
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    loading: false,
                });
                this.props.enqueueSnackbar(error.message, {variant: 'error'});
            });
    };

    handleOpenDiscountDialog = event => {
        this.setState({
            discountPopupAnchorEl: event.currentTarget,
        });
    };

    handleCloseDiscountDialog = () => {
        this.setState({
            discountPopupAnchorEl: null,
        });
    };

    handleChangeDiscount = (seatId, value) => {
        const order = myOrdersManager.getOrder(this.props.orderId);
        for (let item of order.items) {
            if (item.seat.id === seatId) {
                myOrdersManager.saveOrderItemDiscount(order.id, item.id, value)
                    .then(() => {
                        this.forceUpdate();
                    });
            }
        }

    };

    render() {
        const { classes, onCancelReservation, orderId, session } = this.props;

        const order = myOrdersManager.getOrder(this.props.orderId);

        if (!order) {
            return null;
        }

        if (!this.priceMapManager.isReady()) {
            return null;
        }

        const items = order.items;

        let totalSum = 0;
        let payedSum = 0;

        let forPaymentSum = 0;
        for (let k of this.state.forPayment.keys()) {
            forPaymentSum += this.state.forPayment.get(k);
        }

        const discountSeatId = this.state.discountPopupAnchorEl !== null
            ? parseInt(this.state.discountPopupAnchorEl.dataset.seatId)
            : null;
        let discountItem = null;

        let paidTicketsCount = 0;
        let bookedTicketsCount = 0;
        for (let item of items) {
            if (item.status === 1) {
                paidTicketsCount++;
            }
            if (item.status === 0) {
                bookedTicketsCount++;
            }
            if (item.seat.id === discountSeatId) {
                discountItem = item;
            }
        }

        return (
            <div>
                {
                    this.state.payment &&
                    <RobokassaPaymentForm payment={ this.state.payment } />
                }
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ width: 38 }}>
                                №
                            </TableCell>
                            <TableCell align="left">
                                Место
                            </TableCell>
                            <TableCell align="right" style={{ width: 88 }}>
                                Цена, руб.
                            </TableCell>
                            <TableCell align="center" style={{ width: 88 }}>
                                К оплате
                            </TableCell>
                            <TableCell align="center" style={{ width: 64 }}>
                                <Tooltip title="Распечатать все оплаченные билеты в заказе">
                                    <span>
                                        <IconButton
                                            href={ this.getPrintTicketsUrl(orderId) }
                                            target="_blank"
                                            disabled={ paidTicketsCount === 0 }
                                        >
                                            <Badge badgeContent={ paidTicketsCount } color="default">
                                                <Icon>print</Icon>
                                            </Badge>
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="center" style={{ width: 58 }}>
                                <Tooltip title="Снять бронь со всех неоплаченных билетов">
                                    <span>
                                        <IconButton
                                            onClick={ () => onCancelReservation(orderId) }
                                            disabled={ bookedTicketsCount === 0 }
                                        >
                                            <Badge badgeContent={ bookedTicketsCount } color="default">
                                                <Icon>delete</Icon>
                                            </Badge>
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items.map((item, index) => {
                                totalSum += item.price - item.discount;
                                payedSum += item.status === 1 ? item.price : 0;
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={item.id}
                                        className={ item.status === 1 ? classes.paidItem : classes.bookedItem }
                                    >
                                        <TableCell align="center">
                                            { index + 1 }
                                        </TableCell>
                                        <TableCell align="left">
                                            { item.seat.name }
                                        </TableCell>
                                        <TableCell align="right">
                                            {
                                                (item.status === 0 && this.priceMapManager.getSeatDiscountRanges(item.seat.id).length > 0) ?
                                                    <>

                                                        <Tooltip title="Доступна скидка">
                                                            <DottedLink className={ classes.discountAsterisk } data-seat-id={ item.seat.id } onClick={ this.handleOpenDiscountDialog }>
                                                                <Currency>{ item.price - item.discount }</Currency>
                                                            </DottedLink>
                                                        </Tooltip>
                                                        {
                                                            item.discountMap !== null &&
                                                            <div className={ classes.oldPrice }><Currency>{ item.price }</Currency></div>
                                                        }
                                                    </>
                                                    :
                                                    <Currency>{ item.price - item.discount }</Currency>
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title={ item.status === 1 ? 'Билет уже оплачен' : '' }>
                                                <span>
                                                    <Switch
                                                        checked={ item.status !== 1 && this.state.forPayment.has(item.id) }
                                                        disabled={ item.status === 1 }
                                                        onChange={ event => this.handleChangeForPayment(item, event.target.checked) }
                                                        color="primary"
                                                        data-id={ item.id }
                                                    />
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title={ item.status === 1 ? 'Распечатать билет' : 'Нельзя распечатать билет т.к. он еще не оплачен' }>
                                                <span>
                                                    <IconButton
                                                        href={ this.getPrintTicketUrl(orderId, item.id) }
                                                        target="_blank"
                                                        disabled={ item.status !== 1 }
                                                    >
                                                        <Icon>print</Icon>
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title={ item.status === 0 ? 'Снять бронь' : 'Нельзя удалить билет т.к. он уже оплачен' }>
                                                <span>
                                                    <IconButton
                                                        onClick={ () => onCancelReservation(orderId, item.id) }
                                                        disabled={ item.status !== 0 }
                                                    >
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={ 2 } align="left">
                                Cтоимость заказа:
                            </TableCell>
                            <TableCell align="right">
                                { totalSum }
                            </TableCell>
                            <TableCell colSpan={ 3 }>

                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={ 2 } align="left">
                                Оплачено:
                            </TableCell>
                            <TableCell align="right">
                                { payedSum }
                            </TableCell>
                            <TableCell colSpan={ 3 }>
                            </TableCell>
                        </TableRow>
                        <TableRow className={ classes.lastRow }>
                            <TableCell colSpan={ 2 } align="left">
                                К оплате ({ this.getItemsLabel(this.state.forPayment.size) }):
                            </TableCell>
                            <TableCell align="right">
                                <span>{ forPaymentSum }</span>
                            </TableCell>
                            <TableCell style={{padding: '4px 0'}} align="center">
                                <Tooltip title={ this.getPaymentButtonHint(this.state.forPayment.size, forPaymentSum) }>
                                    <span>
                                        <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            disabled={ this.state.forPayment.size === 0 || this.state.loading || !session.settings.isOnlinePaymentEnabled }
                                            onClick={ this.handlePay }
                                        >
                                            {
                                                this.getPayButtonLabel()
                                            }
                                        </Button>
                                    </span>
                                </Tooltip>
                            </TableCell>
                            <TableCell colSpan={ 2 } align="left">
                                {
                                    this.state.forPayment.size > 0 &&
                                    <span className={ classes.robokassaAttributes }>
                                        <span>через</span> <img src="https://auth.robokassa.ru/Merchant/PaymentForm/Images/logo-m.png" alt="Robokassa" /><br />
                                    </span>
                                }
                                {
                                    session.settings.onlineSurcharge &&
                                    <span>с комиссией { session.settings.onlineSurcharge }{ session.settings.onlineSurchargeUnit === '%' ? '%' : ' руб.'}</span>
                                }
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>

                {
                    this.state.discountPopupAnchorEl !== null &&
                    <Popover
                        open
                        anchorEl={ this.state.discountPopupAnchorEl }
                        onClose={ this.handleCloseDiscountDialog }
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        elevation={ 12 }
                    >
                        <div className={ classes.discountFormContainer }>
                            <SeatDiscountForm
                                priceMapManager={ this.priceMapManager }
                                seatId={ discountItem.seat.id }
                                seatName={ discountItem.seat.name }
                                value={ discountItem.discountMap ? discountItem.discountMap.id : 0 }
                                onChange={ this.handleChangeDiscount }
                            />
                        </div>
                    </Popover>
                }
            </div>
        )
    }

    getPayButtonLabel() {
        return this.state.loading ? 'Оплата...' : 'Оплатить';
    }

    getPaymentButtonHint(count, sum) {
        if (!this.props.session.settings.isOnlinePaymentEnabled) {
            return 'Online-покупка билетов на данное мероприятие недоступна.';
        }
        if (count === 0) {
            return 'Установите флаг "К оплате" у тех билетов, которые Вы собираетесь оплатить.';
        }
        return 'Оплатить ' + this.getItemsLabel(count) + ' на сумму ' + sum + ' руб.';
    }

    getItemsLabel = (count) => {
        const d = count % 10;
        switch (d) {
            case 0:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
                return `${count} билетов`;
            case 1:
                return `${count} билет`;
            default:
                return `${count} билета`;
        }
    }
}

const orderItemsStyle = theme => ({
    discountAsterisk: {
        position: 'relative',
        '&:after': {
            content: '"*"',
            position: 'absolute',
            top: -5,
            fontSize: 'small',
        }
    },
    oldPrice: {
        textDecoration: 'line-through',
        color: '#aaa'
    },
    discountFormContainer: {
        padding: theme.spacing(2),
        width: 450,
    },
    paidItem: {
        backgroundColor: '#e3fbd2',
    },
    bookedItem: {
        backgroundColor: '#f7f4d7',
    },
    lastRow: {
        '& > td': {
            borderBottom: 'none',
        }
    },
    robokassaAttributes: {
        '& > span': {
            verticalAlign: 'middle'
        },
        '& > img': {
            verticalAlign: 'middle',
            height: 9,
            opacity: 0.5,
        }
    }
});

export default withStyles(orderItemsStyle)(withSnackbar(OrderItems));
