import React from 'react';
import PropTypes from 'prop-types';
import qs from "qs";
import {Redirect} from "react-router-dom";
import {logDebug} from "../../utils";

class RobokassaPaymentForm extends React.PureComponent
{
    constructor(props){
        super(props);
        this.form = React.createRef();
        this.comebackUrl = null;

        const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        if (queryParams['payment_comeback_id']) {
            logDebug('Найден параметр "payment_comeback_id" - выполняем перенаправление.');

            this.comebackUrl = this.constructor.popComebackUrl(queryParams['payment_comeback_id']);
            if (!this.comebackUrl) {
                logDebug('Нет сохраненного адреса возврата - используем текущий.');

                this.comebackUrl = window.location.pathname + window.location.search;
            }

            const status = queryParams['payment_status'];
            const message = queryParams['payment_message'];
            if (message) {
                if (this.comebackUrl.indexOf('?') === -1) {
                    this.comebackUrl += '?';
                } else {
                    this.comebackUrl += '&';
                }

                let messageParams = {
                    info_message: message
                };
                switch (status) {
                    case 'ok':
                        messageParams = {
                            success_message: message
                        };
                        break;
                    case 'error':
                        messageParams = {
                            error_message: message
                        };
                        break;
                }

                this.comebackUrl += qs.stringify(messageParams);
            }

            logDebug('Перенаправляем клиента на ' + this.comebackUrl);
        }
    }

    componentDidMount() {
        if (this.props.payment) {
            this.constructor.pushComebackUrl(this.props.payment['InvId']);
            this.form.current.submit();
        }
    }

    render() {
        if (this.comebackUrl) {
            return <Redirect to={ this.comebackUrl } />
        }
        if (!this.props.payment) {
            return null;
        }
        return (
            <form
                action="https://auth.robokassa.ru/Merchant/Index.aspx"
                method="POST"
                style={{display: 'none'}}
                ref={ this.form }
            >
                {
                    Object.keys(this.props.payment).map(key => (
                        <input type="hidden" name={ key } value={ encodeURIComponent(this.props.payment[key]) } />
                    ))
                }
            </form>
        )
    }

    static pushComebackUrl(key, url) {
        key = 'robokassa_comeback_url_' + key;
        if (!url) {
            url = window.location.pathname + window.location.search;
        }
        localStorage.setItem(key, url);
    }

    static popComebackUrl(key) {
        key = 'robokassa_comeback_url_' + key;
        return localStorage.getItem(key);
    }
}

RobokassaPaymentForm.propTypes = {
    payment: PropTypes.exact({
        MrchLogin: PropTypes.string,
        OutSum: PropTypes.number,
        InvId: PropTypes.number,
        InvDesc: PropTypes.string,
        Shp_items: PropTypes.string,
        Culture: PropTypes.string,
        IsTest: PropTypes.number,
        SignatureValue: PropTypes.string,
    })
};

export default RobokassaPaymentForm;
