import BackendService from './BackendService';
import apiRoutes, { reverse } from "../api/routes";

class PartnerManager extends BackendService
{
    constructor() {
        super();
        this.currentPartner = undefined;
    }

    getPartner() {
        return this.currentPartner;
    }

    requestPartner() {
        if (this.currentPartner !== undefined){
            return Promise.resolve(this.currentPartner);
        }

        return this
            .requestApi(reverse(apiRoutes.partner.index), 'GET')
            .then(response => {
                this.currentPartner = response.data.partner;

                return response.data.partner;
            });
    }

    isUnknownPartner() {
        return this.currentPartner === undefined;
    }
}

export default new PartnerManager();
