import React from 'react';
import Login from "../Login";
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import OrderingForm from "../OrderingForm";
import userManager, { EVENTS as USER_EVENTS } from "../../service/UserManager";
import './style.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export default class Ordering extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            tabIndex: 0,
            requestedUsername: null,
        };
    }

    componentDidMount(){
        userManager.subscribe(USER_EVENTS.CURRENT_USER_CHANGED, this, () => {
            this.forceUpdate()
        });
    }

    componentWillUnmount(){
        userManager.unsubscribeFromAllEvents(this);
    }

    handleChangeTab = (tabIndex) => {
        this.setState({
            tabIndex,
        })
    };

    render() {
        if (userManager.isUnknownUser()) {
            return null;
        }

        if (userManager.getUser()) {
            return (
                <div style={{padding: '0 16px'}}>
                    <OrderingForm
                        sessionId={ this.props.sessionId }
                    />
                </div>
            )
        }

        const { tabIndex } = this.state;

        return (
            <div className="c-ordering">
                <Tabs
                    value={ tabIndex }
                    onChange={ (event, index) => this.handleChangeTab(index) }
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Заказать из временной учетной записи" wrapped />
                    <Tab label="Войти в учетную запись" wrapped />
                </Tabs>

                <SwipeableViews
                    index={ tabIndex }
                    //onChangeIndex={ this.handleChangeTab }
                >
                    <TabPanel value={ tabIndex } index={0} style={{padding: '0 16px'}}>
                        <OrderingForm sessionId={ this.props.sessionId } />
                    </TabPanel>
                    <TabPanel value={ tabIndex } index={1} style={{padding: '0 16px'}}>
                        <Login
                            username={ this.state.requestedUsername }
                            hideHeader
                            hideRemember
                            hideRegister
                        />
                    </TabPanel>
                </SwipeableViews>
            </div>
        )
    }
}
