import BackendService from './BackendService';
import apiRoutes, {reverse} from "../api/routes";

export const EVENTS = {
    INVOICE_SAVED: 'INVOICE_SAVED',
};

class PushkinCardManager extends BackendService
{
    constructor(shopId) {
        super();
        this.shopId = shopId;
    }

    requestInvoices(sort, pageNum = 0, pageSize = 25) {
        const url = reverse(apiRoutes.pushkinCard.invoices, {shopId: this.shopId});

        const queryData = {
            pageNum,
            pageSize,
        };
        if (sort) {
            queryData.sort = sort;
        }

        return this.requestApi(url, 'GET', queryData)
            .then(response => {
                return response.data;
            });
    }

    requestInvoice(invoiceId) {
        const url = reverse(apiRoutes.pushkinCard.invoice, {shopId: this.shopId, invoiceId});

        return this.requestApi(url, 'GET')
            .then(response => {
                return response.data.invoice;
            });
    }

    saveInvoice(invoiceId, invoice, send = null) {
        const url = reverse(apiRoutes.pushkinCard.invoice, {shopId: this.shopId, invoiceId});

        return this.requestApi(url, 'PUT', send ? {send} : null, {invoice})
            .then(response => {
                this.dispatch(EVENTS.INVOICE_SAVED, response.invoice);
                return response.invoice;
            })
            .catch(error => {
                this.dispatch(EVENTS.INVOICE_SAVED);
                throw error;
            });
    }
}

class Factory
{
    managers = new Map();

    getManager(shopId){
        const id = shopId;
        if (this.managers.has(id)) {
            return this.managers.get(id);
        }
        const manager = new PushkinCardManager(shopId);
        this.managers.set(id, manager);

        return manager;
    }
}

export default new Factory();
