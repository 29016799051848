import React from 'react';
import DottedLink from "../DottedLink";
import { Alert, AlertTitle } from '@material-ui/lab';
import userManager, { EVENTS as USER_EVENTS } from "../../service/UserManager";
import withStyles from "@material-ui/core/styles/withStyles";

class NotificationOfAccountCreation extends React.PureComponent
{
    componentDidMount() {
        userManager.subscribe([USER_EVENTS.CURRENT_USER_CHANGED, USER_EVENTS.CURRENT_USER_UPDATED], this, () => {
            this.requestMoreProfileData();
            this.forceUpdate();
        });
        this.requestMoreProfileData();
    }

    componentWillUnmount() {
        userManager.unsubscribeFromAllEvents(this);
    }

    handleChangePasswordRequest = () => {
        userManager.editProfile();
    };

    requestMoreProfileData() {
        const user = userManager.getUser();
        if (!user || !user.password) {
            return;
        }
        if (user.surname && user.phone && user.email) {
            return;
        }
        userManager.editProfile(true);
    }

    render() {
        const user = userManager.getUser();
        if (!user || !user.password) {
            return null;
        }

        const { classes } = this.props;

        return (
            <Alert severity="info">
                <AlertTitle>Внимание! Для Вас была создана учетная запись:</AlertTitle>
                <span>
                    Имя пользователя - <span className={ classes.accessAttribute }>{ user.username }</span>,
                    пароль - <span className={ classes.accessAttribute }>{ user.password }</span>.
                </span><br />
                Запомните этот пароль или <DottedLink onClick={ this.handleChangePasswordRequest }>смените его</DottedLink> на любой другой,
                если Вы планируете в дальнейшем пользоваться данной учетной записью. После подтверждения номера телефона
                или email будет возможно использовать их в качестве имени пользователя.
            </Alert>
        )
    }
}

const styles = {
    accessAttribute: {
        fontWeight: 'bold',
        padding: '4px 12px',
        lineHeight: '2em',
        background: '#2196f3',
        color: '#fff',
        fontFamily: "Courier New",
    }
};


export default withStyles(styles)(NotificationOfAccountCreation);
