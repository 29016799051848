import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import BaseTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import myOrdersManager from "../../service/MyOrdersManager";
import DateTime from "../DateTime";
import withStyles from "@material-ui/core/styles/withStyles";
import OrderItems from "./OrderItems";
import { withSnackbar } from 'notistack';
import { routes, reverse } from "../../routes";
import {Link} from "react-router-dom";
import {EVENTS as USER_EVENTS} from "../../service/UserManager";
import userManager from "../../service/UserManager";

class MyOrders extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            page: 0,
        };
    }

    componentDidMount() {
        userManager.subscribe(USER_EVENTS.CURRENT_USER_CHANGED, this, () => {
            this.loadOrders();
        });
        // подписаться на событие удаления ордера

        this.loadOrders();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page || prevState.rowsPerPage !== this.state.rowsPerPage) {
            this.loadOrders();
        }
    }

    handleChangePage = (event, page) => {
        this.setState({
            page,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    loadOrders() {
        myOrdersManager.loadOrders(this.state.page, this.state.rowsPerPage)
            .then(() => {
                this.forceUpdate();
            })
    }

    handleCancelReservation = (orderId, orderItemId) => {
        myOrdersManager.declineOrderItems(orderId, orderItemId)
            .then(() => {
                const order = myOrdersManager.getOrder(orderId);
                if (!order) {
                    this.forceUpdate();
                    this.props.enqueueSnackbar('Заказ успешно удален.', {variant: 'success'});
                } else {
                    const message = orderItemId
                        ? 'Билет успешно удален.'
                        : 'Заказ успешно удален.';
                    this.props.enqueueSnackbar(message, {variant: 'success'});
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar(error.message, {variant: 'error'});
            })
    };

    render() {
        const orders = myOrdersManager.getOrders();
        if (orders === null) {
            return null;
        }

        const { rowsPerPage, page } = this.state;
        return (
            <div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    №
                                </TableCell>
                                <TableCell>
                                    Мероприятие
                                </TableCell>
                                <TableCell>
                                    Билеты
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Array.from(orders).map(([orderId, order], index) => {
                                    if (order.items.length === 0) {
                                        return null;
                                    }
                                    const session = myOrdersManager.getSession(order.sessionId, order.agentName);
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={order.id}
                                        >
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                { rowsPerPage * page + index + 1 }
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: 'top' }}>
                                                <div>
                                                    <Link to={ reverse(routes.session.index, {sessionId: order.sessionId}) }>
                                                        { session.event.name } ({ session.event.age }+)
                                                    </Link>
                                                </div>
                                                <div>
                                                    <small>{ session.event.area.name }, <DateTime>{ session.startDate }</DateTime></small>
                                                </div>

                                                <div><SessionStatus status={ session.status } /></div>
                                                <br />
                                                <div>Дата оформления заказа:<br /><DateTime>{ order.createdAt }</DateTime></div>
                                                <div>Бронь неоплаченных мест до:<br /><DateTime>{ order.expiresAt }</DateTime></div>
                                            </TableCell>
                                            <TableCell padding="none">
                                                <OrderItems
                                                    session={ session }
                                                    orderId={ order.id }
                                                    onCancelReservation={ this.handleCancelReservation }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={ [10, 25, 50] }
                    component="div"
                    count={ myOrdersManager.getOrdersCount() }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onChangePage={ this.handleChangePage }
                    onChangeRowsPerPage={ this.handleChangeRowsPerPage }
                />
            </div>
        );
    }
}

const tableCellStyles = {
    root: {
        padding: '16px 8px',
    }
};

const TableCell = withStyles(tableCellStyles)(BaseTableCell);

class SessionStatus extends React.PureComponent
{
    render() {
        const { status } = this.props;
        switch (status) {
            case 'e':
                return 'Мероприятие завершено';
            case 'c':
                return 'Мероприятие отменено';
            default:
                return null;
        }
    }
}

export default withSnackbar(MyOrders);
