class Dispatcher
{
    constructor(){
        this.listeners = new Map();
    }

    subscribe(eventType, tag, handler){
        let eventTypes = eventType;
        if (!Array.isArray(eventType)){
            eventTypes = [eventType];
        }
        for (eventType of eventTypes){
            if (!this.listeners.has(eventType)){
                this.listeners.set(eventType, new Map());
            }
            this.listeners.get(eventType).set(tag, handler);
        }
    }

    unsubscribe(eventType, tag){
        let eventTypes = eventType;
        if (!Array.isArray(eventType)){
            eventTypes = [eventType];
        }
        for (eventType of eventTypes){
            if (this.listeners.has(eventType) && this.listeners.get(eventType).has(tag)){
                this.listeners.get(eventType).delete(tag);
            }
        }
    }

    unsubscribeFromAllEvents(tag){
        for (let handlers of this.listeners.values()){
            handlers.delete(tag);
        }
    }

    dispatch(eventType, ...data){
        if (this.listeners.has(eventType)){
            this.listeners.get(eventType).forEach(handler => {
                handler(...data);
            });
        }
    }
}

export default new Dispatcher();
