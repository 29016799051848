import BackendService from './BackendService';
import apiRoutes, {reverse} from "../api/routes";

export const EVENTS = {
    ORDER_ITEM_IS_CANCELLED: 'ORDER_ITEM_IS_CANCELLED',
    ORDER_ITEM_DISCOUNT_IS_CHANGED: 'ORDER_ITEM_DISCOUNT_IS_CHANGED',
};

class MyOrdersManager extends BackendService
{
    constructor(){
        super();
        this.orders = null;
        this.sessions = null;
        this.ordersCount = 0;
    }

    getReservations(){
        return this.requestApi(reverse(apiRoutes.myOrders.reservations), 'GET');
    }

    loadOrders(page = 0, perPage = 10) {
        return this.requestApi(
            reverse(apiRoutes.myOrders.index),
            'GET',
            {page, perPage}
        )
        .then(response => {
            const sessions = new Map();
            for (let session of response.data.sessions) {
                sessions.set(session.id + '_' + session.settings.agentName, session)
            }
            this.sessions = sessions;

            this.orders = new Map();
            for (let order of response.data.orders) {
                this.orders.set(order.id, order);
            }

            this.ordersCount = response.data.ordersCount;
        })
    }

    payOrder(orderId, itemsIds = null) {
        return this.requestApi(
            reverse(apiRoutes.myOrders.order.payment, { orderId }),
            'GET',
            { items: itemsIds ? itemsIds.join(',') : null }
        )
    }

    getOrders() {
        return this.orders;
    }

    getOrdersCount() {
        return this.ordersCount;
    }

    getOrder(orderId) {
        return this.orders.get(orderId) || null;
    }

    getSession(sessionId, agentName) {
        return this.sessions.get(sessionId + '_' + agentName) || null;
    }

    saveOrderItemDiscount(orderId, orderItemId, discountId) {
        const url = reverse(apiRoutes.myOrders.order.items.item, {orderId, orderItemId});

        return this.requestApi(url, 'PUT', null, {discountMap: discountId === 0 ? null : {id: discountId}})
            .then(response => {
                const order = response.data.order;
                this.orders.set(order.id, order);
                this.dispatch(EVENTS.ORDER_ITEM_DISCOUNT_IS_CHANGED, orderId);
            });
    }

    declineOrderItems(orderId, orderItemId) {
        const url = orderItemId
            ? reverse(apiRoutes.myOrders.order.items.item, {orderId, orderItemId})
            : reverse(apiRoutes.myOrders.order.index, {orderId});

        return this.requestApi(url, 'DELETE')
            .then(response => {
                // if (response.status !== 'OK') {
                //     throw {
                //         message: response.message,
                //     }
                // }
                const order = response.data.order;
                if (order.items.length === 0) {
                    this.orders.delete(orderId);
                    this.ordersCount--;
                } else {
                    this.orders.set(orderId, order);
                }
                this.dispatch(EVENTS.ORDER_ITEM_IS_CANCELLED, orderId);
            });
    }
}

export default new MyOrdersManager();