import React from 'react';
import ordersManagerFactory, { EVENTS as ORDERS_MANAGER_EVENTS } from "service/OrdersManager";
import priceMapManagerFactory, { EVENTS as PRICE_MAP_MANAGER_EVENTS } from "service/PriceMapManager"

class SeatsLeft extends React.PureComponent
{
    constructor(props){
        super(props);
        this.ordersManager = ordersManagerFactory.getManager(this.props.sessionId);
        this.priceMapManager = priceMapManagerFactory.getManager(this.props.sessionId);
    }

    componentDidMount() {
        this.priceMapManager.subscribe(PRICE_MAP_MANAGER_EVENTS.PRICE_MAP_REFRESHED, this, () => {
            this.forceUpdate();
        });
        this.ordersManager.subscribe(ORDERS_MANAGER_EVENTS.SESSION_STATE_REFRESH, this, () => {
            this.forceUpdate();
        });

        let promise = Promise.resolve();
        if (!this.priceMapManager.isReady()) {
            promise = this.priceMapManager.requestPriceMaps();
        }
        promise
            .then(() => {
                this.forceUpdate();
            })
    }

    componentWillUnmount() {
        this.priceMapManager.unsubscribeFromAllEvents(this);
        this.ordersManager.unsubscribeFromAllEvents(this);
    }

    render() {
        let seatsTotal = 0;
        let seatsLeft = 0;

        const priceMap = this.priceMapManager.getPriceMap();
        if (priceMap) {
            for (const range of priceMap.ranges) {
                seatsTotal += range.count;
            }
            seatsLeft = seatsTotal - this.ordersManager.getOccupiedSeatsCount();
        }

        return (
            <span>Осталось мест: { seatsLeft }/{ seatsTotal }</span>
        )
    }
}

export default SeatsLeft;
