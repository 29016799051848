import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import priceMapManagerFactory, { EVENTS as PRICE_MAP_MANAGER_EVENTS } from "../../service/PriceMapManager";

class Chair extends React.PureComponent
{
    render(){
        return (
            <Grid
                container
                alignItems="center"
                spacing={ 1 }
            >
                <Grid item>
                    { this.props.children }
                </Grid>
                <Grid item>
                    <span>{ this.props.label }</span>
                </Grid>
            </Grid>
        )
    }
}

class Legend extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            prices: [],
        }
    }

    componentDidMount(){
        const priceMapManager = priceMapManagerFactory.getManager(this.props.sessionId);
        priceMapManager.subscribe(PRICE_MAP_MANAGER_EVENTS.PRICE_MAP_REFRESHED, this, ({prices}) => {
            this.setState({
                prices,
            });
        });
    }

    componentWillUnmount() {
        priceMapManagerFactory.getManager(this.props.sessionId).unsubscribeFromAllEvents(this);
    }

    render() {
        const { classes } = this.props;
        const { prices } = this.state;
        return (
            <div className={ classes.root }>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={ 2 }
                >
                    <Grid item>
                        <Chair label="Выбрано">
                            <div className={ 'chair ' + classes.selection } />
                        </Chair>
                    </Grid>
                    <Grid item>
                        <Chair label="Занято">
                            <div className={ 'chair ' + classes.occupied } />
                        </Chair>
                    </Grid>
                </Grid>

                <hr style={{margin: '10px 0', border: 'none', borderTop: '1px solid #ddd' }} />

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={ 2 }
                >
                    {
                        prices.map(({id, color, nominal}) => (
                            <Grid item key={ id }>
                                <Chair label={ nominal + ' руб.' }>
                                    <div className="chair" style={ {backgroundColor: color} } />
                                </Chair>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        );
    }
}

Legend.propTypes = {
    sessionId: PropTypes.number.isRequired,
};

const styles = {
    root: {
        '& .chair': {
            position: 'static',
            cursor: 'initial',
        }
    },
    occupied: {
        backgroundColor: '#ddd !important',
    },
    selection: {
        backgroundColor: 'yellowgreen !important',
    },
};

export default withStyles(styles)(Legend);
