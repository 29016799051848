import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import Select from "@material-ui/core/Select";
import pointsOfSaleManager from "../../service/PointsOfSaleManager";
import MenuItem from "@material-ui/core/MenuItem";
import ymaps from 'ymaps';
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import BaseExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import BaseExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DottedLink from '../DottedLink';
import withStyles from "@material-ui/core/styles/withStyles";
import params from '../../params';

class BasePhones extends React.PureComponent
{
    render() {
        const { phones, classes } = this.props;
        if (!phones) {
            return null
        }
        return (
            <div className={ classes.root }>
                <h5>Телефоны:</h5>
                <ul>
                    {
                        phones.map(phone => (
                            <li key={ phone }>{ phone }</li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

const Phones = withStyles({
    root: {
        '& h5': {
            margin: '8px 0',
        },
        '& ul': {
            listStyleType: 'none',
            padding: 0,
            margin: 0,
            '& > li': {
                marginLeft: 10,
                position: 'relative',
                '&::before': {
                    content: `"-"`,
                    position: 'absolute',
                    marginLeft: -10,
                }
            },
        }
    },
})(BasePhones);

class BaseSchedule extends React.PureComponent
{
    render() {
        const { schedule, classes } = this.props;
        if (!schedule) {
            return null
        }
        return (
            <div className={ classes.root }>
                <h5>Время работы:</h5>
                <table cellSpacing={ 0 } cellPadding={ 0 }>
                    <tbody>
                    {
                        schedule.map(item => (
                            <tr key={ item.days + ':' + item.time }>
                                <td>{ item.days }</td>
                                <td>{ item.time }</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

const Schedule = withStyles({
    root: {
        '& h5': {
            margin: '8px 0',
        },
        '& table': {
            borderCollapse: 'collapse',
        },
        '& table > tbody > tr > td': {
            border: '1px solid #ccc',
            padding: '2px 8px'
        }
    },
})(BaseSchedule);

const ExpansionPanelSummary = withStyles({
    expanded: {},
    content: {
        '&$expanded': {
            margin: '16px 0',
        },
    },
})(BaseExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
    root: {
        padding: '8px 24px',
    },
})(BaseExpansionPanelDetails);


export default class PointsOfSale extends React.PureComponent
{
    constructor(props){
        super(props);

        this.mapRef = React.createRef();

        this.state = {
            cityId: 0,
            maps: null,
        };

        this.map = null;
        this.markers = [];

        const apiKey = params.ymapKey;

        ymaps.load('https://api-maps.yandex.ru/2.1/?apikey=' + apiKey + '&lang=ru_RU')
            .then(maps => {
                this.setState({
                    maps,
                }, () => this.setupMap());
            });
    }

    componentDidMount() {
        pointsOfSaleManager.requestPointsOfSale()
            .then(() => {
                this.forceUpdate(() => this.setupMap())
            });
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.cityId !== this.props.cityId || prevState.cityId !== this.state.cityId) {
            this.setupMap();
        }
    }

    handleCityChanged = event => {
        const cityId = parseInt(event.target.value);
        this.setState({
            cityId,
        });
    };

    handlePointOfSaleClicked = event => {
        event.stopPropagation();
        const index = parseInt(event.currentTarget.dataset['index']);
        this.highlight(index);
    };

    getPointsOfSale = () => {
        if (this.state.cityId === 0) {
            return pointsOfSaleManager.getPoints();
        }

        return pointsOfSaleManager.getPoints().filter(pointOfSale => pointOfSale.cityId === this.state.cityId);
    };

    render() {
        if (pointsOfSaleManager.getCities() === null) {
            return null;
        }
        return (
            <Grid container>
                <Grid item sm={ 4 }>
                    <div>
                        <Select
                            onChange={ this.handleCityChanged }
                            value={ this.state.cityId }
                            fullWidth
                        >
                            <MenuItem value="0">Все города</MenuItem>
                            {
                                pointsOfSaleManager.getCities().map(city => (
                                    <MenuItem value={ city.id } key={ city.id }>{ city.name }</MenuItem>
                                ))
                            }
                        </Select>
                    </div>

                    <div style={{ padding: '12px 4px', backgroundColor: '#f5f5f5' }}>
                    <div style={{ padding: '4px 12px', overflowY: 'scroll', height: 500}}>
                        {
                            this.getPointsOfSale().map((pointOfSale, i) => {
                                const x = !!pointOfSale.phones || !!pointOfSale.schedule;
                                return <ExpansionPanel key={ pointOfSale.address }>
                                    <ExpansionPanelSummary
                                        expandIcon={ x ? <ExpandMoreIcon /> : null }
                                    >
                                        <div>
                                            <DottedLink data-index={ i } onClick={ this.handlePointOfSaleClicked }>{ pointOfSale.address }</DottedLink>
                                            {
                                                pointOfSale.description &&
                                                <div style={{fontSize: 'small'}}>
                                                    {pointOfSale.description}
                                                </div>
                                            }
                                        </div>
                                    </ExpansionPanelSummary>
                                    {
                                        x &&
                                        <ExpansionPanelDetails>
                                            <div>
                                                <Phones phones={pointOfSale.phones}/>
                                                <Schedule schedule={pointOfSale.schedule}/>
                                            </div>
                                        </ExpansionPanelDetails>
                                    }
                                </ExpansionPanel>
                            })
                        }
                    </div>
                    </div>
                </Grid>
                <Grid item sm={ 8 }>
                    <div ref={ this.mapRef } style={{ height: 524, marginTop: 32 }}>

                    </div>
                </Grid>
            </Grid>
        );
    }

    highlight(index){
        const marker = this.markers[index];
        this.map.setCenter(marker.geometry.getCoordinates(), 16);
        marker.balloon.open();
    }

    getFullDescription = (pointOfSales) => {
        return (
            <div>
                {
                    pointOfSales.description &&
                    <div className="pos-desc">{ pointOfSales.description }</div>
                }
                <Phones phones={ pointOfSales.phones } />
                <Schedule schedule={ pointOfSales.schedule } />
            </div>
        )
    };

    setupMap(){
        const { maps } = this.state;
        if (!maps){
            return;
        }
        if (pointsOfSaleManager.getCities() === null) {
            return;
        }

        const pointsOfSale = this.getPointsOfSale();

        const rect = this.getBoundsRect(pointsOfSale.map(pointOfSales => [pointOfSales.lat, pointOfSales.lng]));
        const zoom = this.getZoom(rect, this.mapRef.current.offsetWidth, this.mapRef.current.offsetHeight);
        const center = this.getCenter(rect);

        if (this.map === null){
            this.map = new maps.Map(this.mapRef.current, {center: center, zoom: zoom});
        } else {
            for (let marker of this.markers){
                this.map.geoObjects.remove(marker);
            }
            this.markers = [];
            this.map.setCenter(center, zoom);
        }

        let i = 0;
        for (let pointOfSales of pointsOfSale){
            const markerProps = {
                iconContent: ++i,
                hintContent: pointOfSales.address,
                balloonContentFooter: pointOfSales.address,
                balloonContentBody: '<div class="pos-balloon">' + ReactDOMServer.renderToStaticMarkup(this.getFullDescription(pointOfSales)) + '</div>',
            };

            const marker = new maps.Placemark(
                [pointOfSales.lat, pointOfSales.lng],
                markerProps,
                {
                    iconColor: 'red'
                }
            );
            this.map.geoObjects.add(marker);
            this.markers.push(marker);
        }
    }




    getBoundsRect = points => {
        let minLat = null;
        let maxLat = null;
        let minLng = null;
        let maxLng = null;
        for (let point of points){
            if (maxLat === null || point[0] > maxLat){
                maxLat = point[0];
            }
            if (minLat === null || point[0] < minLat){
                minLat = point[0];
            }
            if (maxLng === null || point[1] > maxLng){
                maxLng = point[1];
            }
            if (minLng === null || point[1] < minLng){
                minLng = point[1];
            }
        }
        return {
            minLat,
            maxLat,
            minLng,
            maxLng
        }
    };

    getCenter = rect => {
        return [
            (rect.minLat + rect.maxLat) / 2,
            (rect.minLng + rect.maxLng) / 2,
        ];
    };

    getZoom = (rect, w, h) => {
        const latZoom = rect.maxLat > rect.minLat ? Math.log2(180 * h / ((rect.maxLat - rect.minLat) * 256)) : null;
        const lngZoom = rect.maxLng > rect.minLng ? Math.log2(360 * w / ((rect.maxLng - rect.minLng) * 256)) : null;

        if (latZoom === null && lngZoom === null){
            return 16;
        }
        if (lngZoom === null) {
            return Math.floor(latZoom);
        }
        if (latZoom === null) {
            return Math.floor(lngZoom);
        }

        return Math.floor(Math.min(latZoom, lngZoom ));
    };
}
