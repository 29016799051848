import React from 'react';
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import './style.css';

export default class Backdrop extends React.Component
{
    render() {

        return <div className="cBackdrop" style={ this.props.style }>
            {
                this.props.loading &&
                <div>
                    <div className="cBackdrop-backdrop" />
                    <div className="cBackdrop-loading">
                        <CircularProgress size={24} />
                        {
                            this.props.task &&
                            <p>{ this.props.task }</p>
                        }
                    </div>
                </div>
            }
            { this.props.children }
        </div>
    }
}

Backdrop.propTypes = {
    loading: PropTypes.bool.isRequired,
    task: PropTypes.string,
};

Backdrop.defaultProps = {
    loading: false,
};
