import React from "react";

export const DialogContext = React.createContext({
    onClose: undefined,
    styles: {
        actions: '',
        alert: '',
        backdrop: '',
    },
    buttonProps: undefined,
});