import React from 'react';
import DottedLink from "../DottedLink";
import { Alert, AlertTitle } from '@material-ui/lab';
import userManager, { EVENTS as USER_EVENTS } from "../../service/UserManager";

class OfferToVerifyContactDetails extends React.PureComponent
{
    componentDidMount() {
        userManager.subscribe([USER_EVENTS.CURRENT_USER_CHANGED, USER_EVENTS.CURRENT_USER_UPDATED], this, () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        userManager.unsubscribeFromAllEvents(this);
    }

    handleEmailConfirmationRequest = () => {
        userManager.confirmContacts(['email']);
    };

    handleProfileEditionRequest = () => {
        userManager.editProfile();
    };

    render() {
        const user = userManager.getUser();
        if (!user) {
            return null;
        }
        if (user.role !== 'ROLE_SPECTATOR' && user.role !== 'ROLE_DEALER') {
            return null;
        }

        const hasConfirmedContact = (user.phone && user.isPhoneConfirmed) || (user.email && user.isEmailConfirmed);
        if (hasConfirmedContact) {
            return null;
        }

        return (
            <Alert severity="warning">
                {
                    !user.email &&
                    <AlertTitle>
                        Рекомендуем Вам <DottedLink onClick={ this.handleProfileEditionRequest }>указать</DottedLink> и подтвердить адрес электронной почты
                    </AlertTitle>
                }
                {
                    user.email && !user.isEmailConfirmed &&
                    <AlertTitle>
                        Рекомендуем Вам <DottedLink onClick={ this.handleEmailConfirmationRequest }>подтвердить адрес электронной почты</DottedLink>
                    </AlertTitle>
                }
                Это даст Вам возможность:
                <ul>
                    <li>бронировать билеты на длительное время;</li>
                    <li>восстановить доступ к учетной записи в случае, если Вы забудете пароль;</li>
                    <li>собирать в одной учетной записи все свои заказы и участвовать в бонусных программах;</li>
                </ul>
            </Alert>
        )
    }
}

export default OfferToVerifyContactDetails;
