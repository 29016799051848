import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";

const FORMAT_TZ = "YYYY-MM-DDTHH:mm:ssZZ";
const FORMAT_NO_TZ = "YYYY-MM-DDTHH:mm:ss";

class DateTime extends React.PureComponent
{
    constructor(props) {
        super(props);

        const [moment, hasTimezone] = this.getMoment(this.props.children);

        this.state = {
            moment,
        };
        this.hasTimezone = hasTimezone;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children) {
            const [moment, hasTimezone] = this.getMoment(this.props.children);
            this.hasTimezone = hasTimezone;
            this.setState({
                moment
            });
        }
    }

    getMoment = (value) => {
        for (let format of [FORMAT_TZ, FORMAT_NO_TZ]) {
            const m = moment(value, format, true);
            if (m.isValid()) {
                return [m, format === FORMAT_TZ];
            }
        }
        return [null, null];
    };

    render() {
        const { moment } = this.state;
        const { format } = this.props;
        if (!moment) {
            return null;
        }

        return moment.format(format);
    }
}

DateTime.propTypes = {
    format: PropTypes.string.isRequired,
};

DateTime.defaultProps = {
    format: 'DD.MM.YYYY HH:mm',
};

export default DateTime;
