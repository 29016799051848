import React from 'react';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import userManager from "../../service/UserManager";
import TextField from "@material-ui/core/TextField";
import { FormActions, FormAlert, FormBackdrop } from "../FormDialog/Form";

class ContactConfirmationForm extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            isSent: false,
            timeout: 0,
            loading: false,
            code: '',
            message: null,
            errors: new Map(),
        };
    }

    componentDidMount() {
        if (this.props.autorun) {
            this.handleSend();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.code.length !== 4 && this.state.code.length === 4) {
            this.handleCheck();
        }
    }

    getContact(){
        const user = userManager.getUser();
        switch (this.props.contactType) {
            case 'email':
                return user.email;
            case 'phone':
                return user.phone;
            default:
                return '';
        }
    }

    isContactConfirmed(){
        const user = userManager.getUser();
        switch (this.props.contactType) {
            case 'email':
                return user.isEmailConfirmed;
            case 'phone':
                return user.isPhoneConfirmed;
            default:
                return '';
        }
    }

    clearErrors = () => {
        this.setState({
            message: null,
            errors: new Map(),
        });
    };

    handleMessageClose = () => {
        this.clearErrors();
    };

    handleCodeChanged = event => {
        this.setState({
            message: null,
            errors: new Map(),
            code: event.target.value,
        });
    };

    handleSend = () => {
        this.setState({
            loading: true,
            message: null,
            errors: new Map(),
        });
        userManager.requestConfirmationCode(this.props.contactType)
            .then(() => {
                this.setState({
                    loading: false,
                    isSent: true,
                    timeout: 10,
                });
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    message: {
                        text: error.message,
                        type: 'danger',
                    },
                });
            });
    };

    handleCheck = () => {
        const { code } = this.state;
        const errors = new Map();
        if (!code) {
            errors.set('code', 'Код не может быть пустым');
            this.setState({
                errors,
                message: {
                    text: 'Пожалуйста, исправьте ошибки ввода',
                    type: 'danger',
                },
            });
            return;
        }

        this.setState({
            loading: true,
            message: null,
            errors: new Map(),
        });
        userManager.checkConfirmationCode(this.props.contactType, code)
            .then(response => {
                this.setState({
                    loading: false,
                });
                this.props.onConfirmed && this.props.onConfirmed(this.props.contactType, response.data.isMerged);
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    message: {
                        text: error.message,
                        type: 'danger'
                    },
                });
            });
    };

    handleExpired = () => {
        this.setState({
            timeout: 0,
        });
    };

    render() {
        const isContactConfirmed = this.isContactConfirmed();
        const { contactType } = this.props;

        return (
            <div>
                <FormBackdrop loading={ this.state.loading }>
                {
                    isContactConfirmed ?
                    <FormAlert type="info">
                        {
                            contactType === 'email'
                                ? 'Адрес электронной почты ' + this.getContact() + ' подтвержден.'
                                : 'Номер телефона ' + this.getContact() + ' подтвержден.'
                        }

                    </FormAlert>
                    :
                    <div>
                        {
                            !this.state.loading && this.state.isSent &&
                            <FormAlert type="success">
                                Сообщение с кодом подтверждения было успешно отправлено на { contactType === 'email' ? 'почтовый ящик' : 'телефон'}{' '}
                                {this.getContact()}. Если сообщение потеряно - Вы можете запросить его повторно.
                            </FormAlert>
                        }
                        {/*{*/}
                            {/*!this.state.isSent &&*/}
                            {/*<div>*/}
                                {/*Чтобы подтвердить { contactType === 'email' ? 'адрес электронной почты' : 'номер телефона'} <b>{this.getContact()}</b>,*/}
                                {/*нажмите кнопку "Выслать код", а затем введите в поле ниже код, содержащийся в полученном сообщении.*/}
                            {/*</div>*/}
                        {/*}*/}
                        {
                            this.state.message !== null &&
                            <FormAlert
                                type={ this.state.message.type }
                                onClose={ this.handleMessageClose }
                            >
                                { this.state.message.text }
                            </FormAlert>
                        }

                        <TextField
                            label="Введите полученный код"
                            fullWidth
                            margin="dense"
                            value={ this.state.code }
                            helperText={ this.state.errors.get('code') || '' }
                            error={ this.state.errors.has('code') }
                            InputProps={ {disableUnderline: false} }
                            onChange={ this.handleCodeChanged }
                            disabled={ !this.state.isSent }
                        />
                    </div>
                }
                </FormBackdrop>

                <FormActions>
                    <Button
                        color="primary"
                        disabled={ isContactConfirmed || this.state.loading || this.state.timeout > 0 }
                        onClick={ this.handleSend }
                    >
                        {
                            this.state.isSent ?
                            <span>
                                Выслать код повторно
                                {
                                    this.state.timeout > 0 &&
                                    <span> (через <Countdown timeout={ this.state.timeout } onExpired={ this.handleExpired } /> сек.)</span>
                                }
                            </span>
                            :
                            <span>Выслать код</span>
                        }
                    </Button>
                    <Button
                        color="primary"
                        disabled={ isContactConfirmed || this.state.loading || !this.state.isSent }
                        onClick={ this.handleCheck }
                    >
                        Проверить код
                    </Button>
                    {
                        this.props.onClose &&
                        <Button
                            color="default"
                            onClick={ this.props.onClose }
                        >
                            Отмена
                        </Button>
                    }
                </FormActions>
            </div>
        )
    }
}

ContactConfirmationForm.propTypes = {
    contactType: PropTypes.oneOf(['email', 'phone']).isRequired,
    onConfirmed: PropTypes.func,
    autorun: PropTypes.bool,
};

ContactConfirmationForm.defaultProps = {
    autorun: true,
};

// const s = {
//     xxx: {
//         width: 100
//     }
// };

export default ContactConfirmationForm; //withStyles(s)(ContactConfirmation);

class Countdown extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            timeLeft: this.props.timeout,
        };
        if (this.props.timeout > 0) {
            this.interval = setInterval(this.decrementTimeLeft, 1000);
        }
    }

    componentDidUpdate(prevProps, prevState){
        if (this.state.timeLeft < prevState.timeLeft && this.state.timeLeft <= 0) {
            clearInterval(this.interval);
            this.props.onExpired && this.props.onExpired();
            return;
        }
        if (this.props.timeout !== prevProps.timeout) {
            if (!this.interval) {
                this.interval = setInterval(this.decrementTimeLeft, 1000);
            }
            this.setState({
                timeLeft: this.props.timeout,
            });
        }
    }

    decrementTimeLeft = () => {
        this.setState(state => {
            return {
                timeLeft: state.timeLeft - 1,
            }
        });
    };

    render(){
        return <span>{ this.state.timeLeft }</span>
    }
}

