import BackendService from './BackendService';
import apiRoutes, {reverse} from "../api/routes";

class SessionManager extends BackendService
{
    constructor(){
        super();
        this.sessions = new Map();
    }

    requestSession(sessionId) {
        if (this.sessions.has(sessionId)) {
            return Promise.resolve(this.sessions.get(sessionId));
        }
        return this.requestApi(reverse(apiRoutes.session.index, {sessionId}), 'GET')
            .then(response => {
                const { session } = response;
                this.sessions.set(sessionId, session);

                return session;
            });
    }

    getSession(sessionId) {
        return this.sessions.get(sessionId) || null;
    }
}

export default new SessionManager();
