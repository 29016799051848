import BackendService from './BackendService';
import apiRoutes, {reverse} from "../api/routes";

export const EVENTS = {
   SCHEMA_IS_LOADED: 'SCHEMA_IS_LOADED',
};

class SchemaManager extends BackendService
{
    constructor(schemaId) {
        super();
        this.schemaId = schemaId;
        this.schema = null;
        this.seats = new Map();
        this.standingSpaces = new Map();
    }

    requestSchema() {
        return this.requestApi(reverse(apiRoutes.schema.index, {schemaId: this.schemaId}), 'GET')
            .then(response => {
                this.schema = response.schema;
                this.seats = new Map();
                this.standingSpaces = new Map();

                const seats = [];
                for (let category of this.schema.categories) {
                    if (category.isStandingSpace){
                        this.standingSpaces.set(parseInt(category.id), category);
                    }
                    for (let seat of category.seats) {
                        this.seats.set(parseInt(seat.id), seat);
                        if (!category.isStandingSpace){
                            seats.push(seat);
                        }
                    }
                }
                this.schema.seats = seats;

                this.dispatch(EVENTS.SCHEMA_IS_LOADED);

                return response;
            });
    }

    getSchema() {
        return this.schema;
    }

    getSeat(seatId) {
        return this.seats.get(seatId) || null;
    }

    getStandingSpace(standingSpaceId) {
        return this.standingSpaces.get(standingSpaceId) || null;
    }

    getStandingSpaces() {
        return Array.from(this.standingSpaces.values());
    }

    getStandingSpaceBySeatId(seatId) {
        for (let standingSpace of this.standingSpaces.values()) {
            if (standingSpace.seats[0].id === seatId) {
                return standingSpace;
            }
        }
        return null;
    }
}

class Factory
{
    managers = new Map();

    getManager(schemaId){
        schemaId = parseInt(schemaId);
        if (this.managers.has(schemaId)) {
            return this.managers.get(schemaId);
        }
        const manager = new SchemaManager(schemaId);
        this.managers.set(schemaId, manager);

        return manager;
    }
}

export default new Factory();
