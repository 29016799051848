import params from '../params';
import { include, reverse } from 'named-urls';

const getSubdomain = () => {
    const regexp = new RegExp('^(?:(.*)\\.)?' + params.domain.replace('.', '\\.') + '$', 'i');
    const m = window.location.hostname.match(regexp);
    if (!m) {
        return 'koncert-kassa';
        //throw new Error('Wrong domain name.');
    }
    return m[1] || 'self';
};

const compile = (pattern, params) => {
    if (typeof pattern === 'object'){
        pattern = pattern.index;
    }
    const subdomain = getSubdomain();
    if (subdomain) {
        if (!params) {
            params = {};
        }
        if (pattern.search(/:agentName/) !== -1) {
            params['agentName'] = subdomain;
        }
    }

    let result = reverse(pattern, params);
    //if (result.substr(-1) === '/'){
    //    return '/api' + result.slice(0, -1);
    //}
    return result;
};

export { compile as reverse };

export default {
    hey: '/hey',
    contactConfirmation: '/confirmation/:type',
    login:    '/login',
    logout:   '/logout',

    union:   '/union/:userId',
    twins:   '/twins',

    board: '/board.php',

    myOrders: include('/my_orders', {
        index: '',
        order: include(':orderId', {
            index: '',
            items: include('items', {
                index: '',
                item: include(':orderItemId', {
                    index: '',
                    ticket: 'ticket'
                })
            }),
            payment: 'payment',
            tickets: 'tickets',
        }),
        reservations: 'reservations'
    }),

    backdoor:   '/backdoor',
    users:    '/users',
    user:     '/users/:userId',

    session: include('/session/:sessionId', {
        index: 'info/:agentName?',
        seats: 'seats/:agentName?',
        prices: 'prices/:agentName?',
        order: 'order/:agentName?',
    }),
    upcomingEvents: '/upcoming_events/:agentName?',
    pointsOfSale: '/points_of_sales/:agentName?',

    schema: include('/schema/:schemaId', {
        index: '',
    }),

    partner: include('/partner/:agentName', {
        index: '',
    }),

    pushkinCard: include('/pushkin_card/:agentName/:shopId', {
        invoices: 'invoice',
        invoice: 'invoice/:invoiceId',
    }),

    control: include('/control', {
        check: 'check',
        pass: 'pass',
        areas: 'areas',
        events: 'events/:areaId',
    }),
}
