import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import FormDialog from "../FormDialog/FormDialog";
import {Button} from "@material-ui/core";
import SearchInvoiceForm from "./SearchInvoiceForm";
import RRNForm from "./RRNForm";
import InvoicesTable from "./InvoicesTable";
import PushkinCardManagerFactory from "../../service/PushkinCardManager";

class PushkinCardDashboard extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            isSearchInvoiceDialogOpen: false,
            invoice: null,
        }

        this.pushkinCardManager = PushkinCardManagerFactory.getManager(this.props.shopId);
    }

    handleSearchInvoiceDialogOpen = () => {
        this.setState({
            isSearchInvoiceDialogOpen: true,
        });
    }

    handleSearchInvoiceDialogClose = () => {
        this.setState({
            isSearchInvoiceDialogOpen: false,
        });
    }

    handleInvoiceDialogClose = () => {
        this.setState({
            invoice: null,
        });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
            e.preventDefault();
            this.setState({
                isSearchInvoiceDialogOpen: true,
            });
        }
    };

    handleSave = () => {
        this.setState({
            invoice: null,
        });
    };

    handleLoadInvoice = (invoice) => {
        this.setState({
            invoice,
            isSearchInvoiceDialogOpen: false,
        });
    };

    handleEditInvoice = (invoice) => {
        this.setState({
            invoice
        });
    };

    render() {
        const { invoice } = this.state;
        return (
            <div onKeyDown={ this.handleKeyDown }>
                <Button onClick={ this.handleSearchInvoiceDialogOpen }>Поиск платежа</Button>

                <InvoicesTable
                    pushkinCardManager={ this.pushkinCardManager }
                    onEditInvoice={ this.handleEditInvoice }
                />

                {
                    invoice !== null &&
                    <FormDialog
                        open
                        title="Ввод RRN"
                        onClose={ this.handleInvoiceDialogClose }
                        maxWidth="xs"
                        fullWidth
                    >
                        <RRNForm
                            pushkinCardManager={ this.pushkinCardManager }
                            invoice={ invoice }
                            onSave={ this.handleSave }
                        />
                    </FormDialog>
                }

                <FormDialog
                    open={ this.state.isSearchInvoiceDialogOpen }
                    title="Поиск счета"
                    onClose={ this.handleSearchInvoiceDialogClose }
                    maxWidth="xs"
                    fullWidth
                >
                    <SearchInvoiceForm
                        onLoadInvoice={ this.handleLoadInvoice }
                        pushkinCardManager={ this.pushkinCardManager }
                    />
                </FormDialog>
            </div>

        )
    }
}

const styles = {

};

export default withStyles(styles)(PushkinCardDashboard);
