import _ from "lodash";

const params = {
    domain: '',
    apiEndpoint: '',
    backendUrl: '',
    ymapKey: '',
};

_.merge(params, window.params);

export default params;
