import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {Tooltip, Icon, Table, TableBody, TableContainer, TableHead, TableFooter, Popover} from "@material-ui/core";
import { TableCell as BaseTableCell, TableRow as BaseTableRow, IconButton as BaseIconButton } from '@material-ui/core';
import { EVENTS as CART_EVENTS } from "../../service/CartManager";
import { EVENTS as ORDERS_MANAGER_EVENTS } from "../../service/OrdersManager";
import { EVENTS as PRICE_MAP_MANAGER_EVENTS } from "../../service/PriceMapManager";
import { EVENTS as SCHEMA_EVENTS } from "../../service/SchemaManager";
import SeatDiscountForm from "./SeatDiscountForm";
import Currency from "../Currency";
import classNames from 'classnames';
import DottedLink from "../DottedLink";

const TableCell = withStyles({
    root: {
        padding: '2px 4px',
        lineHeight: '1.25em',
    },
    head: {
        height: 40,
    },
    footer: {
        height: 40,
        color: 'inherit',
        lineHeight: 'inherit',
        fontSize: 'inherit',
    }
})(BaseTableCell);

const IconButton = withStyles({
    root: {
        padding: 6
    }
})(BaseIconButton);


const s = {
    root: {
        '& > td:first-child, & > th:first-child': {
            paddingLeft: 16
        },
        '& > td:last-child, & > th:last-child': {
            paddingRight: 16
        }

    }
};

const TableRow = withStyles(s)(BaseTableRow);

class Cart extends React.PureComponent
{
    constructor(props) {
        super(props);
        this.state = {
            perPage: 10,
            page: 0,
            discountPopupAnchorEl: null,
        };
    }

    componentDidMount(){
        this.props.cartManager.subscribe(
            [
                CART_EVENTS.CART_IS_RELEASED,
                CART_EVENTS.CART_ITEMS_DELETED,
                CART_EVENTS.CART_ITEMS_ADDED,
                CART_EVENTS.CART_ITEM_DISCOUNT_CHANGED
            ],
            this,
            () => this.forceUpdate()
        );

        this.props.ordersManager.subscribe(
            [
                ORDERS_MANAGER_EVENTS.SESSION_STATE_REFRESH,
            ],
            this,
            () => this.forceUpdate()
        );

        this.props.priceMapManager.subscribe(
            [
                PRICE_MAP_MANAGER_EVENTS.PRICE_MAP_REFRESHED,
            ],
            this,
            () => this.forceUpdate()
        );

        this.props.schemaManager.subscribe(
            [
                SCHEMA_EVENTS.SCHEMA_IS_LOADED
            ],
            this,
            () => this.forceUpdate()
        );

        this.props.cartManager.subscribe(CART_EVENTS.CART_ITEM_DISCOUNT_CHANGED, this, () => this.forceUpdate());
    }

    handleChangePage = (event, page) => {
        this.setState({
            page,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            perPage: parseInt(event.target.value),
            page: 0,
        });
    };

    componentWillUnmount(){
        this.props.cartManager.unsubscribeFromAllEvents(this);
        this.props.ordersManager.unsubscribeFromAllEvents(this);
        this.props.schemaManager.unsubscribeFromAllEvents(this);
    }

    handleDeleteItem = itemId => {
        this.props.cartManager.deleteItems([itemId]);
    };

    handleOpenDiscountDialog = event => {
        //const seatId = parseInt(event.currentTarget.dataset.seatId);
        this.setState({
            discountPopupAnchorEl: event.currentTarget,
        });
    };

    handleCloseDiscountDialog = () => {
        this.setState({
            discountPopupAnchorEl: null,
        });
    };

    handleChange = (seatId, value) => {
        const discountItemId = parseInt(this.state.discountPopupAnchorEl.dataset.itemId);

        this.props.cartManager.setDiscountForItem(discountItemId, value);
    };

    render() {
        const { classes, priceMapManager, cartManager } = this.props;
        const { discountPopupAnchorEl } = this.state;
        if (!this.props.priceMapManager.isReady()) {
            return null;
        }
        if (this.props.ordersManager.getSeats() === null) {
            return null;
        }
        if (this.props.schemaManager.getSchema() === null) {
            return null;
        }

        const cartItems = cartManager.getItems();

        let discountItemId = null;
        let discountItem = null;

        if (discountPopupAnchorEl !== null) {
            discountItemId = parseInt(discountPopupAnchorEl.dataset.itemId);
        }

        let areDiscountsAvailable = false;
        let totalPrice = 0;
        const items = [];

        for (let cartItem of cartItems) {
            const { id, seatId, discountId } = cartItem;

            const priceRange = priceMapManager.getSeatPriceRange(seatId);
            const price = priceRange.nominal;
            const discountRanges = priceMapManager.getSeatDiscountRanges(seatId);
            const name = this.props.schemaManager.getSeat(seatId).name;

            if (id === discountItemId){
                discountItem = cartItem;
            }

            let priceWithDiscount = price;
            if (discountId !== 0) {
                for (let discountRange of discountRanges) {
                    const discountMap = priceMapManager.getMapOfRange(discountRange.id);
                    if (discountMap.id === discountId) {
                        priceWithDiscount = discountRange.unit === '%'
                            ? price * (100 - discountRange.nominal)/100
                            : price - discountRange.nominal;
                        break;
                    }
                }
            }

            totalPrice += priceWithDiscount;
            if (!areDiscountsAvailable && discountRanges.length > 0) {
                areDiscountsAvailable = true;
            }

            items.push({
                id,
                name,
                price,
                priceWithDiscount,
                discountRanges,
            });
        }

        return (
            <div>
                <TableContainer style={{maxHeight: 163}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow className={ classes.headerRow }>
                                <TableCell align="center" style={{ width: 36 }}>№</TableCell>
                                <TableCell align="right" style={{ width: 44 }} />
                                <TableCell align="left">Место</TableCell>
                                <TableCell align="right" style={{ width: 100 }}>Цена</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                //items.slice(page * perPage, page * perPage + perPage)
                                items.map((item, i) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={ item.id }>
                                            <TableCell align="center">
                                                { i + 1 }
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="Удалить место из корзины">
                                                    <IconButton onClick={ () => this.handleDeleteItem(item.id) }>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="left">
                                                { item.name }
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    item.discountRanges.length > 0 ?
                                                    <>

                                                        <Tooltip title="Доступна скидка">
                                                            <DottedLink className={ classes.discountAsterisk } data-item-id={ item.id } onClick={ this.handleOpenDiscountDialog }>
                                                                <Currency>{ item.priceWithDiscount }</Currency>
                                                            </DottedLink>
                                                        </Tooltip>
                                                        {
                                                            item.price !== item.priceWithDiscount &&
                                                            <div className={ classes.oldPrice }><Currency>{ item.price }</Currency></div>
                                                        }
                                                    </>
                                                    :
                                                    <Currency>{ item.price }</Currency>

                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer>
                    <Table>
                        <TableFooter>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                <TableCell style={{ width: 36 }} />
                                <TableCell style={{ width: 44 }} />
                                <TableCell align="left">
                                    <b>Итого:</b>
                                </TableCell>
                                <TableCell align="right">
                                    <b><Currency>{ totalPrice }</Currency></b>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                {
                    areDiscountsAvailable &&
                    <div className={ classes.discountNote }>
                        * Доступна скидка. Для выбора нажмите на цену билета.
                    </div>
                }

                {/*<TablePagination*/}
                    {/*rowsPerPageOptions={[10, 25, 100]}*/}
                    {/*component="div"*/}
                    {/*count={ items.length }*/}
                    {/*rowsPerPage={ perPage }*/}
                    {/*page={ page }*/}
                    {/*onChangePage={ this.handleChangePage }*/}
                    {/*onChangeRowsPerPage={ this.handleChangeRowsPerPage }*/}
                {/*/>*/}

                {
                    this.state.discountPopupAnchorEl !== null &&
                    <Popover
                        open
                        anchorEl={ this.state.discountPopupAnchorEl }
                        onClose={ this.handleCloseDiscountDialog }
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        elevation={ 12 }
                    >
                        <div className={ classes.discountFormContainer }>
                            <SeatDiscountForm
                                seatId={ discountItem.seatId }
                                seatName={ this.props.schemaManager.getSeat(discountItem.seatId).name }
                                priceMapManager={ this.props.priceMapManager }
                                value={ discountItem.discountId }
                                onChange={ this.handleChange }
                            />
                        </div>
                    </Popover>
                }
            </div>
        )
    }
}

class BaseFontAwesomeIcon extends React.PureComponent
{
    render() {
        return  (
            <span className={ classNames(this.props.children, this.props.classes.root) } />
        )
    }
}

const FontAwesomeIcon = withStyles({
    root: {
        fontSize: 18,
        width: '1em',
        height: '1em',
        margin: 3,
    }
})(BaseFontAwesomeIcon);


Cart.propTypes = {
    cartManager: PropTypes.object.isRequired,
};

const sss = theme => ({
    discountAsterisk: {
        position: 'relative',
        '&:after': {
            content: '"*"',
            position: 'absolute',
            top: -5,
            fontSize: 'small',
        }
    },
    oldPrice: {
        textDecoration: 'line-through',
        color: '#aaa'
    },
    newPrice: {
        //color: 'green'
    },
    discountNote: {
        padding: '4px 16px',
        fontSize: '0.8rem',
    },
    discountFormContainer: {
        padding: theme.spacing(2),
        width: 450,
    },
    headerRow: {
        '& > th': {
            borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd',
        }
    }
});

export default withStyles(sss)(Cart);
