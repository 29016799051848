import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

class Countdown extends React.PureComponent
{
    constructor(props){
        super(props);
        this.state = {
            time: this.props.time
        };
        if (this.props.time) {
            this.interval = setInterval(this.tick, 1000);
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.time !== prevProps.time) {
            this.setState({
                time: this.props.time
            });
            if (this.props.time) {
                clearInterval(this.interval);
                this.interval = setInterval(this.tick, 1000);
            }
        }
    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }

    tick = () => {
        this.setState(state => {
            const time = state.time - 1;
            if (time <= 0) {
                clearInterval(this.interval);
                this.props.onExpired && this.props.onExpired();
            }
            return { time }
        });
    };

    render() {
        let { time } = this.state;

        const days = Math.floor(time / 86400);
        time = time - days * 86400;
        const hours = Math.floor(time / 3600);
        time = time - hours * 3600;
        const minutes = Math.floor(time / 60);
        time = time - minutes * 60;
        const seconds = time;

        const { classes } = this.props;

        return (
            <table className={ classes.root } cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr>
                        <td><span>{ Math.floor(days / 10) }</span><span>{ days % 10 }</span></td>
                        <td>:</td>
                        <td><span>{ Math.floor(hours / 10) }</span><span>{ hours % 10 }</span></td>
                        <td>:</td>
                        <td><span>{ Math.floor(minutes / 10) }</span><span>{ minutes % 10 }</span></td>
                        <td>:</td>
                        <td><span>{ Math.floor(seconds / 10) }</span><span>{ seconds % 10 }</span></td>
                    </tr>
                    <tr>
                        <td>дней</td>
                        <td />
                        <td>часов</td>
                        <td />
                        <td>минут</td>
                        <td />
                        <td>секунд</td>
                    </tr>
                </tbody>
            </table>
        )
    }
}

const styles = {
    root: {
        '& > tbody> tr:first-child': {
            '& > td:nth-child(2n+1)': {
                '& > span': {
                    fontFamily: "Courier New",
                    fontSize: 20,
                    color: '#fff',
                    background: '#000',
                    width: 20,
                    display: 'inline-block',
                    textAlign: 'center',
                    borderLeft: '1px solid',
                }
            },
            '& > td:nth-child(2n)': {
                fontSize: 20,
                lineHeight: '1em',
                fontWeight: 'bold',
                width: 10,
                textAlign: 'center',
            }
        },
        '& > tbody > tr:last-child': {
            '& > td': {
                fontSize: '75%',
                textAlign: 'center',
            }
        }
    }
};

Countdown.propTypes = {
    time: PropTypes.number,
    onExpired: PropTypes.func,
};

Countdown.defaultProps = {
    time: 0,
};

export default withStyles(styles)(Countdown);
